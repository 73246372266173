import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import UpgradeModal from './UpgradeModal';
import './Landingpage.css';
import { AlertCircle, Check, FileText, Smartphone, BarChart2, MessageCircle, BookOpen, Copy } from 'lucide-react';

const LandingPage = () => {
  const [hoveredStep, setHoveredStep] = useState(null);
  const [copySuccess, setCopySuccess] = useState('');
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

  const steps = [
    { id: 1, status: 'success', label: 'Auth' },
    { id: 2, status: 'flaky', label: 'Login', flaky: { number: 102, attempts: 2 } },
    { id: 3, status: 'success', label: 'Verify' },
    { id: 4, status: 'flaky', label: 'Owner Role', flaky: { number: 104, attempts: 3 } },
    { id: 5, status: 'success', label: 'Complete' },
  ];

  const getStatusIcon = (status) => {
    switch (status) {
      case 'success':
        return <Check className="text-jade-400" />;
      case 'flaky':
        return <AlertCircle className="text-orange-400" />;
      default:
        return null;
    }
  };

  const copyWKN = () => {
    navigator.clipboard.writeText('LS9TUS').then(() => {
      setCopySuccess('WKN kopiert!');
      setTimeout(() => setCopySuccess(''), 2000);
    }, (err) => {
      console.error('Fehler beim Kopieren: ', err);
    });
  };

  const openUpgradeModal = () => {
    setIsUpgradeModalOpen(true);
  };

  const closeUpgradeModal = () => {
    setIsUpgradeModalOpen(false);
  };

  const handleYearlySubscription = () => {
    // Implement yearly subscription logic
    closeUpgradeModal();
  };

  const handleMonthlySubscription = () => {
    // Implement monthly subscription logic
    closeUpgradeModal();
  };

  return (
    <div className="landing-container">
      <header className="landing-header">
      </header>

      <main className="landing-main">
        <section id="hero" className="hero-section">
          <video 
            autoPlay 
            loop 
            muted 
            playsInline={true}
            disablePictureInPicture={true}
            className="hero-video"
          >
            <source src="/images/background.webm" type="video/webm" />
            <source src="/images/background.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="hero-content">
            <h1>Der Börsenbrief der nächsten Generation</h1>
            <p>KI-gestützte Echtzeit-Marktanalysen, Live-Chat, kurzfristige Trades, langfristige Investments und lehrreiche Mastermind-Kursinhalte.</p>
            <button onClick={openUpgradeModal} className="cta-button">MITGLIED WERDEN</button>
          </div>
          <div className="hero-mockup">
            <div className="phone-mockup">
              <img src="/images/liq2-left.png" alt="App Screenshot" className="app-screenshot" />
            </div>
          </div>
        </section>

        <section id="features" className="features-section">
          <h2>100X Alpha Hauptfunktionen</h2>
          <div className="feature-grid">
            <div className="feature-card">
              <div className="feature-icon">
                <BarChart2 size={48} className="text-landing-highlight" />
              </div>
              <h3>100X Aktien-Depot</h3>
              <p>Dauerhafter Zugriff auf das 100X Aktien-Depot mit Performance-Daten</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">
                <Smartphone size={48} className="text-landing-highlight" />
              </div>
              <h3>Trade-Alarm per SMS</h3>
              <p>Sofortige SMS-Benachrichtigungen zu wichtigen Marktchancen, die getradet werden können.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">
                <BarChart2 size={48} className="text-landing-highlight" />
              </div>
              <h3>10+ Echtzeit-Indikatoren</h3>
              <p>Eine wachsende Anzahl von einzigartigen Marktindikatoren.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">
                <MessageCircle size={48} className="text-landing-highlight" />
              </div>
              <h3>Live-Chat Community</h3>
              <p>Echtzeit-Austausch mit Maximilian von Projekt: 100X und anderen zur Strategieverfeinerung.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">
                <FileText size={48} className="text-landing-highlight" />
              </div>
              <h3>Insider-Analyse</h3>
              <p>Verfolge die Aktivitäten von Unternehmensinsidern mit unserer übersichtlichen Datenaufbereitung.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">
                <BookOpen size={48} className="text-landing-highlight" />
              </div>
              <h3>Mini-Podcasts</h3>
              <p>Höre dir Max' Audio-Notizen zu aktuellen Marktgeschehen und Trades an.</p>
            </div>
          </div>
        </section>

        <section id="stock-portfolio" className="content-section">
          <div className="section-content">
            <h2>100X Aktien-Depot</h2>
            <p>Erhalte exklusiven Zugriff auf Maximilians privates 100X Aktien-Depot. Basierend auf tiefgehenden fundamentalen Analysen, zeigt dir dieses Portfolio die Positionsgrößen und aktuelle Performance-Daten.</p>
            <ul className="feature-list">
              <li>Detaillierte Einblicke in Maximilians Investitionsstrategie</li>
              <li>Aktuelle Positionsgrößen und Performance-Daten</li>
              <li>Fundierte Analysen zu jeder Aktienposition (im Aufbau)</li>
              <li>Regelmäßige Updates zu Portfolio-Änderungen</li>
            </ul>
          </div>
          <div className="section-image stock-portfolio-mockup">
            <div className="stock-list">
              <div className="stock-item">
                <span className="stock-name">Tesla (TSLA)</span>
                <span className="stock-position">15% des Portfolios</span>
                <span className="stock-performance positive">+643%</span>
              </div>
              <div className="stock-item">
                <span className="stock-name">Palantir (PLTR)</span>
                <span className="stock-position">12% des Portfolios</span>
                <span className="stock-performance positive">+415%</span>
              </div>
              <div className="stock-item">
                <span className="stock-name">Rocket Lab (RKLB)</span>
                <span className="stock-position">10% des Portfolios</span>
                <span className="stock-performance positive">+287%</span>
              </div>
            </div>
          </div>
        </section>

        <section id="trade-alerts" className="content-section reverse">
          <div className="section-content">
            <h2>Trade-Alarm per SMS</h2>
            <p>Verpasse nie wieder eine wichtige Handelsmöglichkeit. Unser SMS-Alarmsystem hält dich über kritische Marktbewegungen auf dem Laufenden, egal wo du dich befindest.</p>
            <ul className="feature-list">
              <li>Sofortige Benachrichtigungen</li>
              <li>Anpassbare Alarmeinstellungen</li>
              <li>Präzise Marktinformationen</li>
              <li>Handlungsempfehlungen inklusive</li>
            </ul>
          </div>
          <div className="section-image sms-mockup">
            <div className="sms-bubble">
              <div className="sms-header">
                <span className="sms-sender">100X Alpha</span>
                <span className="sms-time">Jetzt</span>
              </div>
              <div className="sms-content">
                <p><strong>KAUFSIGNAL: TESLA (TSLA)</strong></p>
                <p>Aktueller Kurs: $263</p>
                <p>Technische Analyse zeigt Bodenbildung</p>
                <p>Mehr Details im Chat.</p>
              </div>
            </div>
            <div className="historical-trades">
              <h3>Trading-Signale</h3>
              
              {/* Current Trades Section */}
              <div className="trades-section">
                <h4>Aktuelle Positionen</h4>
                <div className="trades-list blurred">
                  {/* Replace actual data with dummy data */}
                  <div className="trade-item">
                    <span>████████████</span>
                    <span>████</span>
                    <span>████</span>
                  </div>
                  <div className="trade-item">
                    <span>████████████</span>
                    <span>████</span>
                    <span>████</span>
                  </div>
                </div>
                <div className="unlock-overlay">
                  <button onClick={openUpgradeModal} className="unlock-button">
                    Jetzt freischalten
                  </button>
                </div>
              </div>

              {/* Historical Trades Section */}
              <div className="trades-section">
                <h4>Abgeschlossene Trades</h4>
                <div className="trades-list">
                  <div className="trade-item">
                    <span className="trade-name">Bitcoin (2x Hebel)</span>
                    <span className="trade-status">Geschlossen</span>
                    <span className="trade-performance positive">+24,9%</span>
                  </div>
                  <div className="trade-item">
                    <span className="trade-name">TLT Call-Option</span>
                    <span className="trade-status">Geschlossen</span>
                    <span className="trade-performance negative">-9,1%</span>
                  </div>
                  <div className="trade-item">
                    <span className="trade-name">AMD Call-Option</span>
                    <span className="trade-status">Geschlossen</span>
                    <span className="trade-performance positive">+101%</span>
                  </div>
                  <div className="trade-item">
                    <span className="trade-name">CrowdStrike</span>
                    <span className="trade-status">Geschlossen</span>
                    <span className="trade-performance positive">+9%</span>
                  </div>
                  <div className="trade-item">
                    <span className="trade-name">Nvidia Short</span>
                    <span className="trade-status">Geschlossen</span>
                    <span className="trade-performance positive">+31%</span>
                  </div>
                  <div className="trade-item">
                    <span className="trade-name">TLT Call-Option</span>
                    <span className="trade-status">Geschlossen</span>
                    <span className="trade-performance positive">+76%</span>
                  </div>
                  <div className="trade-item">
                    <span className="trade-name">Nasdaq (3x Hebel)</span>
                    <span className="trade-status">Geschlossen</span>
                    <span className="trade-performance positive">+5,9%</span>
                  </div>
                  <div className="trade-item highlight">
                    <span className="trade-name">VIX-Futures</span>
                    <span className="trade-status">Geschlossen</span>
                    <span className="trade-performance positive">+640%</span>
                  </div>
                  <div className="trade-item">
                    <span className="trade-name">Kupfer</span>
                    <span className="trade-status">Geschlossen</span>
                    <span className="trade-performance positive">+51%</span>
                  </div>
                  <div className="trade-item">
                    <span className="trade-name">Rivian</span>
                    <span className="trade-status">Geschlossen</span>
                    <span className="trade-performance negative">-2,9%</span>
                  </div>
                </div>
              </div>
              
              {/* Existing disclaimer */}
            </div>
          </div>
        </section>

        <section className="disclaimer-section">
          <div className="disclaimer-container">
            <h3>Risikohinweise</h3>
            <div className="disclaimer-content">
              <div className="disclaimer-icon">⚠️</div>
              <p>
                Die hier dargestellten Handelsergebnisse basieren auf tatsächlich durchgeführten Trades. 
                Dennoch können vergangene Erfolge keine Garantie für zukünftige Ergebnisse sein. 
                Der Handel mit Wertpapieren, insbesondere mit Hebelprodukten, birgt erhebliche Risiken 
                und kann zum Totalverlust des eingesetzten Kapitals führen. Bitte handeln Sie 
                verantwortungsvoll und nur mit Kapital, dessen Verlust Sie sich leisten können.
              </p>
            </div>
          </div>
        </section>

        <section id="market-indicators" className="content-section">
          <div className="section-content">
            <h2>10+ Echtzeit-Marktindikatoren</h2>
            <p>Nutze die Kraft von über 13 Echtzeit-Marktindikatoren, um fundierte Handelsentscheidungen zu treffen. Unsere präzisen Indikatoren geben dir einen Wettbewerbsvorteil auf dem Markt.</p>
            <ul className="feature-list">
              <li>Automatische Analyse von Quartalsberichten</li>
              <li>Auswertung von Konsumdaten</li>
              <li>Stimmungsanalyse der Anleger</li>
              <li>Exklusive Daten zur Globalen Liquidität</li>
            </ul>
          </div>
          <div className="section-image indicator-mockup">
            <div className="pe-ratios-grid">
              <div className="pe-ratio-item">
                <div className="pe-ratio-header">
                  <span className="pe-ratio-name">Marktbewertung</span>
                  <span className="pe-ratio-value">65.2</span>
                </div>
                <div className="pe-ratio-scale-container">
                  <div className="pe-ratio-scale">
                    <div className="pe-ratio-indicator" style={{left: '65.2%'}}></div>
                  </div>
                  <div className="pe-ratio-labels">
                    <span>0</span>
                    <span>100</span>
                  </div>
                </div>
              </div>
              <div className="pe-ratio-item">
                <div className="pe-ratio-header">
                  <span className="pe-ratio-name">Angst und Gier</span>
                  <span className="pe-ratio-value">+0.35</span>
                </div>
                <div className="pe-ratio-scale-container">
                  <div className="pe-ratio-scale">
                    <div className="pe-ratio-indicator" style={{left: '60%'}}></div>
                  </div>
                  <div className="pe-ratio-labels">
                    <span>-1</span>
                    <span>+1</span>
                  </div>
                </div>
              </div>
              <div className="pe-ratio-item">
                <div className="pe-ratio-header">
                  <span className="pe-ratio-name">Rezessionswahrscheinlichkeit</span>
                  <span className="pe-ratio-value">18.7%</span>
                </div>
                <div className="pe-ratio-scale-container">
                  <div className="pe-ratio-scale">
                    <div className="pe-ratio-indicator" style={{left: '18.7%'}}></div>
                  </div>
                  <div className="pe-ratio-labels">
                    <span>0%</span>
                    <span>100%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="live-chat" className="content-section reverse">
          <div className="section-content">
            <h2>Live-Chat mit Max und anderen Mitgliedern</h2>
            <p>Tritt unserer lebendigen Community bei und chatte in Echtzeit mit anderen erfahrenen Mitgliedern. Teile Ideen, diskutiere Strategien und lerne von den Besten.</p>
            <ul className="feature-list">
              <li>Expertenmeinungen in Echtzeit</li>
              <li>Community-getriebene Diskussionen</li>
              <li>Networking-Möglichkeiten</li>
              <li>Hilfreiche Unterstützung</li>
            </ul>
          </div>
          <div className="section-image live-chat-mockup">
            <div className="chat-bubbles">
              <div className="chat-bubble bubble-1">
                <span className="user">Max:</span> Willkommen im Live-Chat! Wie kann ich euch heute helfen?
              </div>
              <div className="chat-bubble bubble-2">
                <span className="user">Anna:</span> Hallo Max! Ich habe eine Frage zu den neuesten Markttrends.
              </div>
              <div className="chat-bubble bubble-3">
                <span className="user">Thomas:</span> Der Bitcoin-Chart hat gerade den Widerstand geknackt! Jetzt kaufen?
              </div>
            </div>
          </div>
        </section>

        <section id="mastermind" className="content-section">
          <div className="section-content">
            <h2>Mastermind Lerninhalte</h2>
            <p>Erweitere dein Wissen und schärfe deine Fähigkeiten mit unseren exklusiven Mastermind-Lerninhalten. Von detaillierten Aktienanalysen bis hin zu fortgeschrittenen Optionsstrategien - wir haben alles, was du für deinen Erfolg benötigst.</p>
            <ul className="feature-list">
              <li>Tiefgehende Aktienanalysen</li>
              <li>Fortgeschrittene Optionsstrategien</li>
              <li>Risikomanagement-Techniken</li>
              <li>Regelmäßige Webinare und Workshops</li>
            </ul>
          </div>
          <div className="section-image content-player-mockup">
            <div className="content-player">
              <div className="player-header">
                <h3>Aktuelle Lerninhalte</h3>
              </div>
              <div className="player-content">
                <div className="video-thumbnail">
                  <div className="play-button"></div>
                </div>
                <h4>Fortgeschrittene Optionsstrategien</h4>
                <p>Dauer: 45 Minuten</p>
              </div>
              <div className="player-playlist">
                <div className="playlist-item active">
                  <span className="playlist-item-number">1</span>
                  <span className="playlist-item-title">Einführung in Optionen</span>
                </div>
                <div className="playlist-item">
                  <span className="playlist-item-number">2</span>
                  <span className="playlist-item-title">Covered Calls Strategie</span>
                </div>
                <div className="playlist-item">
                  <span className="playlist-item-number">3</span>
                  <span className="playlist-item-title">Iron Condor Strategie</span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="insider-analysis" className="content-section">
          <div className="section-content">
            <h2>Insider-Analyse</h2>
            <p>Bleibe über die Aktivitäten von Unternehmensinsidern informiert. Unsere aufbereiteten Daten zeigen dir klar und deutlich, ob Insider gerade kaufen oder verkaufen.</p>
            <ul className="feature-list">
              <li>Tägliche Aktualisierung der Insider-Aktivitäten</li>
              <li>Übersichtliche Visualisierung der Transaktionen</li>
              <li>Historische Vergleichsdaten</li>
              <li>Detaillierte Analysen zu bedeutenden Bewegungen</li>
            </ul>
          </div>
          <div className="section-image indicator-mockup">
            <div className="pe-ratios-grid">
              <div className="pe-ratio-item">
                <div className="pe-ratio-header">
                  <span className="pe-ratio-name">Tesla (TSLA) Insider-Aktivität</span>
                  <span className="pe-ratio-value">Verkauf durch CEO</span>
                </div>
                <div className="insider-transaction">
                  <p><strong>Elon Musk</strong> hat <strong>500.000 Tesla Aktien</strong> verkauft</p>
                  <p>Gesamtwert: <strong>$126.715.000</strong></p>
                  <p>Datum: {new Date().toLocaleDateString('de-DE')}</p>
                </div>
                <div className="pe-ratio-scale-container">
                  <div className="pe-ratio-scale">
                    <div className="pe-ratio-indicator" style={{left: '75%'}}></div>
                  </div>
                  <div className="pe-ratio-labels">
                    <span>Kauf</span>
                    <span>Verkauf</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="mini-podcasts" className="content-section reverse">
          <div className="section-content">
            <h2>Mini-Podcasts von Max</h2>
            <p>Bleiben Sie auf dem Laufenden mit kurzen, prägnanten Audio-Updates von Max zu aktuellen Marktentwicklungen und Trading-Entscheidungen.</p>
            <ul className="feature-list">
              <li>Kurze, informative Audio-Updates</li>
              <li>Direkter Einblick in Trading-Entscheidungen</li>
              <li>Aktuelle Marktanalysen</li>
              <li>Jederzeit und überall anhörbar</li>
            </ul>
          </div>
          <div className="section-image content-player-mockup">
            <div className="content-player">
              <div className="player-header">
                <h3>Aktuelle Audio-Updates</h3>
              </div>
              <div className="player-content">
                <div className="video-thumbnail">
                  <div className="play-button"></div>
                </div>
                <h4>Marktupdate: Tech-Sektor Analyse</h4>
                <p>Dauer: 5 Minuten</p>
              </div>
              <div className="player-playlist">
                <div className="playlist-item active">
                  <span className="playlist-item-number">1</span>
                  <span className="playlist-item-title">Tech-Sektor Analyse</span>
                </div>
                <div className="playlist-item">
                  <span className="playlist-item-number">2</span>
                  <span className="playlist-item-title">Bitcoin Breakout?</span>
                </div>
                <div className="playlist-item">
                  <span className="playlist-item-number">3</span>
                  <span className="playlist-item-title">Tesla Trade Update</span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="zertifikat" className="landing-zertifikat">
          <h2>100X Zertifikat: Deine Chance auf Tech- und Wachstumsaktien</h2>
          <div className="zertifikat-content">
            <div className="zertifikat-info">
              <p>Entdecke das 100X Zertifikat, deine Eintrittskarte in die Welt der Tech- und Wachstumsaktien. Dieses innovative Finanzprodukt bietet dir die Möglichkeit, in ein sorgfältig ausgewähltes Portfolio zukunftsweisender Unternehmen zu investieren.</p>
              <div className="wkn-container">
                <span>WKN: LS9TUS</span>
                <button onClick={copyWKN} className="copy-button">
                  <Copy size={16} />
                  Kopieren
                </button>
                {copySuccess && <span className="copy-success">{copySuccess}</span>}
              </div>
              <ul>
                <li>Fokus auf innovative Technologiewerte und Growth-Aktien</li>
                <li>Disruptive und digitale Geschäftsmodelle</li>
                <li>Einbeziehung des Kryptowährungs-Marktes</li>
                <li>Nutzung von ETFs zur breiteren Sektorabdeckung</li>
                <li>Einsatz von Hebelprodukten für kurzfristige Spekulationen</li>
              </ul>
              <p>Das 100X wikifolio Projekt konzentriert sich vorrangig auf innovative Technologiewerte (Growth-Aktien), disruptive und digitale Geschäftsmodelle sowie den Kryptowährungs-Markt. Neben Aktien werden auch ETFs zur breiteren Abdeckung bestimmter Sektoren sowie Hebelprodukte zur Spekulation auf kurzfristige Volatilität genutzt.</p>
              <p>Der Anlagehorizont ist überwiegend langfristig, wobei einzelne Positionen auch kurzfristig gehalten werden können. Die Entscheidungsfindung basiert auf technischer Analyse, wie Trendbestätigungsformationen und gleitenden Durchschnitten, sowie fundamentaler Analyse in Form qualitativer und quantitativer Unternehmensanalyse.</p>
            </div>
            <div className="zertifikat-chart">
              <img src="https://www.ariva.de/chart/images/chart.png?z=a171023929~A1~B100~b16~H0~L1~R100~w940x420~W0" alt="100X Zertifikat Chart" />
              <p>Kursverlauf des 100X Zertifikats vom {new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).toLocaleDateString('de-DE')} bis {new Date().toLocaleDateString('de-DE')}</p>
            </div>
          </div>
        </section>

      </main>

      <footer className="landing-footer">
        <p>&copy; 2024 100X Alpha. Alle Rechte vorbehalten.</p>
        <div className="footer-links">
          <a href="https://projekt100x.de/impressum/">Impressum</a>
          <a href="https://projekt100x.de/datenschutz/">Datenschutz</a>
          <span>Allgemeine Geschäftsbedingungen</span>
        </div>
      </footer>

      <UpgradeModal
        isOpen={isUpgradeModalOpen}
        onClose={closeUpgradeModal}
        onYearlySubscription={handleYearlySubscription}
        onMonthlySubscription={handleMonthlySubscription}
      />
    </div>
  );
};

export default LandingPage;
