import React, { useState, useEffect } from 'react';
import { useSubscription } from '../contexts/SubscriptionContext';
import Spinner from '../components/Spinner';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import '../styles/trades.css';  // Dies sollte jetzt korrekt auf die CSS-Datei verweisen
import { supabase, updateUserSubscription } from '../supabaseClient';
import 'react-phone-input-2/lib/style.css';
import UpgradeOverlay from '../components/UpgradeOverlay';

const TradeModal = ({ trade, onClose }) => (
  <div className="trade-modal-overlay" onClick={onClose}>
    <div className="trade-modal-content" onClick={e => e.stopPropagation()}>
      <h2>{trade.name}</h2>
      <div className="trade-modal-details">
        {trade.name === 'Gold (vierbeinige Option Spread)' ? (
          <>
            <p>Ich habe eine neue Strategie gekauft...</p>
            
            <div className="trade-disclaimer-box">
              <h4>Hinweis:</h4>
              <p>Dies ist eine fortgeschrittene Optionsstrategie. Die Kenntnisse zur Entdeckung und Ausführung solcher Trades werden in Kürze im 100X Alpha Mastermind-Bereich dieser Seite vermittelt. Bitte beachten Sie, dass komplexe Optionsstrategien mit erhöhten Risiken verbunden sind und nur von erfahrenen Anlegern eingesetzt werden sollten.</p>
            </div>

            <h3>Details des Trades:</h3>
            <ul>
              <li>Verkauf 1× GLD 225 Put (Strike: $225, Ablauf: 31. März 2025): Du erhältst eine Prämie und verpflichtest dich, Gold bei $225 zu kaufen, falls der Preis so stark fällt.</li>
              <li>Kauf 1× GLD 255 Put (Strike: $255, Ablauf: 31. März 2025): Diese Option gibt dir das Recht, Gold bei $255 zu verkaufen, wenn der Preis stark fällt.</li>
              <li>Kauf 1× GLD 255 Call (Strike: $255, Ablauf: 31. März 2025): Wenn der Goldpreis stark steigt, kannst du Gold bei $255 kaufen.</li>
              <li>Verkauf 1× GLD 275 Call (Strike: $275, Ablauf: 31. März 2025): Du erhältst eine Prämie, die deine Kosten reduziert. Gewinne sind jedoch bei $275 begrenzt.</li>
            </ul>

            <h3>Positionen und Gewinn-Verlust-Verteilung:</h3>
            <p>Hier sind die Details zu den gekauften Positionen und der Gewinn-Verlust-Verteilung:</p>
            <div className="trade-modal-image">
              <img src="/images/trades/gold-combo-oct24/1.png" alt="Gekaufte Positionen" />
              <p>Bild 1: Screenshot der gekauften Positionen. 10 Kontrakte wurden erworben.</p>
            </div>
            <div className="trade-modal-image">
              <img src="/images/trades/gold-combo-oct24/2.png" alt="Gewinn-Verlust-Verteilung" />
              <p>Bild 2: Diagramm der Gewinn-Verlust-Verteilung. Es zeigt die möglichen Ergebnisse basierend auf verschiedenen Goldpreisbewegungen bis März 2025:</p>
              <ul>
                <li>Maximaler Gewinn von 15.000 $, wenn Gold unter 225 $ fällt</li>
                <li>Maximaler Gewinn von 4.800 $, wenn Gold über 275 $ steigt</li>
                <li>Vollständiger Verlust der Prämie (15.120 $), wenn sich der Goldpreis kaum bewegt</li>
              </ul>
            </div>

            <h3>Einsatz und Gewinne:</h3>
            <ul>
              <li>Einsatz (Netto-Kosten): $15.12 pro Kontrakt</li>
              <li>Maximaler Gewinn:
                <ul>
                  <li>Auf der Unterseite: $14.88 pro Kontrakt (wenn Gold unter $225 fällt)</li>
                  <li>Auf der Oberseite: $4.88 pro Kontrakt (wenn Gold über $275 steigt)</li>
                </ul>
              </li>
              <li>Maximaler Verlust: Der maximal mögliche Verlust ist auf deine Investition von $15.12 begrenzt, wenn Gold sich kaum bewegt.</li>
            </ul>

            <h3>Warum gerade jetzt der richtige Zeitpunkt ist:</h3>
            <ol>
              <li><strong>Goldpreis auf Rekordhoch:</strong> Gold befindet sich in der Nähe historischer Höchststände, was die Möglichkeit für eine starke Bewegung nach oben oder unten bietet. Die geopolitische Unsicherheit, insbesondere in Bezug auf Konflikte im Nahen Osten, treibt die Nachfrage nach Gold als „sicherer Hafen" an.</li>
              <li><strong>Hohe implizite Volatilität (IV):</strong> Die implizite Volatilität für Goldoptionen liegt derzeit bei etwa 17-20%. Dies bedeutet, dass der Markt starke Bewegungen im Goldpreis erwartet. Ein Rückgang der IV, nachdem du diese Position eröffnet hast, wird die Kosten der verkauften Optionen (225 Put und 275 Call) reduzieren, was diesen Trade profitabler macht, selbst wenn der Goldpreis sich nicht drastisch ändert.</li>
              <li><strong>Profitieren von IV-Rückgang:</strong> Da du Optionen verkauft hast, profitierst du, wenn die Volatilität sinkt. Im Moment ist die IV hoch, was bedeutet, dass die Prämien, die du für das Verkaufen von Optionen erhältst, größer sind. Wenn die IV nach einem Beruhigen der Märkte fällt, werden die Kosten der Optionen sinken und dein Trade wird schneller profitabel.</li>
            </ol>

            <h3>Risiken:</h3>
            <ul>
              <li><strong>Seitwärtsbewegung:</strong> Wenn sich Gold kaum bewegt, könnte der Wert der gekauften Optionen schneller verfallen als die Prämien der verkauften Optionen, was zu einem Verlust führen könnte.</li>
              <li><strong>Begrenzter Gewinn:</strong> Auf der Oberseite ist dein Gewinn durch den Verkauf der Call-Option bei $275 gedeckelt.</li>
            </ul>

            <p><strong>Zusammengefasst:</strong> Diese Strategie nutzt die hohe Marktvolatilität und bietet Chancen, sowohl von fallenden als auch von steigenden Goldpreisen zu profitieren, während du von einem Rückgang der Volatilität zusätzlich profitieren kannst.</p>
          </>
        ) : (
          <>
            <p>Status: {trade.status}</p>
            <p>Ergebnis: <span className={trade.ergebnis.startsWith('+') ? 'positive' : 'negative'}>{trade.ergebnis}</span></p>
          </>
        )}
      </div>
      <button className="close-modal" onClick={onClose}>Schließen</button>
    </div>
  </div>
);

const TradeItem = ({ trade, clickable }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = () => {
    if (clickable) {
      setIsModalOpen(true);
    }
  };

  const isPositive = trade.ergebnis.startsWith('+');

  return (
    <>
      <div className={`trade-item ${clickable ? 'clickable' : ''}`} onClick={handleClick}>
        <div className="trade-item-cell">{trade.name}</div>
        <div className="trade-item-cell">{trade.status}</div>
        <div className="trade-item-cell">
          {trade.positionsgroesse ? `${trade.positionsgroesse}` : ''}
        </div>
        <div className="trade-item-cell">
          <span className={`result ${isPositive ? 'positive positive-result' : 'negative'}`}>
            {trade.ergebnis}
          </span>
        </div>
      </div>
      {clickable && isModalOpen && (
        <TradeModal trade={trade} onClose={() => setIsModalOpen(false)} />
      )}
    </>
  );
};

const TradeSection = ({ title, trades, expandable = true, clickable = true }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className="trade-section">
      <div className={`trade-section-header ${expandable ? 'expandable' : ''}`} onClick={() => expandable && setIsExpanded(!isExpanded)}>
        <h2>{title}</h2>
        {expandable && (isExpanded ? <FaChevronUp /> : <FaChevronDown />)}
      </div>
      {isExpanded && (
        <div className="trade-list">
          <div className="trade-list-header">
            <div className="trade-list-header-cell">Name</div>
            <div className="trade-list-header-cell">Status</div>
            {title === "Live Trades" && <div className="trade-list-header-cell">Positionsgröße</div>}
            <div className="trade-list-header-cell">Ergebnis</div>
          </div>
          {trades.map((trade, index) => (
            <TradeItem key={index} trade={trade} clickable={clickable} />
          ))}
        </div>
      )}
    </div>
  );
};

const TradeAlertSection = ({ subscription, onToggle, onPhoneSubmit }) => {
  const [isPhoneInputVisible, setIsPhoneInputVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('49');
  const [error, setError] = useState('');

  const handleToggle = () => {
    if (subscription && subscription.trade_alert) {
      onToggle(false);
    } else {
      setIsPhoneInputVisible(true);
    }
  };

  const handlePhoneSubmit = () => {
    if (phoneNumber.length < 10) {
      setError('Bitte geben Sie eine gültige Telefonnummer ein.');
      return;
    }
    onPhoneSubmit(`+${country}${phoneNumber}`);
    setIsPhoneInputVisible(false);
    setError('');
  };

  // If subscription is null or undefined, don't render the component
  if (!subscription) {
    return null;
  }

  return (
    <div className="trade-alerts-section">
      <h2>Trade-Alarm</h2>
      <div className={`trade-alert-content ${isPhoneInputVisible ? 'phone-input-visible' : ''}`}>
        <div className="trade-alert-status">
          <p>{subscription.trade_alert ? 'Trade-Alarm aktiviert' : 'Trade-Alarm deaktiviert'}</p>
          <button onClick={handleToggle}>
            {subscription.trade_alert ? 'Deaktivieren' : 'Aktivieren'}
          </button>
        </div>
        <div className="phone-input-area">
          <div className="phone-input-container">
            <select 
              value={country} 
              onChange={(e) => setCountry(e.target.value)}
              className="country-select"
            >
              <option value="49">Deutschland (+49)</option>
              <option value="43">Österreich (+43)</option>
              <option value="41">Schweiz (+41)</option>
            </select>
            <input
              type="tel"
              inputMode="numeric"
              pattern="[0-9]*"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Telefonnummer"
              className="phone-input-field"
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <div className="phone-input-buttons">
            <button onClick={handlePhoneSubmit}>Bestätigen</button>
            <button onClick={() => setIsPhoneInputVisible(false)}>Abbrechen</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Trades = () => {
  const [trades, setTrades] = useState({
    watchlist: [],
    liveTrades: [],
    completedTrades: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const { subscription, refreshSubscription } = useSubscription();

  const updateTradeAlert = async (userId, newAlertStatus, phoneNumber = null) => {
    try {
      await updateUserSubscription(userId, { trade_alert: newAlertStatus });
      await updateActiveCampaign(userId, phoneNumber, newAlertStatus);
      refreshSubscription();
    } catch (error) {
      console.error('Error updating trade alert:', error);
    }
  };

  const updateActiveCampaign = async (userId, phoneNumber, activateAlert) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) throw new Error('No active session');

      const response = await fetch('https://jfhelnsdfzbwwrmbuoms.functions.supabase.co/update-activecampaign', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`,
        },
        body: JSON.stringify({ userId, phoneNumber, activateAlert }),
      });

      const data = await response.json();

      if (!response.ok) {
        console.error('Error response:', data);
        throw new Error(`Failed to update ActiveCampaign: ${response.status} ${response.statusText}\n${JSON.stringify(data)}`);
      }

      console.log('ActiveCampaign update steps:', data.logs);
      console.log('ActiveCampaign update successful:', data);
      return data;
    } catch (error) {
      console.error('Error updating ActiveCampaign:', error);
      throw error;
    }
  };

  const handleTradeAlertToggle = async (newStatus) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        await updateTradeAlert(user.id, newStatus, subscription.phone_number);
      }
    } catch (error) {
      console.error('Error updating trade alert:', error);
    }
  };

  const handlePhoneSubmit = async (phoneNumber) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        await updateTradeAlert(user.id, true, phoneNumber);
      } else {
        console.error('No user found');
      }
    } catch (error) {
      console.error('Error in handlePhoneSubmit:', error);
    }
  };

  useEffect(() => {
    const fetchTradeData = async () => {
      // Simulating data fetching
      const mockTrades = {
        watchlist: [
          // Empty watchlist
        ],
        liveTrades: [
          { 
            name: 'Ethereum (2X Hebel)', 
            status: 'Aktiv',
            positionsgroesse: '10.000 EUR', 
            ergebnis: '-51,32%'
          },
          {
            name: 'Leerverkauf ionQ',
            status: 'Aktiv',
            positionsgroesse: '7.680 EUR',
            ergebnis: '-3,77%'
          }
        ],
        completedTrades: [
          { 
            name: 'Bitcoin (BITX 2x Hebel)', 
            status: 'Geschlossen', 
            ergebnis: '+5,69%'
          },
          { 
            name: 'Gold (vierbeinige Option Spread)', 
            status: 'Geschlossen', 
            ergebnis: '+24,7%'
          },
          { name: 'Bitcoin (BITX 2x Hebel)', status: 'Geschlossen', ergebnis: '+24,9%' },
          { name: 'TLT Call-Option', status: 'Geschlossen', ergebnis: '-9,1%' },
          { name: 'AMD Call-Option', status: 'Geschlossen', ergebnis: '+101%' },
          { name: 'CrowdStrike', status: 'Geschlossen', ergebnis: '+9%' },
          { name: 'Nvidia Short', status: 'Geschlossen', ergebnis: '+31%' },
          { name: 'TLT Call-Option', status: 'Geschlossen', ergebnis: '+76%' },
          { name: 'Nasdaq 3xHebel', status: 'Geschlossen', ergebnis: '+5,9%' },
          { name: 'VIX-Futures', status: 'Geschlossen', ergebnis: '+640%' },
          { name: 'Kupfer', status: 'Geschlossen', ergebnis: '+51%' },
          { name: 'Rivian', status: 'Geschlossen', ergebnis: '-2,9%' },
        ],
      };

      setTrades(mockTrades);
      setIsLoading(false);
    };

    fetchTradeData();
  }, []);

  const handleCloseDisclaimer = () => {
    setShowDisclaimer(false);
  };

  const hasActiveSubscription = subscription && subscription.subscription_status === 'active';

  return (
    <div className="trades-page">
      <div className="trades-header">
        <h1>Trades</h1>
      </div>
      <div className="trades-explanation">
        <p>
          Hier findest du eine Übersicht über unsere aktuellen und vergangenen Trades. Unter Trades verstehen wir alle Positionen, die nicht für eine langfristige Haltedauer vorgesehen sind. Die Haltedauer kann von wenigen Stunden bis zu mehreren Monaten variieren.
        </p>
        <p>
          Wir eröffnen Trades unregelmäßig, wenn wir besondere Marktchancen erkennen. Aufgrund des höheren Risikos arbeiten wir mit geringen Positionsgrößen, setzen aber gerne Hebel ein, um die Rendite zu optimieren.
        </p>
        <p>
          Die Seite ist in drei Hauptbereiche unterteilt:
        </p>
        <ul>
          <li><strong>Watchlist:</strong> Potenzielle zukünftige Trades, die wir genau beobachten.</li>
          <li><strong>Live Trades:</strong> Aktuell laufende Trades mit Echtzeit-Updates.</li>
          <li><strong>Abgeschlossene Trades:</strong> Historische Trades mit ihren Endergebnissen.</li>
        </ul>
        <p>
          Klicke auf einen Trade, um detaillierte Informationen zu erhalten. Bitte beachte den untenstehenden Haftungsausschluss.
        </p>
      </div>
      {showDisclaimer && (
        <div className="page-disclaimer-box">
          <button className="page-close-disclaimer" onClick={handleCloseDisclaimer}>×</button>
          <h3>Rechtlicher Hinweis und Haftungsausschluss</h3>
          <div className="page-disclaimer-content">
            <p>
              Die auf dieser Webseite bereitgestellten Informationen dienen ausschließlich zu Informations- und Bildungszwecken. Sie stellen unter keinen Umständen eine Anlageberatung, Empfehlung oder Aufforderung zum Kauf oder Verkauf von Finanzinstrumenten dar.
            </p>
            <ul>
              <li>Jegliche Investitionsentscheidungen erfolgen ausschließlich auf Ihr eigenes Risiko.</li>
              <li>Wir übernehmen keinerlei Haftung für etwaige Verluste oder Schäden.</li>
              <li>Vergangene Wertentwicklungen sind kein verlässlicher Indikator für zukünftige Ergebnisse.</li>
              <li>Es besteht die Möglichkeit, dass Sie nicht den ursprünglich investierten Betrag zurückerhalten.</li>
            </ul>
            <p>
              Vor jeder Anlageentscheidung sollten Sie:
            </p>
            <ol>
              <li>Ihre individuellen finanziellen Umstände prüfen</li>
              <li>Ihre Anlageziele und Risikotoleranz sorgfältig abwägen</li>
              <li>Unabhängigen, professionellen Finanzrat einholen</li>
              <li>Alle relevanten Risiken, Gebühren und Bedingungen vollständig verstehen</li>
            </ol>
            <p>
              Durch die Nutzung dieser Webseite erklären Sie sich mit den hierin enthaltenen Bedingungen einverstanden und bestätigen, dass Sie die damit verbundenen Risiken verstanden haben. Bei Nichteinverstndnis bitten wir Sie, diese Webseite nicht zu nutzen.
            </p>
          </div>
        </div>
      )}
      {subscription && (
        <TradeAlertSection 
          subscription={subscription}
          onToggle={handleTradeAlertToggle}
          onPhoneSubmit={handlePhoneSubmit}
        />
      )}
      {!hasActiveSubscription && <UpgradeOverlay />}
      <div className={`trades-container ${!hasActiveSubscription ? 'blurred' : ''}`}>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <TradeSection 
              title="Watchlist" 
              trades={trades.watchlist} 
            />
            <TradeSection 
              title="Live Trades" 
              trades={trades.liveTrades} 
            />
            <TradeSection 
              title="Abgeschlossene Trades" 
              trades={trades.completedTrades} 
              clickable={false}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Trades;
