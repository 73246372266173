import React, { useState } from 'react';
import '../styles/chat.css';

const AdminChatTabs = ({ 
  activeTab, 
  onTabChange, 
  activeChats,
  onNewChat,
  onCloseChat,
  unreadChats
}) => {
  const [showUserSelect, setShowUserSelect] = useState(false);

  return (
    <div className="chat-tabs admin-chat-tabs">
      <button 
        className={`tab-button ${activeTab === 'public' ? 'active' : ''} ${unreadChats.has('public') ? 'has-unread' : ''}`}
        onClick={() => onTabChange('public')}
      >
        Öffentlich
      </button>
      
      {activeChats.map(chat => (
        <div key={chat.userId} className="tab-with-close">
          <button 
            className={`tab-button ${activeTab === chat.userId ? 'active' : ''} ${unreadChats.has(chat.userId) ? 'has-unread' : ''}`}
            onClick={() => onTabChange(chat.userId)}
          >
            {chat.userName}
          </button>
          <button 
            className="close-tab-button"
            onClick={() => onCloseChat(chat.userId)}
          >
            ✕
          </button>
        </div>
      ))}

      <button 
        className="tab-button new-chat-button"
        onClick={onNewChat}
      >
        + New Chat
      </button>
    </div>
  );
};

export default AdminChatTabs; 