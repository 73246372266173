import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { Tooltip } from 'react-tooltip';

const EarningsCalendar = ({ isOpen, setIsOpen }) => {
  const wirtschaftsdaten = [
    { ereignis: 'Beschäftigung außerhalb der Landwirtschaft OKT', datum: new Date(2024, 10, 1, 14, 30), konsens: '113K', vorherig: '223K', aktuell: '12K', erklaerung: 'Zeigt die Anzahl neuer Arbeitsplätze. Einer der wichtigsten Arbeitsmarktindikatoren, der Aktien-, Anleihe- und Währungsmärkte stark beeinflusst.' },
    { ereignis: 'Arbeitslosenquote OKT', datum: new Date(2024, 10, 1, 14, 30), konsens: '4,1%', vorherig: '4,1%', aktuell: '4,1%', erklaerung: 'Misst den Anteil der Arbeitslosen an der Erwerbsbevölkerung. Wichtiger Indikator für wirtschaftliche Gesundheit und kann geldpolitische Entscheidungen beeinflussen.' },
    { ereignis: 'ISM Produktions-PMI OKT', datum: new Date(2024, 10, 1, 16, 0), konsens: '47,6', vorherig: '47,2', aktuell: '46,5', erklaerung: 'Misst die Aktivität im verarbeitenden Gewerbe. Wichtiger Frühindikator für Wirtschaftswachstum und kann Industrieaktien beeinflussen.' },
    { ereignis: 'ISM Dienstleistungs-PMI OKT', datum: new Date(2024, 10, 5, 17, 0), konsens: '53,8', vorherig: '54,9', aktuell: '56', erklaerung: 'Misst die Aktivität im Dienstleistungssektor. Wichtiger Indikator für den größten Sektor der US-Wirtschaft.' },
    { ereignis: 'Fed Zinsentscheid', datum: new Date(2024, 10, 7, 21, 0), konsens: '4,75%', vorherig: '5%', aktuell: '4,75%', erklaerung: 'Zentrale geldpolitische Entscheidung der Federal Reserve. Bestimmt die Leitzinsen und beeinflusst alle Finanzmärkte.' },
    { ereignis: 'Michigan Verbrauchervertrauen Vorl. NOV', datum: new Date(2024, 10, 8, 17, 0), konsens: '71', vorherig: '70,5', aktuell: '73,0', erklaerung: 'Misst die Verbraucherstimmung. Wichtiger Indikator für zukünftiges Konsumverhalten und kann Einzelhandels- und Konsumaktien beeinflussen.' },
    { ereignis: 'Kerninflationsrate MoM OKT', datum: new Date(2024, 10, 13, 15, 30), konsens: '0,3%', vorherig: '0,3%', aktuell: '0,3%', erklaerung: 'Misst Preisänderungen ohne volatile Lebensmittel- und Energiepreise. Wichtiger Indikator für die zugrunde liegende Inflation.' },
    { ereignis: 'Kerninflationsrate YoY OKT', datum: new Date(2024, 10, 13, 15, 30), konsens: '3,3%', vorherig: '3,3%', aktuell: '3,3%', erklaerung: 'Jährliche Veränderung der Kerninflation. Zeigt langfristige Inflationstrends.' },
    { ereignis: 'Inflationsrate MoM OKT', datum: new Date(2024, 10, 13, 15, 30), konsens: '0,2%', vorherig: '0,2%', aktuell: '0,2%', erklaerung: 'Monatliche Veränderung der Verbraucherpreise. Wichtiger Indikator für kurzfristige Inflationstrends.' },
    { ereignis: 'Inflationsrate YoY OKT', datum: new Date(2024, 10, 13, 15, 30), konsens: '2,6%', vorherig: '2,4%', aktuell: '2,6%', erklaerung: 'Jährliche Inflationsrate. Zentral für geldpolitische Entscheidungen.' },
    { ereignis: 'Erzeugerpreisindex MoM OKT', datum: new Date(2024, 10, 14, 15, 30), konsens: '0,2%', vorherig: '0,1%', aktuell: '0,2%', erklaerung: 'Misst Preisänderungen auf Produzentenebene. Frühindikator für Verbraucherpreisinflation.' },
    { ereignis: 'Einzelhandelsumsätze MoM OKT', datum: new Date(2024, 10, 15, 15, 30), konsens: '0,3%', vorherig: '0,4%', aktuell: '-', erklaerung: 'Zeigt Veränderungen im Konsumverhalten. Wichtig für BIP-Prognosen.' },
    { ereignis: 'Baugenehmigungen Vorl. OKT', datum: new Date(2024, 10, 19, 15, 30), konsens: '-', vorherig: '1,425 Mio.', aktuell: '-', erklaerung: 'Indikator für zukünftige Bauaktivitäten. Wichtig für Immobilien- und Baustoffsektor.' },
    { ereignis: 'Kern-PCE-Preisindex MoM OKT', datum: new Date(2024, 10, 27, 15, 30), konsens: '-', vorherig: '0,3%', aktuell: '-', erklaerung: 'Bevorzugtes Inflationsmaß der Fed. Sehr wichtig für geldpolitische Entscheidungen.' },
    { ereignis: 'Auftragseingang langlebiger Güter MoM OKT', datum: new Date(2024, 10, 27, 15, 30), konsens: '-', vorherig: '-0,8%', aktuell: '-', erklaerung: 'Misst Bestellungen für langlebige Produkte. Wichtiger Indikator für Industrieproduktion.' },
    { ereignis: 'BIP-Wachstumsrate QoQ 2.Schätz. Q3', datum: new Date(2024, 10, 27, 15, 30), konsens: '3,2%', vorherig: '-', aktuell: '-', erklaerung: 'Misst das Wirtschaftswachstum. Einer der wichtigsten Wirtschaftsindikatoren.' },
  ];

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    } else {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
    }
    return () => {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const getColorClass = (aktuell, konsens, ereignis) => {
    if (aktuell === '-' || konsens === '-') return '';
    const aktuelValue = parseFloat(aktuell.replace(',', '.').replace('%', ''));
    const konsensValue = parseFloat(konsens.replace(',', '.').replace('%', ''));
    
    // Check if the event is related to inflation or prices
    const isInflationOrPriceRelated = ereignis.toLowerCase().includes('inflation') || 
                                    ereignis.toLowerCase().includes('preis') ||
                                    ereignis.toLowerCase().includes('pce');
    
    if (isInflationOrPriceRelated) {
      // For inflation and price metrics, lower than expected is good (green), higher is bad (red)
      return aktuelValue < konsensValue ? 'green' : aktuelValue > konsensValue ? 'red' : '';
    } else {
      // For other metrics, higher than expected is good (green), lower is bad (red)
      return aktuelValue > konsensValue ? 'green' : aktuelValue < konsensValue ? 'red' : '';
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content calendar-modal">
        <h2>Wirtschaftsdaten-Veröffentlichungen</h2>
        
        <div className="calendar-table-container">
          <table className="calendar-table">
            <thead>
              <tr>
                <th>Datum</th>
                <th>Ereignis</th>
                <th className="hidden-mobile">Konsens</th>
                <th className="hidden-mobile">Vorherig</th>
                <th>Aktuell</th>
              </tr>
            </thead>
            <tbody>
              {wirtschaftsdaten.map((data, index) => (
                <tr key={`economic-${index}`}>
                  <td>{format(data.datum, "dd.MM.yyyy HH:mm", { locale: de })}</td>
                  <td>
                    <span data-tooltip-id={`tooltip-${index}`}>
                      {data.ereignis}
                    </span>
                    <Tooltip id={`tooltip-${index}`} className="custom-tooltip">
                      <span>{data.erklaerung}</span>
                    </Tooltip>
                  </td>
                  <td className="hidden-mobile">{data.konsens}</td>
                  <td className="hidden-mobile">{data.vorherig}</td>
                  <td className={getColorClass(data.aktuell, data.konsens, data.ereignis)}>
                    {data.aktuell}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <button className="modal-close" onClick={() => setIsOpen(false)}>Schließen</button>
      </div>
    </div>
  );
};

export default EarningsCalendar;
