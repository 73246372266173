import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import { useSubscription } from '../contexts/SubscriptionContext';
import UpgradeModal from './UpgradeModal';

const AccountPage = () => {
  const [email, setEmail] = useState('');
  const [nickname, setNickname] = useState('');
  const [isEditingNickname, setIsEditingNickname] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const navigate = useNavigate();
  const { subscription, refreshSubscription } = useSubscription();

  useEffect(() => {
    const fetchUserData = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        setEmail(user.email);
        if (subscription?.nickname) {
          setNickname(subscription.nickname);
        }
      }
    };
    fetchUserData();
  }, [subscription]);

  const handleSaveNickname = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      await supabase
        .from('user_subscriptions')
        .update({ nickname: nickname.trim() })
        .eq('user_id', user.id);

      setIsEditingNickname(false);
      refreshSubscription();
    } catch (error) {
      console.error('Error saving nickname:', error);
      alert('Failed to save nickname. Please try again.');
    }
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate('/');
  };

  const openUpgradeModal = () => {
    setShowUpgradeModal(true);
  };

  const closeUpgradeModal = () => {
    setShowUpgradeModal(false);
  };

  const handleYearlySubscription = () => {
    window.location.href = "https://buy.stripe.com/28o9Ei4TX7RkgDe4gh?locale=de";
  };

  const handleMonthlySubscription = () => {
    // Placeholder for monthly subscription
    alert("Monatliches Abonnement ist noch nicht verfügbar.");
  };

  return (
    <div className="account-page">
      <h1>Kontoinformationen</h1>
      <div className="account-info">
        <p><strong>E-Mail:</strong> {email}</p>
        <div className="nickname-section">
          <strong>Chat-Alias:</strong>
          {isEditingNickname ? (
            <div className="nickname-edit">
              <input
                type="text"
                value={nickname}
                onChange={(e) => setNickname(e.target.value)}
                placeholder="Enter chat name"
                maxLength={30}
              />
              <button onClick={handleSaveNickname}>Speichern</button>
              <button onClick={() => setIsEditingNickname(false)}>Abbruch</button>
            </div>
          ) : (
            <div className="nickname-display">
              <span>{nickname || email.split('@')[0]}</span>
              <button onClick={() => setIsEditingNickname(true)}>Ändern</button>
            </div>
          )}
        </div>
        <p><strong>Abonnementstatus:</strong> {subscription ? subscription.subscription_status : 'Kein Abonnement'}</p>
        {subscription && subscription.subscription_type && (
          <p><strong>Abonnementtyp:</strong> {subscription.subscription_type}</p>
        )}
        {subscription && subscription.end_date && (
          <p><strong>Abonnement-Enddatum:</strong> {new Date(subscription.end_date).toLocaleDateString('de-DE')}</p>
        )}
      </div>
      <div className="account-actions">
        <button onClick={openUpgradeModal} className="upgrade-button">Abonnement upgraden</button>
        <button onClick={handleLogout} className="logout-button">Abmelden</button>
      </div>

      <UpgradeModal
        isOpen={showUpgradeModal}
        onClose={closeUpgradeModal}
        onYearlySubscription={handleYearlySubscription}
        onMonthlySubscription={handleMonthlySubscription}
      />
    </div>
  );
};

export default AccountPage;
