import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { Tooltip } from 'react-tooltip';


const EarningsCalendar = ({ isOpen, setIsOpen }) => {
  const wirtschaftsdaten = [
    { ereignis: 'ISM Produktions-PMI NOV', datum: new Date(2024, 11, 2, 16, 0), konsens: '47,5', vorherig: '46,5', aktuell: '-', erklaerung: 'Misst die Aktivität im verarbeitenden Gewerbe. Wichtiger Frühindikator für Wirtschaftswachstum und kann Industrieaktien beeinflussen.' },
    { ereignis: 'JOLTs Stellenangebote OKT', datum: new Date(2024, 11, 3, 16, 0), konsens: '7,38M', vorherig: '7,443M', aktuell: '-', erklaerung: 'Zeigt die Anzahl der offenen Stellen. Wichtiger Indikator für den Arbeitsmarkt.' },
    { ereignis: 'ISM Dienstleistungs-PMI NOV', datum: new Date(2024, 11, 4, 16, 0), konsens: '55,5', vorherig: '56', aktuell: '-', erklaerung: 'Misst die Aktivität im Dienstleistungssektor. Wichtiger Indikator für den größten Sektor der US-Wirtschaft.' },
    { ereignis: 'Fed Vorsitzender Powell Rede', datum: new Date(2024, 11, 4, 19, 45), konsens: '-', vorherig: '-', aktuell: '-', erklaerung: 'Wichtige Rede des Fed-Vorsitzenden. Kann Hinweise auf künftige Geldpolitik geben.' },
    { ereignis: 'Beschäftigung außerhalb der Landwirtschaft NOV', datum: new Date(2024, 11, 6, 14, 30), konsens: '183K', vorherig: '12K', aktuell: '-', erklaerung: 'Zeigt die Anzahl neuer Arbeitsplätze. Einer der wichtigsten Arbeitsmarktindikatoren.' },
    { ereignis: 'Arbeitslosenquote NOV', datum: new Date(2024, 11, 6, 14, 30), konsens: '4,1%', vorherig: '4,1%', aktuell: '-', erklaerung: 'Misst den Anteil der Arbeitslosen an der Erwerbsbevölkerung.' },
    { ereignis: 'Michigan Verbrauchervertrauen Vorl. DEZ', datum: new Date(2024, 11, 6, 16, 0), konsens: '71,5', vorherig: '71,8', aktuell: '-', erklaerung: 'Misst die Verbraucherstimmung. Wichtiger Indikator für zukünftiges Konsumverhalten.' },
    { ereignis: 'Kerninflationsrate MoM NOV', datum: new Date(2024, 11, 11, 14, 30), konsens: '-', vorherig: '0,3%', aktuell: '-', erklaerung: 'Misst Preisänderungen ohne volatile Lebensmittel- und Energiepreise.' },
    { ereignis: 'Kerninflationsrate YoY NOV', datum: new Date(2024, 11, 11, 14, 30), konsens: '-', vorherig: '3,3%', aktuell: '-', erklaerung: 'Jährliche Veränderung der Kerninflation. Zeigt langfristige Inflationstrends.' },
    { ereignis: 'Inflationsrate MoM NOV', datum: new Date(2024, 11, 11, 14, 30), konsens: '-', vorherig: '0,2%', aktuell: '-', erklaerung: 'Monatliche Veränderung der Verbraucherpreise.' },
    { ereignis: 'Inflationsrate YoY NOV', datum: new Date(2024, 11, 11, 14, 30), konsens: '2,7%', vorherig: '2,6%', aktuell: '-', erklaerung: 'Jährliche Inflationsrate. Zentral für geldpolitische Entscheidungen.' },
    { ereignis: 'Erzeugerpreisindex MoM NOV', datum: new Date(2024, 11, 12, 14, 30), konsens: '-', vorherig: '0,2%', aktuell: '-', erklaerung: 'Misst Preisänderungen auf Produzentenebene. Frühindikator für Verbraucherpreisinflation.' },
    { ereignis: 'Einzelhandelsumsätze MoM NOV', datum: new Date(2024, 11, 17, 14, 30), konsens: '-', vorherig: '0,4%', aktuell: '-', erklaerung: 'Zeigt Veränderungen im Konsumverhalten. Wichtig für BIP-Prognosen.' },
    { ereignis: 'Baugenehmigungen Vorl. NOV', datum: new Date(2024, 11, 18, 14, 30), konsens: '-', vorherig: '1,419M', aktuell: '-', erklaerung: 'Indikator für zukünftige Bauaktivitäten. Wichtig für Immobilien- und Baustoffsektor.' },
    { ereignis: 'Fed Zinsentscheid', datum: new Date(2024, 11, 18, 20, 0), konsens: '4,5%', vorherig: '4,75%', aktuell: '-', erklaerung: 'Zentrale geldpolitische Entscheidung der Federal Reserve.' },
    { ereignis: 'FOMC Wirtschaftsprognosen', datum: new Date(2024, 11, 18, 20, 0), konsens: '-', vorherig: '-', aktuell: '-', erklaerung: 'Wirtschaftliche Projektionen der Fed-Mitglieder.' },
    { ereignis: 'Fed Pressekonferenz', datum: new Date(2024, 11, 18, 20, 30), konsens: '-', vorherig: '-', aktuell: '-', erklaerung: 'Erläuterungen zur Zinsentscheidung und Ausblick.' },
    { ereignis: 'BIP-Wachstumsrate QoQ Final Q3', datum: new Date(2024, 11, 19, 14, 30), konsens: '2,8%', vorherig: '3%', aktuell: '-', erklaerung: 'Finale Schätzung des Wirtschaftswachstums im dritten Quartal.' },
    { ereignis: 'Kern-PCE-Preisindex MoM NOV', datum: new Date(2024, 11, 20, 14, 30), konsens: '-', vorherig: '0,3%', aktuell: '-', erklaerung: 'Bevorzugtes Inflationsmaß der Fed.' },
    { ereignis: 'Persönliche Einkommen MoM NOV', datum: new Date(2024, 11, 20, 14, 30), konsens: '-', vorherig: '0,6%', aktuell: '-', erklaerung: 'Misst die Veränderung der Haushaltseinkommen.' },
    { ereignis: 'Persönliche Ausgaben MoM NOV', datum: new Date(2024, 11, 20, 14, 30), konsens: '-', vorherig: '-', aktuell: '-', erklaerung: 'Zeigt Veränderungen im Konsumverhalten der Haushalte.' },
    { ereignis: 'Auftragseingang langlebiger Güter MoM NOV', datum: new Date(2024, 11, 24, 14, 30), konsens: '-', vorherig: '0,2%', aktuell: '-', erklaerung: 'Misst Bestellungen für langlebige Produkte. Wichtiger Indikator für Industrieproduktion.' }
  ];

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    } else {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
    }
    return () => {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const getColorClass = (aktuell, konsens, ereignis) => {
    if (aktuell === '-' || konsens === '-') return '';
    const aktuelValue = parseFloat(aktuell.replace(',', '.').replace('%', ''));
    const konsensValue = parseFloat(konsens.replace(',', '.').replace('%', ''));
    
    // Check if the event is related to inflation or prices
    const isInflationOrPriceRelated = ereignis.toLowerCase().includes('inflation') || 
                                    ereignis.toLowerCase().includes('preis') ||
                                    ereignis.toLowerCase().includes('pce');
    
    if (isInflationOrPriceRelated) {
      // For inflation and price metrics, lower than expected is good (green), higher is bad (red)
      return aktuelValue < konsensValue ? 'green' : aktuelValue > konsensValue ? 'red' : '';
    } else {
      // For other metrics, higher than expected is good (green), lower is bad (red)
      return aktuelValue > konsensValue ? 'green' : aktuelValue < konsensValue ? 'red' : '';
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content calendar-modal">
        <h2>Wirtschaftsdaten-Veröffentlichungen</h2>
        
        <div className="calendar-table-container">
          <table className="calendar-table">
            <thead>
              <tr>
                <th>Datum</th>
                <th>Ereignis</th>
                <th className="hidden-mobile">Konsens</th>
                <th className="hidden-mobile">Vorherig</th>
                <th>Aktuell</th>
              </tr>
            </thead>
            <tbody>
              {wirtschaftsdaten.map((data, index) => (
                <tr key={`economic-${index}`}>
                  <td>{format(data.datum, "dd.MM.yyyy HH:mm", { locale: de })}</td>
                  <td>
                    <span data-tooltip-id={`tooltip-${index}`}>
                      {data.ereignis}
                    </span>
                    <Tooltip id={`tooltip-${index}`} className="custom-tooltip">
                      <span>{data.erklaerung}</span>
                    </Tooltip>
                  </td>
                  <td className="hidden-mobile">{data.konsens}</td>
                  <td className="hidden-mobile">{data.vorherig}</td>
                  <td className={getColorClass(data.aktuell, data.konsens, data.ereignis)}>
                    {data.aktuell}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <button className="calendar-close-modal" onClick={() => setIsOpen(false)}>X</button>
      </div>
    </div>
  );
};

export default EarningsCalendar;
