import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSubscription } from '../contexts/SubscriptionContext';
import VixComponent from '../components/VixComponent';
import AAIIComponent from '../components/AAIIComponent';
import SummaryComponent from '../components/SummaryComponent';
import MarketScoreComponent from '../components/MarketScoreComponent';
import UnemploymentComponent from '../components/UnemploymentComponent';
import FiscalFlowsComponent from '../components/FiscalFlowsComponent';
import M2MoneyGrowthComponent from '../components/M2MoneyGrowthComponent';
import CreditCardDelinquencyComponent from '../components/CreditCardDelinquencyComponent';
import PersonalSavingRateComponent from '../components/PersonalSavingRateComponent';
import EarningsCallComponent from '../components/EarningsCallComponent';
import RecessionIndicatorComponent from '../components/RecessionIndicatorComponent';
import { useMediaQuery } from 'react-responsive';
import Liquiditatsindikator from '../components/Liquiditatsindikator';
import GlobalPEComponent from '../components/GlobalPEComponent';
import ZinsInjektionComponent from '../components/ZinsInjektionComponent';
import AudioPlayerComponent from '../components/AudioPlayerComponent';
import UpgradeOverlay from '../components/UpgradeOverlay';

const Home = () => {
  const { subscription, loading: subscriptionLoading } = useSubscription();
  const [fiscalFlowsState, setFiscalFlowsState] = useState({ state: 'stable', timestamp: new Date().toISOString() });
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    if (!subscriptionLoading) {
      setTimeout(() => {
        setIsVisible(true);
      }, 2000);
    }
  }, [subscriptionLoading]);

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const hasActiveSubscription = subscription && subscription.subscription_status === 'active';

  return (
    <>
      {(!isVisible || subscriptionLoading) && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>Lade Daten...</p>
        </div>
      )}
      
      <div style={{ visibility: isVisible ? 'visible' : 'hidden', position: isVisible ? 'relative' : 'absolute' }}>
        <div className="home-page">
          {!hasActiveSubscription && <UpgradeOverlay />}
          <div 
            ref={containerRef}
            className={`components-grid ${!hasActiveSubscription ? 'blurred' : ''}`}
            style={{
              animation: 'fadeIn 0.5s ease-in forwards'
            }}
          >
            <SummaryComponent />
            <AudioPlayerComponent />
            <Liquiditatsindikator />
            <ZinsInjektionComponent />
            <GlobalPEComponent />
            <RecessionIndicatorComponent />
            <EarningsCallComponent />
            <VixComponent />
            <AAIIComponent />
            <UnemploymentComponent />
            <FiscalFlowsComponent setFiscalFlowsState={setFiscalFlowsState} />
            <M2MoneyGrowthComponent />
            <PersonalSavingRateComponent />
            <CreditCardDelinquencyComponent />
            <MarketScoreComponent fiscalFlowsState={fiscalFlowsState.state} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
