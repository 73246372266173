export const financialEmojis = {
  categories: ['finance'],
  emojis: {
    // Geld & Währungen
    'money_bag': { id: 'money_bag', name: 'Money Bag', keywords: ['money', 'finance'], native: '💰' },
    'chart': { id: 'chart', name: 'Chart Increasing', keywords: ['stocks', 'growth'], native: '📈' },
    'chart_decreasing': { id: 'chart_decreasing', name: 'Chart Decreasing', keywords: ['stocks', 'loss'], native: '📉' },
    'bank': { id: 'bank', name: 'Bank', keywords: ['finance', 'banking'], native: '🏦' },
    'credit_card': { id: 'credit_card', name: 'Credit Card', keywords: ['payment', 'finance'], native: '💳' },
   
    // Geschäft & Handel
    'briefcase': { id: 'briefcase', name: 'Briefcase', keywords: ['business', 'work'], native: '💼' },
   
    // Indikatoren
    'rocket': { id: 'rocket', name: 'Rocket', keywords: ['growth', 'success'], native: '🚀' },
    'hundred': { id: 'hundred', name: 'Hundert Punkte', keywords: ['perfekt', 'top'], native: '💯' },
  }
}

export const emotionEmojis = {
  categories: ['emotions'],
  emojis: {
    // Positive Emotionen
    'smile': { id: 'smile', name: 'Lächelndes Gesicht', keywords: ['glücklich', 'froh'], native: '😊' },
    'joy': { id: 'joy', name: 'Freudetränen', keywords: ['lachen', 'freude'], native: '😂' },
    'heart_eyes': { id: 'heart_eyes', name: 'Verliebtes Gesicht', keywords: ['liebe', 'begeistert'], native: '😍' },
    'money_mouth': { id: 'money_mouth', name: 'Gesicht mit Geldmund', keywords: ['geld', 'reich', 'cash'], native: '🤑' },
   
    // Negative Emotionen
    'sad': { id: 'sad', name: 'Trauriges Gesicht', keywords: ['traurig', 'enttäuscht'], native: '😢' },
    'angry': { id: 'angry', name: 'Wütendes Gesicht', keywords: ['wütend', 'sauer'], native: '😠' },
    'rage': { id: 'rage', name: 'Sehr wütendes Gesicht', keywords: ['wütend', 'zornig'], native: '🤬' },
    'worried': { id: 'worried', name: 'Besorgtes Gesicht', keywords: ['besorgt', 'ängstlich'], native: '😟' },
    
    // Neutrale Emotionen
    'thinking': { id: 'thinking', name: 'Nachdenkendes Gesicht', keywords: ['überlegen', 'zweifeln'], native: '🤔' },
    'neutral': { id: 'neutral', name: 'Neutrales Gesicht', keywords: ['neutral', 'okay'], native: '😐' },
    'wink': { id: 'wink', name: 'Zwinkerndes Gesicht', keywords: ['zwinkern', 'spaß'], native: '😉' },

    //Bewertungen
    'thumbs_up': { id: 'thumbs_up', name: 'Daumen hoch', keywords: ['zustimmung', 'gut'], native: '👍' },
    'thumbs_down': { id: 'thumbs_down', name: 'Daumen runter', keywords: ['schlecht', 'negativ'], native: '👎' },
    'handshake': { id: 'handshake', name: 'Handshake', keywords: ['deal', 'business'], native: '🤝' },
  }

} 