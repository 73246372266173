export const smoothScrollToBottom = (ref) => {
  requestAnimationFrame(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "auto" });
      const container = document.querySelector('.messages-container');
      if (container) {
        container.style.scrollbarWidth = 'none';
        container.style.msOverflowStyle = 'none';
        setTimeout(() => {
          container.style.scrollbarWidth = '';
          container.style.msOverflowStyle = '';
        }, 100);
      }
    }
  });
}; 