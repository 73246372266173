import React, { useState } from 'react';
import { useSubscription } from '../contexts/SubscriptionContext';
import Spinner from '../components/Spinner';
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import '../styles/tools.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const Tools = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [insiderTransactions, setInsiderTransactions] = useState([]);
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [transactionsError, setTransactionsError] = useState(null);
  const [expandedPerson, setExpandedPerson] = useState(null);
  const { subscription } = useSubscription();
  const [chartMode, setChartMode] = useState('dollars');
  const [showExplanation, setShowExplanation] = useState(false);
  const [insiderSentiment, setInsiderSentiment] = useState([]);
  const [sentimentLoading, setSentimentLoading] = useState(false);
  const [sentimentError, setSentimentError] = useState(null);
  const [showSentimentExplanation, setShowSentimentExplanation] = useState(false);

  const hasActiveSubscription = subscription && subscription.subscription_status === 'active';

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const performSearch = async (term) => {
    if (!term || term.length < 2) {
      setSearchResults([]);
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `https://finnhub.io/api/v1/search?q=${encodeURIComponent(term)}&token=csh1fshr01qldu0d0bbgcsh1fshr01qldu0d0bc0`
      );
      const data = await response.json();
      
      console.log('API Response:', data);

      if (data.error) {
        setError(data.error);
        setSearchResults([]);
        return;
      }

      if (data.result && Array.isArray(data.result)) {
        setSearchResults(data.result);
        if (data.result.length === 0) {
          setError('Keine Ergebnisse gefunden');
        }
      } else {
        setSearchResults([]);
        setError('Keine Ergebnisse gefunden');
      }
    } catch (err) {
      console.error('Search error:', err);
      setError('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
      setSearchResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedSearch = React.useCallback(
    debounce((term) => performSearch(term), 300),
    []
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const fetchInsiderTransactions = async (symbol) => {
    setTransactionsLoading(true);
    setTransactionsError(null);

    try {
      const response = await fetch(
        `https://finnhub.io/api/v1/stock/insider-transactions?symbol=${symbol}&token=csh1fshr01qldu0d0bbgcsh1fshr01qldu0d0bc0`
      );
      const data = await response.json();

      if (data.data && Array.isArray(data.data)) {
        setInsiderTransactions(data.data);
        if (data.data.length === 0) {
          setTransactionsError('Keine Insider-Transaktionen gefunden');
        }
      } else {
        setTransactionsError('Fehler beim Laden der Transaktionen');
      }
    } catch (err) {
      console.error('Transactions fetch error:', err);
      setTransactionsError('Fehler beim Laden der Transaktionen');
    } finally {
      setTransactionsLoading(false);
    }
  };

  const fetchInsiderSentiment = async (symbol) => {
    setSentimentLoading(true);
    setSentimentError(null);

    const today = new Date();
    const threeYearsAgo = new Date();
    threeYearsAgo.setFullYear(today.getFullYear() - 3);

    const fromDate = threeYearsAgo.toISOString().split('T')[0];
    const toDate = today.toISOString().split('T')[0];

    try {
      const response = await fetch(
        `https://finnhub.io/api/v1/stock/insider-sentiment?symbol=${symbol}&from=${fromDate}&to=${toDate}&token=csh1fshr01qldu0d0bbgcsh1fshr01qldu0d0bc0`
      );
      const data = await response.json();

      if (data.data && Array.isArray(data.data)) {
        setInsiderSentiment(data.data);
        if (data.data.length === 0) {
          setSentimentError('Keine Insider-Sentiment-Daten gefunden');
        }
      } else {
        setSentimentError('Fehler beim Laden der Sentiment-Daten');
      }
    } catch (err) {
      console.error('Sentiment fetch error:', err);
      setSentimentError('Fehler beim Laden der Sentiment-Daten');
    } finally {
      setSentimentLoading(false);
    }
  };

  const handleSymbolSelect = (symbol) => {
    setSelectedSymbol(symbol);
    setSearchResults([]);
    setSearchTerm('');
    fetchInsiderSentiment(symbol);
    fetchInsiderTransactions(symbol);
    
    if (window.gtag) {
      window.gtag('event', 'select_ticker', {
        'event_category': 'ticker_search',
        'event_label': symbol,
        'ticker_symbol': symbol,
        'non_interaction': false
      });
    }
  };

  const getTransactionType = (code) => {
    const types = {
      'P': { text: 'Kauf' },
      'S': { text: 'Verkauf' },
      'M': { text: 'Option ausgeübt' },
      'G': { text: 'Geschenk' },
      'F': { text: 'Steuern' },
    };
    return types[code] || { text: code };
  };

  const summarizeTransactions = (transactions) => {
    const personSummary = transactions.reduce((acc, t) => {
      const date = new Date(t.transactionDate);
      const quarter = Math.floor(date.getMonth() / 3) + 1;
      const year = date.getFullYear();
      const key = `${t.name}_${year}_Q${quarter}`;
      
      if (!acc[key]) {
        acc[key] = {
          name: t.name,
          year,
          quarter,
          netShares: 0,
          totalValue: 0,
          transactions: [],
        };
      }
      
      acc[key].transactions.push(t);
      acc[key].netShares += t.change;
      acc[key].totalValue += t.change * t.transactionPrice;
      
      return acc;
    }, {});

    return Object.values(personSummary).sort((a, b) => {
      return b.year - a.year || b.quarter - a.quarter;
    });
  };

  const generateSummaryText = (summary) => {
    const latestTransaction = summary.transactions[summary.transactions.length - 1];
    const totalShares = Math.abs(summary.netShares);
    const totalValue = Math.abs(summary.totalValue);
    const action = summary.netShares > 0 ? 'gekauft' : 
                  summary.netShares < 0 ? 'verkauft' : 
                  'gehandelt';
    
    const summaryClass = summary.netShares > 0 ? 'increased' : 
                        summary.netShares < 0 ? 'reduced' : 
                        'neutral';
    
    return (
      <div className={`transaction-summary ${summaryClass}`}>
        <strong>{summary.name}</strong>
        {' '}hat {totalShares.toLocaleString()} Aktien {action} für ${totalValue.toLocaleString()},
        {' '}besitzt jetzt insgesamt {latestTransaction.share.toLocaleString()} Aktien.
      </div>
    );
  };

  const prepareChartData = (summaries) => {
    const quarterlyData = summaries.reduce((acc, summary) => {
      const key = `${summary.year}_Q${summary.quarter}`;
      if (!acc[key]) {
        acc[key] = {
          year: summary.year,
          quarter: summary.quarter,
          purchases: 0,
          purchaseShares: 0,
          sales: 0,
          saleShares: 0
        };
      }
      
      summary.transactions.forEach(t => {
        if (t.change > 0) {
          acc[key].purchases += t.change * t.transactionPrice;
          acc[key].purchaseShares += t.change;
        } else {
          acc[key].sales += Math.abs(t.change * t.transactionPrice);
          acc[key].saleShares += Math.abs(t.change);
        }
      });
      
      return acc;
    }, {});

    const sortedQuarters = Object.values(quarterlyData)
      .sort((a, b) => {
        if (a.year !== b.year) return a.year - b.year;
        return a.quarter - b.quarter;
      });

    const isShareMode = chartMode === 'shares';
    
    return {
      labels: sortedQuarters.map(q => `Q${q.quarter} ${q.year}`),
      datasets: [
        {
          label: isShareMode ? 'Gekaufte Aktien' : 'Käufe ($)',
          data: sortedQuarters.map(q => isShareMode ? q.purchaseShares : q.purchases),
          backgroundColor: 'rgba(40, 167, 69, 0.2)',
          borderColor: 'rgba(40, 167, 69, 1)',
          borderWidth: 1,
        },
        {
          label: isShareMode ? 'Verkaufte Aktien' : 'Verkäufe ($)',
          data: sortedQuarters.map(q => isShareMode ? q.saleShares : q.sales),
          backgroundColor: 'rgba(220, 53, 69, 0.2)',
          borderColor: 'rgba(220, 53, 69, 1)',
          borderWidth: 1,
        },
      ],
    };
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => {
            if (chartMode === 'shares') {
              return value.toLocaleString() + ' Aktien';
            }
            return `$${Math.abs(value).toLocaleString()}`;
          }
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: chartMode === 'shares' ? 'Insider-Transaktionen nach Aktien' : 'Insider-Transaktionen nach Wert',
      },
    },
  };

  const prepareSentimentChartData = (sentimentData) => {
    const sortedData = [...sentimentData].sort((a, b) => {
      if (a.year !== b.year) return a.year - b.year;
      return a.month - b.month;
    });

    return {
      labels: sortedData.map(d => `${d.year}-${String(d.month).padStart(2, '0')}`),
      datasets: [
        {
          label: '100X Indikator',
          data: sortedData.map(d => d.mspr),
          borderColor: 'rgba(57, 255, 20, 1)',
          backgroundColor: 'rgba(57, 255, 20, 0.2)',
          borderWidth: 2,
          fill: true,
        }
      ]
    };
  };

  const sentimentChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        position: 'left',
        title: {
          display: true,
          text: '100X Indikator (-100 bis 100)'
        }
      }
    },
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: '100X Indikator Analyse',
      }
    }
  };

  const insiderTransactionsExplanation = `
Insider-Transaktionen: Ein wichtiger Indikator für Anlageentscheidungen

Insider-Transaktionen sind Wertpapiertransaktionen von Führungskräften, Vorständen und Großaktionären eines Unternehmens. Die Analyse dieser Transaktionen kann wertvolle Einblicke bieten:

1. Informationsvorsprung:
   • Insider haben tiefe Einblicke in das operative Geschäft
   • Sie kennen die Unternehmensstrategie und -entwicklung
   • Ihre Handelsentscheidungen können Hinweise auf zukünftige Entwicklungen geben

2. Signalwirkung:
   • Große Käufe → positives Signal (Vertrauen in das Unternehmen)
   • Massive Verkäufe → mögliches Warnsignal
   • Timing und Volumen der Transaktionen sind besonders aussagekräftig

3. Interpretationsansätze:
   • Einzelne Transaktionen → weniger aussagekräftig
   • Gehäufte Transaktionen mehrerer Insider → stärkeres Signal
   • Berücksichtigung des Marktumfelds und der Unternehmensphase

4. Wichtige Aspekte:
   • Reguläre Verkäufe zur Diversifikation sind normal
   • Aktienoptionsprogramme können Verkäufe notwendig machen
   • Steuerliche Gründe können Transaktionen beeinflussen

5. Analysemethoden:
   • Cluster-Analyse von Insider-Aktivitäten
   • Verhältnis von Käufen zu Verkäufen
   • Historische Vergleiche der Transaktionsmuster

6. Risiken und Einschränkungen:
   • Keine Garantie für Kursentwicklung
   • Zeitverzögerte Veröffentlichung der Daten
   • Mögliche persönliche Gründe für Transaktionen

Wichtiger Hinweis:
Insider-Transaktionen sollten nie als alleiniges Kriterium für Investitionsentscheidungen verwendet werden. Sie sind ein Baustein in der Gesamtanalyse eines Unternehmens.
`;

  return (
    <div className="tools-page">
      <h1>Tools</h1>
      
      {!hasActiveSubscription ? (
        <div className="upgrade-overlay">
          <div className="upgrade-message">
            <h2>Upgrade erforderlich</h2>
            <p>Um auf alle Funktionen zugreifen zu können, benötigen Sie ein aktives Abonnement.</p>
            <a href="https://myablefy.com/s/projekt100x/projekt-100x-monatliche-zahlung-cb9109dc/payment" className="upgrade-button">
              Jetzt upgraden
            </a>
          </div>
        </div>
      ) : (
        <div className="tools-container">
          <section className="ticker-search">
            <h2>Ticker Suche</h2>
            <form onSubmit={(e) => e.preventDefault()}>
              <input
                type="text"
                value={searchTerm}
                onChange={handleInputChange}
                placeholder="Unternehmen oder Symbol suchen..."
                className="search-input"
              />
            </form>

            {isLoading && <Spinner />}
            
            {error && <div className="error-message">{error}</div>}

            {searchResults.length > 0 && (
              <div className="search-results">
                <table>
                  <thead>
                    <tr>
                      <th>Symbol</th>
                      <th>Name</th>
                      <th>Typ</th>
                      <th>Aktion</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchResults.slice(0, 20).map((result, index) => (
                      <tr key={index}>
                        <td>{result.displaySymbol}</td>
                        <td>{result.description}</td>
                        <td>{result.type}</td>
                        <td>
                          <button 
                            onClick={() => handleSymbolSelect(result.displaySymbol)}
                            className="select-button"
                          >
                            Auswählen
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </section>

          {selectedSymbol && (
            <section className="insider-sentiment">
              <h3>Insider-Sentiment für {selectedSymbol}</h3>
              
              {sentimentLoading && <Spinner />}
              {sentimentError && <div className="error-message">{sentimentError}</div>}
              
              {insiderSentiment.length > 0 && (
                <>
                  <button 
                    onClick={() => setShowSentimentExplanation(!showSentimentExplanation)} 
                    className="expand-button explanation-toggle"
                  >
                    {showSentimentExplanation ? 'Erklärung ausblenden' : 'Mehr über den Indikator erfahren'}
                  </button>
                  
                  {showSentimentExplanation && (
                    <div className="explanation-section">
                      <pre className="expanded-explanation">
                        Der 100X Insider-Sentiment-Indikator basiert auf dem Verhältnis von Aktienkäufen zu -verkäufen durch Unternehmensinsider. 
                        Hierbei werden ausschließlich gezielte Kauf- und Verkaufsaktionen berücksichtigt, um die tatsächlichen 
                        Handelsabsichten der Insider abzubilden, ohne dass ungewollte Aktienzuteilungen (z. B. durch Kompensationen) 
                        die Analyse verfälschen. Der Indikator neigt dazu, als positiv bewertet zu werden, wenn das Unternehmen 
                        Netto-Kaufaktivitäten verzeichnet, was auf eine positive Erwartungshaltung der Insider hinsichtlich des 
                        Unternehmenswertes hinweist. Ist das Verhältnis nahe bei 1, signalisiert dies potenziell steigende Kurse; 
                        liegt es bei -1, wird ein Kursrückgang erwartet.
                      </pre>
                    </div>
                  )}

                  <div className="sentiment-chart">
                    <Line 
                      data={prepareSentimentChartData(insiderSentiment)} 
                      options={sentimentChartOptions}
                      height={300}
                    />
                  </div>
                </>
              )}
            </section>
          )}

          {selectedSymbol && (
            <section className="insider-transactions">
              <h3>Insider-Transaktionen für {selectedSymbol}</h3>
              
              <button 
                onClick={() => setShowExplanation(!showExplanation)} 
                className="expand-button explanation-toggle"
              >
                {showExplanation ? 'Erklärung ausblenden' : 'Mehr über Insider-Transaktionen erfahren'}
              </button>
              
              {showExplanation && (
                <div className="explanation-section">
                  <pre className="expanded-explanation">
                    {insiderTransactionsExplanation}
                  </pre>
                </div>
              )}

              {transactionsLoading && <Spinner />}
              {transactionsError && <div className="error-message">{transactionsError}</div>}
              
              {insiderTransactions.length > 0 && (
                <>
                  <div className="chart-controls">
                    <div className="chart-mode-toggle">
                      <button 
                        className={`toggle-button ${chartMode === 'dollars' ? 'active' : ''}`}
                        onClick={() => setChartMode('dollars')}
                      >
                        Dollar Betrag
                      </button>
                      <button 
                        className={`toggle-button ${chartMode === 'shares' ? 'active' : ''}`}
                        onClick={() => setChartMode('shares')}
                      >
                        Aktien Anzahl
                      </button>
                    </div>
                  </div>
                  <div className="transactions-chart">
                    <Bar 
                      data={prepareChartData(summarizeTransactions(insiderTransactions))} 
                      options={chartOptions}
                      height={300}
                    />
                  </div>

                  <div className="transactions-table-container">
                    <table className="transactions-table">
                      <thead>
                        <tr>
                          <th>Zusammenfassung</th>
                          <th>Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {summarizeTransactions(insiderTransactions).map((summary, index) => {
                          const latestTransaction = summary.transactions[summary.transactions.length - 1];
                          const earliestTransaction = summary.transactions[0];
                          const totalValue = summary.transactions.reduce((total, t) => 
                            total + (Math.abs(t.change) * t.transactionPrice), 0
                          );
                          
                          // Determine row class based on percentage change
                          const rowClass = summary.netShares > 0 ? 'increased' : 
                                          summary.netShares < 0 ? 'reduced' : 
                                          'neutral';
                          
                          return (
                            <React.Fragment key={index}>
                              <tr className={rowClass}>
                                <td className="summary-cell">
                                  <div className="transaction-summary">
                                    <strong>{summary.name}</strong>
                                    {' '}hat {Math.abs(summary.netShares).toLocaleString()} Aktien{' '}
                                    {summary.netShares > 0 ? 'gekauft' : 'verkauft'} für ${totalValue.toLocaleString()},
                                    {' '}besitzt jetzt insgesamt {latestTransaction.share.toLocaleString()} Aktien.
                                  </div>
                                </td>
                                <td>
                                  <button 
                                    onClick={() => setExpandedPerson(expandedPerson === `${summary.name}_${summary.year}_Q${summary.quarter}` ? null : `${summary.name}_${summary.year}_Q${summary.quarter}`)}
                                    className="expand-button"
                                  >
                                    {expandedPerson === `${summary.name}_${summary.year}_Q${summary.quarter}` ? 'Verbergen' : 'Details'}
                                  </button>
                                </td>
                              </tr>
                              {expandedPerson === `${summary.name}_${summary.year}_Q${summary.quarter}` && (
                                <tr>
                                  <td colSpan="5">
                                    <table className="nested-transactions-table">
                                      <thead>
                                        <tr>
                                          <th>Datum</th>
                                          <th>Aktien</th>
                                          <th>Änderung</th>
                                          <th>Preis</th>
                                          <th>Art</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {summary.transactions.map((transaction, tIndex) => {
                                          const transactionType = getTransactionType(transaction.transactionCode);
                                          return (
                                            <tr key={tIndex}>
                                              <td>{new Date(transaction.transactionDate).toLocaleDateString()}</td>
                                              <td>{transaction.share.toLocaleString()}</td>
                                              <td>
                                                {transaction.change > 0 ? '+' : ''}{transaction.change.toLocaleString()}
                                              </td>
                                              <td>${transaction.transactionPrice.toFixed(2)}</td>
                                              <td>
                                                <span>{transactionType.text}</span>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </section>
          )}
        </div>
      )}
    </div>
  );
};

export default Tools; 