import React, { useState, useEffect, useRef } from 'react';
import { supabase, sendMessage, deleteMessage, updateMessage, uploadImage, likeMessage, unlikeMessage } from '../supabaseClient';
import { useSubscription } from '../contexts/SubscriptionContext';
import { Link } from 'react-router-dom';
import ImageModal from '../components/ImageModal'; // You'll need to create this component

const ADMIN_EMAIL = '100x@maximilian.business';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [user, setUser] = useState(null);
  const [editingMessage, setEditingMessage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [imageToUpload, setImageToUpload] = useState(null);
  const [enlargedImage, setEnlargedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const messagesEndRef = useRef(null);
  const [likedMessages, setLikedMessages] = useState({});
  const [activeUsers, setActiveUsers] = useState(0);
  const [shouldScroll, setShouldScroll] = useState(true);
  const [messageLikes, setMessageLikes] = useState({});

  const { subscription } = useSubscription();

  const hasActiveSubscription = subscription && subscription.subscription_status === 'active';
  const isAdmin = user && user.email === ADMIN_EMAIL;

  useEffect(() => {
    const checkUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      console.log("Current user:", user);
      setUser(user);
    };
    checkUser();

    // Subscribe to real-time updates
    const subscription = supabase
      .channel('public_chat')
      .on('postgres_changes', { 
        event: 'INSERT', 
        schema: 'public', 
        table: 'public_chat' 
      }, async payload => {
        // Fetch the complete message data including user_subscription
        const { data, error } = await supabase
          .from('public_chat')
          .select(`
            *,
            user_subscription:user_subscriptions!user_subscription_id (
              nickname
            )
          `)
          .eq('id', payload.new.id)
          .single();

        if (!error && data) {
          setMessages(currentMessages => [...currentMessages, data]);
        }
      })
      .subscribe();

    // Fetch initial messages
    fetchMessages();

    // Fetch active users
    const fetchActiveUsers = async () => {
      try {
        const response = await supabase.functions.invoke('calculate-active-users');
        if (response.error) throw response.error;
        setActiveUsers(response.data.activeUsers);
      } catch (error) {
        console.error('Error fetching active users:', error);
        setActiveUsers(69); // fallback number
      }
    };

    fetchActiveUsers();

    return () => {
      supabase.removeChannel(subscription);
    };
  }, []);

  useEffect(() => {
    if (shouldScroll) {
      scrollToBottom();
    }
  }, [messages, shouldScroll]);

  useEffect(() => {
    if (messages.length > 0 && user) {
      const liked = {};
      messages.forEach(msg => {
        let likes = [];
        try {
          likes = JSON.parse(msg.like || '[]');
        } catch (e) {
          console.error('Error parsing likes:', e);
        }
        liked[msg.id] = Array.isArray(likes) && likes.includes(user.id);
      });
      setLikedMessages(liked);
    }
  }, [messages, user]);

  useEffect(() => {
    const calculateLikes = async () => {
      const likes = {};
      for (const msg of messages) {
        likes[msg.id] = await calculateDisplayLikes(msg);
      }
      setMessageLikes(likes);
    };
    calculateLikes();
  }, [messages]);

  const fetchMessages = async () => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    
    const { data, error } = await supabase
      .from('public_chat')
      .select(`
        id,
        date,
        name,
        message,
        reply_to,
        like,
        status,
        user_subscription:user_subscriptions!user_subscription_id (
          nickname,
          user_id
        )
      `)
      .gte('date', sevenDaysAgo.toISOString())
      .order('date', { ascending: true });

    if (error) {
      console.error('Error fetching messages:', error);
    } else {
      setMessages(data);
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
      }, 300);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if ((!newMessage.trim() && !imageToUpload) || !user) return;

    try {
      let messageContent = newMessage;

      if (imageToUpload) {
        setIsUploading(true);
        const imageUrl = await uploadImage(imageToUpload);
        messageContent += `\n[Image](${imageUrl})`;
        setIsUploading(false);
        setImageToUpload(null);
        setImagePreview(null);
      }

      const messageData = {
        name: user.email,
        message: messageContent
      };
      await sendMessage(messageData);
      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleEdit = (msg) => {
    setEditingMessage(msg);
    setNewMessage(msg.message);
  };

  const handleUpdate = async () => {
    if (!editingMessage || !newMessage.trim()) return;

    try {
      await updateMessage(editingMessage.id, newMessage);
      setMessages(messages.map(msg => 
        msg.id === editingMessage.id ? {...msg, message: newMessage} : msg
      ));
      setEditingMessage(null);
      setNewMessage('');
    } catch (error) {
      console.error('Error updating message:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const { error } = await deleteMessage(id);
      if (error) throw error;
      // Only update UI after successful deletion
      setMessages(messages.filter(msg => msg.id !== id));
    } catch (error) {
      console.error('Error deleting message:', error);
      alert('Failed to delete message. Please try again.');
    }
  };

  const formatName = (email, msg) => {
    if (email === ADMIN_EMAIL) return 'Max';
    
    // Use the nickname from the joined user_subscription data
    if (msg.user_subscription?.nickname) {
      return msg.user_subscription.nickname;
    }
    
    return email.split('@')[0];
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}. ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleImageUpload = async (e) => {
    if (!user) {
      console.error('User not authenticated');
      return;
    }
    const file = e.target.files[0];
    if (!file) return;

    setImageToUpload(file);
    
    // Create a preview of the image
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const renderMessageContent = (content) => {
    const parts = content.split(/(\[Image\]\(.*?\))/);
    const imageUrls = [];
    const textParts = [];

    parts.forEach((part) => {
      const imageMatch = part.match(/\[Image\]\((.*?)\)/);
      if (imageMatch) {
        imageUrls.push(imageMatch[1]);
      } else if (part.trim()) {
        textParts.push(part.trim());
      }
    });

    return (
      <>
        {imageUrls.map((url, index) => (
          <img
            key={index}
            src={url}
            alt="Uploaded"
            className="message-image"
            onClick={() => setEnlargedImage(url)}
          />
        ))}
        {textParts.length > 0 && (
          <div className="message-text">
            {textParts.map((text, index) => (
              <p key={index} style={{ whiteSpace: 'pre-wrap' }}>
                {text.split('\n').map((line, i) => (
                  <React.Fragment key={i}>
                    {line}
                    {i < text.split('\n').length - 1 && <br />}
                  </React.Fragment>
                ))}
              </p>
            ))}
          </div>
        )}
      </>
    );
  };

  const handleLike = async (messageId) => {
    if (!user) return;

    try {
      setShouldScroll(false);
      console.log(`Attempting to like/unlike message ${messageId} for user ${user.id}`);
      let updatedMessage;
      if (likedMessages[messageId]) {
        console.log('Unliking message');
        updatedMessage = await unlikeMessage(messageId, user.id);
      } else {
        console.log('Liking message');
        updatedMessage = await likeMessage(messageId, user.id);
      }
      
      console.log('Response from like/unlike operation:', updatedMessage);

      if (updatedMessage && updatedMessage[0] && updatedMessage[0].like !== undefined) {
        console.log('Updating local state');
        setLikedMessages(prev => ({
          ...prev,
          [messageId]: !prev[messageId]
        }));

        setMessages(prevMessages => 
          prevMessages.map(msg => 
            msg.id === messageId ? { ...msg, like: updatedMessage[0].like } : msg
          )
        );
      } else {
        console.error('Unexpected response structure:', updatedMessage);
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error in handleLike:', error);
      alert('Failed to like/unlike the message. Please try again.');
    } finally {
      setShouldScroll(true);
    }
  };

  const calculateDisplayLikes = async (msg) => {
    try {
      const response = await supabase.functions.invoke('calculate-engagement', {
        body: msg
      });
      
      if (response.error) throw response.error;
      return response.data.totalLikes;
    } catch (e) {
      console.error('Error calculating likes:', e);
      return JSON.parse(msg.like || '[]').length; // Fallback to actual likes only
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="chat-page">
      {!hasActiveSubscription ? (
        <div className="upgrade-overlay">
          <div className="upgrade-message">
            <h2>Upgrade erforderlich</h2>
            <p>Um auf den Chat zugreifen zu können, benötigen Sie ein aktives Abonnement.</p>
            <a href="https://myablefy.com/s/projekt100x/projekt-100x-monatliche-zahlung-cb9109dc/payment" className="upgrade-button">Jetzt upgraden</a>
          </div>
        </div>
      ) : (
        <div className="chat-container">
          <div className="messages-container">
            {messages.map((msg) => {
              const isOwnMessage = msg.name === user.email;
              return (
                <div key={msg.id} className={`message-wrapper ${isOwnMessage ? 'own-message' : 'other-message'}`}>
                  <div className={`message ${msg.name === ADMIN_EMAIL ? 'admin-message' : ''}`}>
                    <div className="message-bubble">
                      <div className="message-header">
                        <span className="message-name">{formatName(msg.name, msg)}</span>
                        <span className="message-datetime">{formatDateTime(msg.date)}</span>
                      </div>
                      <div className="message-content">
                        {renderMessageContent(msg.message)}
                      </div>
                    </div>
                    <div className="message-actions">
                      <button 
                        onClick={() => handleLike(msg.id)}
                        className={`like-button ${likedMessages[msg.id] ? 'liked' : ''}`}
                      >
                        {likedMessages[msg.id] ? '❤️' : '🤍'} {messageLikes[msg.id] || 0}
                      </button>
                      {(isAdmin || isOwnMessage) && (
                        <>
                          <button onClick={() => handleEdit(msg)}>Edit</button>
                          <button onClick={() => handleDelete(msg.id)}>Delete</button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            <div ref={messagesEndRef} />
          </div>
          <div className="chat-input-area">
            <div className="active-users-indicator">
              <span className="active-users-dot"></span>
              <span className="active-users-text">{activeUsers} aktive Nutzer in den letzten 24 Stunden</span>
            </div>
            <form className="message-form" onSubmit={handleSubmit}>
              <textarea
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder={editingMessage ? "Edit your message..." : "Nachricht eingeben..."}
                className="message-input"
                rows="3"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleSubmit(e);
                  }
                }}
              />
              {imagePreview && (
                <div className="image-preview">
                  <img src={imagePreview} alt="Preview" />
                  <button type="button" onClick={() => {
                    setImageToUpload(null);
                    setImagePreview(null);
                  }}>Remove</button>
                </div>
              )}
              <label htmlFor="image-upload" className={`image-upload-label ${isUploading ? 'disabled' : ''}`}>
                📷
                <input
                  id="image-upload"
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  style={{ display: 'none' }}
                  disabled={isUploading}
                />
              </label>
              <button type="submit" className="send-button" disabled={isUploading}>
                <span className="send-icon">{editingMessage ? '✓' : '➤'}</span>
              </button>
              {editingMessage && (
                <button type="button" onClick={() => setEditingMessage(null)} className="cancel-button">
                  Cancel
                </button>
              )}
            </form>
          </div>
          {enlargedImage && (
            <ImageModal imageUrl={enlargedImage} onClose={() => setEnlargedImage(null)} />
          )}
        </div>
      )}
    </div>
  );
};

export default Chat;
