import React from 'react';
import { Link } from 'react-router-dom';
import { useSubscription } from '../contexts/SubscriptionContext';
import '../styles/VideoLibrary.css'; // Add this import

const Mastermind = () => {
  const { subscription } = useSubscription();
  const hasActiveSubscription = subscription && subscription.subscription_status === 'active';
  
  // Update countdown calculation to target Oct 27, 23:59 German time
  const getCountdownDate = () => {
    const targetDate = new Date('2024-11-06T23:59:00+01:00'); // German timezone (CET - winter time)
    return targetDate;
  };

  const [timeLeft, setTimeLeft] = React.useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = getCountdownDate().getTime() - now;
      
      // If countdown is finished
      if (distance < 0) {
        setTimeLeft({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        });
        clearInterval(timer);
        return;
      }

      setTimeLeft({
        days: Math.floor(distance / (1000 * 60 * 60 * 24)),
        hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance % (1000 * 60)) / 1000)
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="mastermind-page">
      <h1>100X Mastermind</h1>
      
      <div className="broker-recommendation">
        <h2>Unser empfohlener Broker</h2>
        <div className="broker-content">
          <div className="broker-info">
            <p>Für die optimale Umsetzung unserer Strategien empfehlen wir IBKR (Interactive Brokers):</p>
            <ul className="broker-features">
              <li>Niedrige Handelsgebühren</li>
              <li>Umfangreiche Optionshandels-Möglichkeiten</li>
              <li>Zuverlässigste Trading-Plattform</li>
              <li>Bis zu $1.000 Anmeldeprämie in Aktien ausgezahlt</li>
            </ul>
            <a href="https://ibkr.com/referral/maximilian328" 
               target="_blank" 
               rel="noopener noreferrer" 
               className="broker-cta">
              Jetzt bei IBKR registrieren
            </a>
          </div>
        </div>
      </div>

      <p>Tauche ein in die wachsende Video-Bibliothek. Profitiere von praxisnahen Tutorials und Wissen für fortgeschrittene Kapitalmarktstrategien.</p>

      {!hasActiveSubscription && (
        <div className="upgrade-overlay">
          <div className="upgrade-message">
            <h2>Upgrade erforderlich</h2>
            <p>Um auf alle Funktionen zugreifen zu können, benötigst du ein aktives Abonnement.</p>
            <a href="https://myablefy.com/s/projekt100x/projekt-100x-monatliche-zahlung-cb9109dc/payment" className="upgrade-button">Jetzt upgraden</a>
          </div>
        </div>
      )}

      <div className={`course-library ${!hasActiveSubscription ? 'blurred' : ''}`}>
        {/* Featured Course */}
        <section className="featured-course">
          <div className="featured-course-card">
            <div className="course-info">
              <h3>Wie du die 100X App verwendest</h3>
              <p>Lerne die Grundlagen unserer 100X App kennen, um dein Investitionserlebnis zu optimieren.</p>
              <Link to="/course/app-introduction" className="enroll-button">
                Jetzt starten
              </Link>
            </div>
          </div>
        </section>

        {/* Covered Calls Course */}
        <section className="featured-course">
          <div className="featured-course-card">
            <div className="course-info">
              <div className="course-tags">
                <span className="tag">Anfänger</span>
                <span className="tag">Niedriges Risiko</span>
              </div>
              <h3>Covered Calls Strategie</h3>
              <p>Generiere zusätzliches Einkommen aus deinen bestehenden Aktienbeständen. Lerne, wie du mit der Covered Calls Strategie regelmäßige Prämieneinnahmen erzielst und dein Portfolio optimierst.</p>
              <Link to="/covered-calls" className="enroll-button">
                Jetzt starten
              </Link>
            </div>
          </div>
        </section>

        {/* Coming Soon Teaser */}
        <section className="coming-soon-teaser">
          <div className="teaser-card">
            <h3>Weitere Kurse in Kürze verfügbar</h3>
            <p>Bleib gespannt auf neue Strategien und Anleitungen. Wir erweitern unsere Bibliothek kontinuierlich mit wertvollen Inhalten für dich.</p>
            <div className="coming-soon-badge">Demnächst verfügbar</div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Mastermind;
