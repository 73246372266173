import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://jfhelnsdfzbwwrmbuoms.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImpmaGVsbnNkZnpid3dybWJ1b21zIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjY2NTc0NTMsImV4cCI6MjA0MjIzMzQ1M30.znNkgn8tTnmSFZYmRYV6IbTKrWYtg9Ql-EDvpPyOTgA';

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    storage: {
      getItem: (key) => document.cookie.match(new RegExp(`(^| )${key}=([^;]+)`))?.[2],
      setItem: (key, value) => {
        const expiresIn = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
        const expires = new Date(Date.now() + expiresIn).toUTCString();
        document.cookie = `${key}=${value}; expires=${expires}; path=/; SameSite=Strict; Secure`;
      },
      removeItem: (key) => {
        document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Strict; Secure`;
      },
    },
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: true,
  },
  db: {
    schema: 'public'
  },
  debug: true
});

export const sendMessage = async ({ name, message, reply_to }) => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('User not authenticated');
  
  const { data, error } = await supabase
    .from('public_chat')
    .insert([{ 
      name, 
      message, 
      reply_to,
      user_subscription_id: user.id,
      date: new Date().toISOString()
    }]);

  if (error) throw error;
  return data;
};

export const deleteMessage = async (messageId) => {
  const { error } = await supabase
    .from('public_chat')
    .delete()
    .eq('id', messageId);

  if (error) throw error;
  return { success: true };
};

export const updateMessage = async (messageId, newContent) => {
  const { error } = await supabase
    .from('public_chat')
    .update({ message: newContent })
    .eq('id', messageId);

  if (error) throw error;
};

export const getUserSubscription = async () => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) return null;

  const { data, error } = await supabase
    .from('user_subscriptions')
    .select('*')
    .eq('user_id', user.id)
    .single();

  if (error) throw error;
  return data;
};

export const updateUserSubscription = async (userId, subscriptionData) => {
  const { data, error } = await supabase
    .from('user_subscriptions')
    .update(subscriptionData)
    .eq('user_id', userId)
    .select();

  if (error) throw error;
  return data;
};

export const uploadImage = async (file) => {
  const fileExt = file.name.split('.').pop();
  const fileName = `${Math.random()}.${fileExt}`;
  const filePath = `chat-images/${fileName}`;

  try {
    const { data, error } = await supabase.storage
      .from('chat-images')
      .upload(filePath, file);

    if (error) {
      console.error('Error uploading file:', error);
      throw error;
    }

    const { data: publicUrl, error: urlError } = supabase.storage
      .from('chat-images')
      .getPublicUrl(filePath);

    if (urlError) {
      console.error('Error getting public URL:', urlError);
      throw urlError;
    }

    return publicUrl.publicUrl;
  } catch (error) {
    console.error('Unexpected error in uploadImage:', error);
    throw error;
  }
};

export const likeMessage = async (messageId, userId) => {
  console.log(`Liking message ${messageId} for user ${userId}`);

  // First, fetch the current message
  const { data: message, error: fetchError } = await supabase
    .from('public_chat')
    .select('like')
    .eq('id', messageId)
    .single();

  if (fetchError) {
    console.error('Error fetching message:', fetchError);
    throw fetchError;
  }

  console.log('Fetched message:', message);

  // Prepare the new like array
  let currentLikes = [];
  try {
    currentLikes = JSON.parse(message.like || '[]');
  } catch (e) {
    console.error('Error parsing likes:', e);
  }
  
  if (!Array.isArray(currentLikes)) {
    currentLikes = [];
  }

  const newLikes = currentLikes.includes(userId) 
    ? currentLikes 
    : [...currentLikes, userId];

  console.log('New likes array:', newLikes);

  // Update the message with the new like array
  const { data, error } = await supabase
    .from('public_chat')
    .update({ like: JSON.stringify(newLikes) })
    .eq('id', messageId)
    .select();

  if (error) {
    console.error('Error updating message:', error);
    throw error;
  }

  console.log('Updated message data:', data);

  if (!data || data.length === 0) {
    console.error('No data returned from update operation');
    throw new Error('No data returned from update operation');
  }

  return data;
};

export const unlikeMessage = async (messageId, userId) => {
  console.log(`Unliking message ${messageId} for user ${userId}`);

  // First, fetch the current message
  const { data: message, error: fetchError } = await supabase
    .from('public_chat')
    .select('like')
    .eq('id', messageId)
    .single();

  if (fetchError) {
    console.error('Error fetching message:', fetchError);
    throw fetchError;
  }

  console.log('Fetched message:', message);

  // Prepare the new like array
  let currentLikes = [];
  try {
    currentLikes = JSON.parse(message.like || '[]');
  } catch (e) {
    console.error('Error parsing likes:', e);
  }
  
  if (!Array.isArray(currentLikes)) {
    currentLikes = [];
  }

  const newLikes = currentLikes.filter(id => id !== userId);

  console.log('New likes array:', newLikes);

  // Update the message with the new like array
  const { data, error } = await supabase
    .from('public_chat')
    .update({ like: JSON.stringify(newLikes) })
    .eq('id', messageId)
    .select();

  if (error) {
    console.error('Error updating message:', error);
    throw error;
  }

  console.log('Updated message data:', data);

  if (!data || data.length === 0) {
    console.error('No data returned from update operation');
    throw new Error('No data returned from update operation');
  }

  return data;
};

export const storeLead = async (email, receiveInfo) => {
  try {
    // Basic insert with minimal fields
    const { data, error } = await supabase
      .from('leads')
      .insert({
        email: email,
        receive_info: receiveInfo
      })
      .select()
      .single();

    if (error) {
      console.error('Insert error:', error);
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error storing lead:', error);
    throw new Error('Es gab ein Problem beim Speichern Ihrer E-Mail. Bitte versuchen Sie es später erneut.');
  }
};

export const signIn = async (email) => {
  try {
    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo: `${window.location.origin}/auth/callback`,
      },
    });
    if (error) throw error;
    return { success: true };
  } catch (error) {
    console.error('Error signing in:', error);
    throw error;
  }
};

export const getVideoProgress = async (courseId) => {
  const { data: { user } } = await supabase.auth.getUser();
  
  const { data, error } = await supabase
    .from('mastermind_progress')
    .select('*')
    .eq('course_id', courseId)
    .eq('user_id', user.id)
    .single();

  if (error && error.code !== 'PGRST116') { // PGRST116 is "not found" error
    throw error;
  }
  return data;
};

export const updateVideoProgress = async (courseId, progress, lastPosition) => {
  const { data: { user } } = await supabase.auth.getUser();
  
  const { data, error } = await supabase
    .from('mastermind_progress')
    .upsert(
      {
        course_id: courseId,
        progress: progress,
        last_position: lastPosition,
        user_id: user.id
      },
      {
        onConflict: 'user_id,course_id',
        ignoreDuplicates: false
      }
    )
    .select();

  if (error) {
    console.error('Error in updateVideoProgress:', error);
    throw error;
  }
  return data;
};

export const incrementAudioPlays = async (audioUrl) => {
  try {
    console.log('Attempting to increment plays for:', audioUrl);
    
    // First, try to get the current record
    const { data: currentData, error: fetchError } = await supabase
      .from('audio_plays')
      .select('play_count')
      .eq('audio_url', audioUrl)
      .single();

    if (fetchError && fetchError.code !== 'PGRST116') { // PGRST116 is "not found" error
      throw fetchError;
    }

    if (!currentData) {
      // No record exists, create a new one
      console.log('No existing record found, creating new one');
      const { data: insertData, error: insertError } = await supabase
        .from('audio_plays')
        .insert([{ 
          audio_url: audioUrl, 
          play_count: 1 
        }])
        .select()
        .single();

      if (insertError) {
        console.error('Insert error:', insertError);
        throw insertError;
      }
      return insertData;
    } else {
      // Record exists, update it
      const newCount = (currentData.play_count || 0) + 1;
      const { data: updateData, error: updateError } = await supabase
        .from('audio_plays')
        .update({ play_count: newCount })
        .eq('audio_url', audioUrl)
        .select()
        .single();

      if (updateError) {
        console.error('Update error:', updateError);
        throw updateError;
      }
      return updateData;
    }
  } catch (error) {
    console.error('Error tracking audio play:', error);
    throw error;
  }
};

export const getAudioPlays = async (audioUrl) => {
  try {
    console.log('Fetching play count for:', audioUrl);
    const { data, error } = await supabase
      .from('audio_plays')
      .select('play_count')
      .eq('audio_url', audioUrl)
      .single();

    console.log('Fetch result:', { data, error });

    if (error) throw error;
    return data?.play_count || 0;
  } catch (error) {
    console.error('Error getting audio plays:', error);
    return 0;
  }
};
