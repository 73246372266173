import React, { useState } from 'react';
import { motion } from "framer-motion";
import ReactRotatingText from 'react-rotating-text';
import UpgradeModal from '../components/UpgradeModal';
import './Landingpage.css';
import { FileText, Smartphone, BarChart2, MessageCircle, BookOpen, Copy } from 'lucide-react';

const LandingPage = () => {
  const [copySuccess, setCopySuccess] = useState('');
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [isAgbModalOpen, setIsAgbModalOpen] = useState(false);
  const [isChartEnlarged, setIsChartEnlarged] = useState(false);

  const copyWKN = () => {
    navigator.clipboard.writeText('LS9TUS').then(() => {
      setCopySuccess('WKN kopiert!');
      setTimeout(() => setCopySuccess(''), 2000);
    }, (err) => {
      console.error('Fehler beim Kopieren: ', err);
    });
  };

  const openUpgradeModal = () => {
    setIsUpgradeModalOpen(true);
  };

  const closeUpgradeModal = () => {
    setIsUpgradeModalOpen(false);
  };

  const handleYearlySubscription = () => {
    // Implement yearly subscription logic
    closeUpgradeModal();
  };

  const handleMonthlySubscription = () => {
    // Implement monthly subscription logic
    closeUpgradeModal();
  };

  const AgbModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;
    
    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <button className="modal-close" onClick={onClose}>×</button>
          <h2>Allgemeine Geschäftsbedingungen</h2>
          <div className="modal-body">
            <h3>1. Allgemeines</h3>
            <p>1.1 Die vorliegenden Allgemeinen Geschäftsbedingungen (AGB) enthalten die zwischen uns, der Projekt 100X, Hessenring 4, 63071 Offenbach am Main, Deutschland (im Folgenden "Verkäufer" oder "wir") und einem Verbraucher oder Unternehmer (im Folgenden "Kunden") ausschließlich geltenden Bedingungen für den Kauf der angebotenen Waren und Dienstleistungen, soweit diese nicht durch schriftliche Vereinbarungen zwischen den Parteien abgeändert werden.</p>

            <p>Verbraucher im Sinne dieser AGB ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können. Unternehmer im Sinne dieser AGB ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt.</p>

            <p>1.2. Änderungen dieser Geschäftsbedingungen werden dem Kunden schriftlich, per Telefax oder per E-Mail mitgeteilt. Widerspricht der Kunde dieser Änderung nicht innerhalb von vier Wochen nach Zugang der Mitteilung, gelten die Änderungen durch den Kunden als anerkannt.</p>

            <h3>2. Vertragsschluss</h3>
            <p>2.1. Die Präsentation der angebotenen Waren und Dienstleistungen stellt kein bindendes Angebot des Verkäufers dar. Erst die Bestellung einer Ware oder Dienstleistung durch den Kunden stellt ein bindendes Angebot nach § 145 BGB dar. Im Falle der Annahme des Kaufangebots durch den Verkäufer versendet dieser an den Kunden eine Auftragsbestätigung per E-Mail.</p>

            <p>2.2. Nach der Abgabe des Angebots und erfolgreichem Abschluss der Bestellung, erhält der Kunde eine Kaufbestätigung per E-Mail mit den relevanten Daten. Der Kunde stellt sicher, dass die von ihm eingegebene E-Mail-Adresse korrekt ist.</p>

            <p>2.3. Während des Bestellprozesses hat der Kunde die Möglichkeit die getätigten Eingaben zu korrigieren. Vor Abschluss des Bestellprozesses erhält der Kunde eine Zusammenfassung aller Bestelldetails und erhält die Gelegenheit seine Angaben zu überprüfen.</p>

            <p>2.4. Der Vertragsschluss erfolgt in deutscher Sprache.</p>

            <p>2.5. Eine Kontaktaufnahme des Kunden durch den Verkäufer erfolgt per E-Mail.</p>

            <p>2.6. Bei digitalen Gütern räumt der Verkäufer dem Kunden ein nicht ausschließliches, örtlich und zeitlich unbeschränktes Recht ein, die überlassenen digitalen Inhalte zu privaten sowie zu geschäftlichen Zwecken zu nutzen. Eine Weitergabe der Inhalte an Dritte, sowie eine Vervielfältigung für Dritte ist nicht gestattet, sofern keine Erlaubnis seitens des Verkäufers erteilt wurde.</p>

            <h3>3. Zahlungsbedingungen</h3>
            <p>3.1. Der Kaufpreis wird sofort mit Bestellung fällig. Die Zahlung der Ware erfolgt mittels der zur Verfügung gestellten Zahlungsarten.</p>

            <p>3.2. Es gelten die zum Zeitpunkt der Bestellung angegebenen Preise. Die in den Preisinformationen genannten Preise enthalten die gesetzliche Umsatzsteuer.</p>

            <p>3.3. Gegen Forderungen des Verkäufers kann der Kunde nur mit unbestrittenen oder rechtskräftig festgestellten oder entscheidungsreifen Gegenansprüchen aufrechnen.</p>

            <h3>4. Versandbedingungen</h3>
            <p>4.1. Der Versand der bestellten Ware erfolgt gemäß den getroffenen Vereinbarungen. Anfallende Versandkosten sind jeweils bei der Produktbeschreibung aufgeführt und werden gesondert auf der Rechnung ausgewiesen.</p>

            <p>4.2. Digitale Güter werden dem Kunden in elektronischer Form entweder als Download oder per E-Mail zur Verfügung gestellt.</p>

            <h3>5. Widerrufsrecht und vorzeitiger Erlöschen des Widerrufsrechts</h3>
            <p>5.1. Grundsätzlich steht dem Verbraucher bei Fernabsatzverträgen ein gesetzliches Widerrufsrecht zu. Die vollständige Widerrufsbelehrung wird dem Kunden vor Vertragsschluss zur Verfügung gestellt.</p>

            <p>5.2. Der Kunde wird ausdrücklich darauf hingewiesen, dass er durch die nachfolgende Erklärung sein Widerrufsrecht bei sofortiger Vertragserfüllung verliert:</p>

            <p><em>"Ich stimme ausdrücklich zu, dass der Anbieter vor Ablauf der Widerrufsfrist mit der Ausführung des Vertrages beginnt. Mir ist bekannt, dass ich durch diese Zustimmung mit Beginn der Ausführung des Vertrages mein Widerrufsrecht verliere (§ 356 Abs. 5 BGB)."</em></p>

            <p>5.3. Mit der Bestätigung dieser Erklärung und dem ersten Login in den Mitgliederbereich:</p>
            <ul>
              <li>verlangt der Kunde ausdrücklich, dass der Anbieter sofort mit der Ausführung des Vertrages beginnt,</li>
              <li>bestätigt der Kunde seine Kenntnis darüber, dass er durch seine Zustimmung mit Beginn der Ausführung des Vertrages sein Widerrufsrecht verliert,</li>
              <li>verzichtet der Kunde ausdrücklich auf die Ausübung seines Widerrufsrechts.</li>
            </ul>

            <p>5.4. Der Anbieter weist darauf hin, dass aufgrund der Natur der digitalen Inhalte und des sofortigen Zugangs zu sämtlichen Kursinhalten ein Widerrufsrecht nach Beginn der Vertragsausführung ausgeschlossen ist. Dies gilt insbesondere, da die digitalen Inhalte nicht auf einem körperlichen Datenträger geliefert werden und der Verbraucher durch die vorzeitige Vertragserfüllung auf sein Widerrufsrecht verzichtet hat.</p>

            <p>5.5. Für Unternehmer besteht generell kein Widerrufsrecht. Gleiches gilt für Verbraucher nach Ablauf der Widerrufsfrist oder bei Verzicht auf das Widerrufsrecht gemäß den vorstehenden Regelungen.</p>

            <h3>6. Offline-Events</h3>
            <p>Erwirbt der Kunde ein Ticket zu einem Offline-Event, gelten folgende Bestimmungen:</p>

            <p>Bei zwingenden organisatorischen oder wirtschaftlichen Gründen, die nicht von dem Veranstalter zu vertreten sind, behält sich der Veranstalter das Recht vor, eine Veranstaltung abzusagen. In diesem Fall wird der Veranstalter den Kunden unverzüglich informieren sowie die Tickets auf eine Folgeveranstaltung umbuchen. Ausfallkosten, gegen die sich der Teilnehmer hätte versichern können (Ticketversicherung, Reiserücktrittskostenversicherung etc.), werden auf keinen Fall erstattet.</p>

            <p>Im Falle höherer Gewalt oder behördlicher Absage der Veranstaltung ist eine Haftung durch den Veranstalter ausgeschlossen.</p>

            <p>Eine Haftung für Stornierungs- oder Umbuchungsgebühren für vom Kunden gebuchte Transportmittel oder Übernachtungskosten ist ausgeschlossen.</p>

            <h3>7. Geld-zurück-Garantie für die App</h3>
            <p>7.1. Der Verkäufer bietet eine Geld-zurück-Garantie für die gesamte App unter folgenden kumulativen Bedingungen an:</p>

            <p>7.2. Voraussetzungen für die Geld-zurück-Garantie:</p>
            <ul>
              <li>Der Kunde muss die in der App gelehrte Covered-Call-Strategie nachweislich und dokumentiert in seinem Depot umgesetzt haben</li>
              <li>Das für die Strategie eingesetzte Mindestkapital muss durchgehend mindestens 10.000 EUR betragen haben</li>
              <li>Die Strategie muss über den gesamten Zeitraum eines Jahres ab Kursbeginn angewendet worden sein</li>
              <li>Sämtliche Trades müssen dokumentiert und auf Anfrage nachgewiesen werden können</li>
              <li>Die Umsetzung muss exakt nach den in der App vermittelten Vorgaben erfolgt sein</li>
            </ul>

            <p>7.3. Wenn trotz Erfüllung aller oben genannten Voraussetzungen die erzielte Jahresrendite nachweislich geringer ausfällt als die gezahlte Jahresgebühr für die App, erstattet der Verkäufer dem Kunden die Jahresgebühr.</p>

            <p>7.4. Ausschlussgründe:</p>
            <ul>
              <li>Abweichungen von der gelehrten Strategie</li>
              <li>Nicht durchgehende Anwendung der Strategie</li>
              <li>Unvollständige oder fehlende Dokumentation der Trades</li>
              <li>Unterschreitung des Mindestkapitals während des Betrachtungszeitraums</li>
            </ul>

            <p>7.5. Der Anspruch auf Erstattung muss innerhalb von 30 Tagen nach Ablauf des Jahres schriftlich unter Beifügung sämtlicher relevanter Nachweise geltend gemacht werden. Die Entscheidung über die Erstattung erfolgt nach Prüfung der eingereichten Unterlagen innerhalb von 30 Werktagen.</p>

            <h3>8. Gewährleistung</h3>
            <p>Soweit die gelieferte Ware mangelhaft ist, ist der Kunde im Rahmen der gesetzlichen Bestimmungen berechtigt, Nacherfüllung zu verlangen, von dem Vertrag zurückzutreten bzw. den Vertrag zu beenden, den Kaufpreis zu mindern, Schadensersatz oder den Ersatz vergeblicher Aufwendungen zu verlangen. Vor dem Kauf mitgeteilte Mängel stellen keinen Gewährleistungsfall dar. Die Verjährungsfrist von Gewährleistungsansprüchen für die gelieferte Ware beträgt zwei Jahre ab Erhalt der Ware.</p>

            <h3>9. Haftungsbeschränkung</h3>
            <p>9.1. Der Verkäufer haftet für Vorsatz und grobe Fahrlässigkeit. Ferner haftet der Verkäufer für die fahrlässige Verletzung von Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht, deren Verletzung die Erreichung des Vertragszwecks gefährdet und auf deren Einhaltung ein Kunde regelmäßig vertraut. Im letztgenannten Fall haftet der Verkäufer jedoch nur für den vorhersehbaren, vertragstypischen Schaden. Der Verkäufer haftet nicht für die leicht fahrlässige Verletzung anderer als der in den vorstehenden Sätzen genannten Pflichten.</p>

            <p>9.2. Die vorstehenden Haftungsausschlüsse gelten nicht bei Verletzung von Leben, Körper und Gesundheit. Die Haftung nach Produkthaftungsgesetz bleibt unberührt.</p>

            <p>9.3. Die Datenkommunikation über das Internet kann nach dem derzeitigen Stand der Technik nicht fehlerfrei und/oder jederzeit verfügbar gewährleistet werden. Der Verkäufer haftet insoweit weder für die ständige und ununterbrochene Verfügbarkeit des Online-Handelssystems und der Onlineangebote.</p>

            <p>9.4. Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter http://ec.europa.eu/consumers/odr finden. Wir nehmen nicht an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil.</p>

            <h3>10. Schlussbestimmungen</h3>
            <p>10.1. Änderungen oder Ergänzungen dieser Geschäftsbedingungen bedürfen der Schriftform. Dies gilt auch für die Aufhebung dieses Schriftformerfordernisses.</p>

            <p>10.2. Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts. Zwingende Bestimmungen des Staates, in dem ein Verbraucher seinen gewöhnlichen Aufenthalt hat, bleiben unberührt.</p>

            <p>10.3. Soweit ein Verbraucher bei Abschluss des Vertrages seinen Wohnsitz oder gewöhnlichen Aufenthalt in Deutschland hatte und entweder zum Zeitpunkt der Klageerhebung verlegt hat oder seinen Aufenthaltsort zu diesem Zeitpunkt unbekannt ist, ist Gerichtsstand für alle Streitigkeiten der Geschäftssitz des Verkäufers.</p>

            <p>Wenn ein Verbraucher seinen Wohnsitz oder gewöhnlichen Aufenthalt nicht in einem Mitgliedsstaat der europäischen Union hat, sind für alle Streitigkeiten die Gerichte am Geschäftssitz des Verkäufers ausschließlich zuständig.</p>

            <p>Handelt der Kunde als Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen mit Sitz im Hoheitsgebiet der Bundesrepublik Deutschland, ist ausschließlicher Gerichtsstand für alle Streitigkeiten aus diesem Vertrag der Geschäftssitz des Verkäufers.</p>

            <p>10.4. Sollten einzelne Bestimmungen dieses Vertrages unwirksam sein oder den gesetzlichen Regelungen widersprechen, so wird hierdurch der Vertrag im Übrigen nicht berührt. Die unwirksame Bestimmung wird von den Vertragsparteien einvernehmlich durch eine rechtswirksame Bestimmung ersetzt, welche dem wirtschaftlichen Sinn und Zweck der unwirksamen Bestimmung am nächsten kommt. Die vorstehende Regelung gilt entsprechend bei Regelungslücken.</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="landing-container">
      <header className="landing-header">
      </header>

      <main className="landing-main">
        <div className="aurora-blob aurora-blob-1"></div>
        <div className="aurora-blob aurora-blob-2"></div>
        <div className="aurora-blob aurora-blob-3"></div>
        <section id="hero" className="hero-section">
          <div className="container-wrapper">
            <div className="hero-container">
              <motion.div
                initial={{ opacity: 0.0, y: 40 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                  delay: 0.3,
                  duration: 0.8,
                  ease: "easeInOut",
                }}
                className="hero-content">
                <h1>
                  <div className="rotating-text-wrapper">
                    <ReactRotatingText 
                      items={[
                        'Börsenbrief',
                        'Anleger-Community',
                        'Analysen',
                        'Trading-Ideen'
                      ]} 
                      pause={2000}
                      typingInterval={50}
                      deletingInterval={30}
                    />
                  </div>
                  {' der nächsten Generation'}
                </h1>
                <p>KI-gestützte Echtzeit-Marktanalysen, Live-Chat, kurzfristige Trades, langfristige Investments und lehrreiche Mastermind-Kursinhalte.</p>
                <button onClick={openUpgradeModal} className="cta-button">MITGLIED WERDEN</button>
              </motion.div>
              <motion.div
                initial={{ opacity: 0.0, y: 40 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                  delay: 0.5,
                  duration: 0.8,
                  ease: "easeInOut",
                }}
                className="hero-mockup">
                <div className="phone-mockup">
                  <img src="/images/liq2-left.png" alt="App Screenshot" className="app-screenshot" />
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        <section id="features" className="features-section">
          <h2>100X Alpha Hauptfunktionen</h2>
          <div className="feature-grid">
            <div className="feature-card">
              <div className="feature-icon">
                <BarChart2 size={48} className="text-landing-highlight" />
              </div>
              <h3>100X Aktien-Depot</h3>
              <p>Dauerhafter Zugriff auf das 100X Aktien-Depot mit Performance-Daten</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">
                <Smartphone size={48} className="text-landing-highlight" />
              </div>
              <h3>Trade-Alarm per SMS</h3>
              <p>Sofortige SMS-Benachrichtigungen zu wichtigen Marktchancen, die getradet werden können.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">
                <BarChart2 size={48} className="text-landing-highlight" />
              </div>
              <h3>10+ Echtzeit-Indikatoren</h3>
              <p>Eine wachsende Anzahl von einzigartigen Marktindikatoren.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">
                <MessageCircle size={48} className="text-landing-highlight" />
              </div>
              <h3>Live-Chat Community</h3>
              <p>Echtzeit-Austausch mit Maximilian von Projekt: 100X und anderen zur Strategieverfeinerung.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">
                <FileText size={48} className="text-landing-highlight" />
              </div>
              <h3>Insider-Analyse</h3>
              <p>Verfolge die Aktivitäten von Unternehmensinsidern mit unserer übersichtlichen Datenaufbereitung.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">
                <BookOpen size={48} className="text-landing-highlight" />
              </div>
              <h3>Exklusive Videos & Podcasts</h3>
              <p>Erhalte Zugang zu privaten Video- und Audio-Inhalten, die nicht öffentlich verfügbar sind.</p>
            </div>
          </div>
        </section>

        <section id="performance" className="performance-section">
          <h2>Portfolio Performance</h2>
          <div className="performance-overview">
            <div className="performance-stats">
              <div className="performance-card">
                <div className="performance-period">2023</div>
                <div className="performance-value">+95,37%</div>
                <div className="performance-details">
                  <div>Gesamtjahr</div>
                </div>
              </div>

              <div className="performance-card">
                <div className="performance-period">2024</div>
                <div className="performance-value">+66,96%</div>
                <div className="performance-details">
                  <div>Gesamtjahr</div>
                </div>
              </div>

              <div className="performance-card">
                <div className="performance-period">2025</div>
                <div className="performance-value">+6,36%</div>
                <div className="performance-details">
                  <div>Seit Jahresbeginn</div>
                </div>
              </div>
            </div>

            <div className="performance-chart-container">
              <div className="chart-placeholder">
                <img 
                  src="/images/yearly performance 2023.png" 
                  alt="Performance Chart"
                  className={`performance-chart ${isChartEnlarged ? 'enlarged' : ''}`}
                  onClick={() => setIsChartEnlarged(!isChartEnlarged)}
                  onError={(e) => {
                    e.target.style.display = 'none';
                    e.target.nextElementSibling.style.display = 'block';
                  }}
                />
                <div className="chart-fallback">
                  <div className="chart-placeholder-icon">📈</div>
                  <div>Performance Verlauf 2023-2024</div>
                  <button className="cta-button" onClick={openUpgradeModal}>
                    Jetzt Zugang freischalten
                  </button>
                </div>
              </div>
            </div>

            <div className="performance-disclaimer">
              Hinweis: Vergangene Performance ist keine Garantie für zukünftige Ergebnisse. 
              Alle Angaben wurden sorgfältig recherchiert.
            </div>
          </div>
        </section>

        <section id="stock-portfolio" className="content-section">
          <div className="section-content">
            <h2>100X Aktien-Depot</h2>
            <p>Erhalte exklusiven Zugriff auf Maximilians privates 100X Aktien-Depot. Basierend auf tiefgehenden fundamentalen Analysen, zeigt dir dieses Portfolio die Positionsgrößen und aktuelle Performance-Daten.</p>
            <ul className="feature-list">
              <li>Detaillierte Einblicke in Maximilians Investitionsstrategie</li>
              <li>Aktuelle Positionsgrößen und Performance-Daten</li>
              <li>Fundierte Analysen zu jeder Aktienposition (im Aufbau)</li>
              <li>Regelmäßige Updates zu Portfolio-Änderungen</li>
            </ul>
          </div>
          <div className="section-image stock-portfolio-mockup">
            <div className="stock-list">
              <div className="stock-item">
                <span className="stock-name">Tesla (TSLA)</span>
                <span className="stock-position">15% des Portfolios</span>
                <span className="stock-performance positive">+643%</span>
              </div>
              <div className="stock-item">
                <span className="stock-name">Palantir (PLTR)</span>
                <span className="stock-position">12% des Portfolios</span>
                <span className="stock-performance positive">+415%</span>
              </div>
              <div className="stock-item">
                <span className="stock-name">Rocket Lab (RKLB)</span>
                <span className="stock-position">10% des Portfolios</span>
                <span className="stock-performance positive">+287%</span>
              </div>
            </div>
          </div>
        </section>

        <section id="trade-alerts" className="content-section reverse">
          <div className="section-content">
            <h2>Trade-Alarm per SMS</h2>
            <p>Verpasse nie wieder eine wichtige Handelsmöglichkeit. Unser SMS-Alarmsystem hält dich über kritische Marktbewegungen auf dem Laufenden, egal wo du dich befindest.</p>
            <ul className="feature-list">
              <li>Sofortige Benachrichtigungen</li>
              <li>Anpassbare Alarmeinstellungen</li>
              <li>Präzise Marktinformationen</li>
              <li>Trading-Setups inklusive</li>
            </ul>
          </div>
          <div className="section-image sms-mockup">
            <div className="sms-bubble">
              <div className="sms-header">
                <span className="sms-sender">100X Alpha</span>
                <span className="sms-time">Jetzt</span>
              </div>
              <div className="sms-content">
                <p><strong>KAUFSIGNAL: TESLA (TSLA)</strong></p>
                <p>Aktueller Kurs: $263</p>
                <p>Technische Analyse zeigt Bodenbildung</p>
                <p>Mehr Details im Chat.</p>
              </div>
            </div>
            <div className="historical-trades">
              <h3>Trading-Setups</h3>
              
              {/* Current Trades Section */}
              <div className="trades-section">
                <h4>Aktuelle Positionen</h4>
                <div className="trades-list blurred">
                  {/* Replace actual data with dummy data */}
                  <div className="trade-item">
                    <span>████████████</span>
                    <span>████</span>
                    <span>████</span>
                  </div>
                  <div className="trade-item">
                    <span>████████████</span>
                    <span>████</span>
                    <span>████</span>
                  </div>
                </div>
                <div className="unlock-overlay">
                  <button onClick={openUpgradeModal} className="unlock-button">
                    Jetzt freischalten
                  </button>
                </div>
              </div>

              {/* Historical Trades Section */}
              <div className="trades-section">
                <h4>Abgeschlossene Trades</h4>
                <div className="trades-list">
                  <div className="trade-item">
                    <span className="trade-name">Bitcoin (2x Hebel)</span>
                    <span className="trade-status">Geschlossen</span>
                    <span className="trade-performance positive">+24,9%</span>
                  </div>
                  <div className="trade-item">
                    <span className="trade-name">Bitcoin (2x Hebel)</span>
                    <span className="trade-status">Geschlossen</span>
                    <span className="trade-performance positive">+5,69%</span>
                  </div>
                  <div className="trade-item">
                    <span className="trade-name">TLT Call-Option</span>
                    <span className="trade-status">Geschlossen</span>
                    <span className="trade-performance negative">-9,1%</span>
                  </div>
                  <div className="trade-item">
                    <span className="trade-name">AMD Call-Option</span>
                    <span className="trade-status">Geschlossen</span>
                    <span className="trade-performance positive">+101%</span>
                  </div>
                  <div className="trade-item">
                    <span className="trade-name">CrowdStrike</span>
                    <span className="trade-status">Geschlossen</span>
                    <span className="trade-performance positive">+9%</span>
                  </div>
                  <div className="trade-item">
                    <span className="trade-name">Nvidia Short</span>
                    <span className="trade-status">Geschlossen</span>
                    <span className="trade-performance positive">+31%</span>
                  </div>
                  <div className="trade-item">
                    <span className="trade-name">TLT Call-Option</span>
                    <span className="trade-status">Geschlossen</span>
                    <span className="trade-performance positive">+76%</span>
                  </div>
                  <div className="trade-item">
                    <span className="trade-name">Nasdaq (3x Hebel)</span>
                    <span className="trade-status">Geschlossen</span>
                    <span className="trade-performance positive">+5,9%</span>
                  </div>
                  <div className="trade-item highlight">
                    <span className="trade-name">VIX-Futures</span>
                    <span className="trade-status">Geschlossen</span>
                    <span className="trade-performance positive">+640%</span>
                  </div>
                  <div className="trade-item">
                    <span className="trade-name">Kupfer</span>
                    <span className="trade-status">Geschlossen</span>
                    <span className="trade-performance positive">+51%</span>
                  </div>
                  <div className="trade-item">
                    <span className="trade-name">Rivian</span>
                    <span className="trade-status">Geschlossen</span>
                    <span className="trade-performance negative">-2,9%</span>
                  </div>
                </div>
              </div>
              
              {/* Existing disclaimer */}
            </div>
          </div>
        </section>

        <section className="disclaimer-section">
          <div className="disclaimer-container">
            <h3>Risikohinweise</h3>
            <div className="disclaimer-content">
              <div className="disclaimer-icon">⚠️</div>
              <p>
                Die hier dargestellten Handelsergebnisse basieren auf tatsächlich durchgeführten Trades. 
                Dennoch können vergangene Erfolge keine Garantie für zukünftige Ergebnisse sein. 
                Der Handel mit Wertpapieren, insbesondere mit Hebelprodukten, birgt erhebliche Risiken 
                und kann zum Totalverlust des eingesetzten Kapitals führen. Bitte handeln Sie 
                verantwortungsvoll und nur mit Kapital, dessen Verlust Sie sich leisten können.
              </p>
            </div>
          </div>
        </section>

        <section id="market-indicators" className="content-section">
          <div className="section-content">
            <h2>10+ Echtzeit-Marktindikatoren</h2>
            <p>Nutze die Kraft von über 13 Echtzeit-Marktindikatoren, um fundierte Handelsentscheidungen zu treffen. Unsere präzisen Indikatoren geben dir einen Wettbewerbsvorteil auf dem Markt.</p>
            <ul className="feature-list">
              <li>Automatische Analyse von Quartalsberichten</li>
              <li>Auswertung von Konsumdaten</li>
              <li>Stimmungsanalyse der Anleger</li>
              <li>Exklusive Daten zur Globalen Liquidität</li>
            </ul>
          </div>
          <div className="section-image indicator-mockup">
            <div className="pe-ratios-grid">
              <div className="pe-ratio-item">
                <div className="pe-ratio-header">
                  <span className="pe-ratio-name">Marktbewertung</span>
                  <span className="pe-ratio-value">65.2</span>
                </div>
                <div className="pe-ratio-scale-container">
                  <div className="pe-ratio-scale">
                    <div className="pe-ratio-indicator" style={{left: '65.2%'}}></div>
                  </div>
                  <div className="pe-ratio-labels">
                    <span>0</span>
                    <span>100</span>
                  </div>
                </div>
              </div>
              <div className="pe-ratio-item">
                <div className="pe-ratio-header">
                  <span className="pe-ratio-name">Angst und Gier</span>
                  <span className="pe-ratio-value">+0.35</span>
                </div>
                <div className="pe-ratio-scale-container">
                  <div className="pe-ratio-scale">
                    <div className="pe-ratio-indicator" style={{left: '60%'}}></div>
                  </div>
                  <div className="pe-ratio-labels">
                    <span>-1</span>
                    <span>+1</span>
                  </div>
                </div>
              </div>
              <div className="pe-ratio-item">
                <div className="pe-ratio-header">
                  <span className="pe-ratio-name">Rezessionswahrscheinlichkeit</span>
                  <span className="pe-ratio-value">18.7%</span>
                </div>
                <div className="pe-ratio-scale-container">
                  <div className="pe-ratio-scale">
                    <div className="pe-ratio-indicator" style={{left: '18.7%'}}></div>
                  </div>
                  <div className="pe-ratio-labels">
                    <span>0%</span>
                    <span>100%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="live-chat" className="content-section reverse">
          <div className="section-content">
            <h2>Live-Chat mit Max und anderen Mitgliedern</h2>
            <p>Tritt unserer lebendigen Community bei und chatte in Echtzeit mit anderen erfahrenen Mitgliedern. Teile Ideen, diskutiere Strategien und lerne von den Besten.</p>
            <ul className="feature-list">
              <li>Expertenmeinungen in Echtzeit</li>
              <li>Community-getriebene Diskussionen</li>
              <li>Networking-Möglichkeiten</li>
              <li>Hilfreiche Unterstützung</li>
            </ul>
          </div>
          <div className="section-image live-chat-mockup">
            <div className="chat-bubbles">
              <div className="chat-bubble bubble-1">
                <span className="user">Max:</span> Willkommen im Live-Chat! Wie kann ich euch heute helfen?
              </div>
              <div className="chat-bubble bubble-2">
                <span className="user">Anna:</span> Hallo Max! Ich habe eine Frage zu den neuesten Markttrends.
              </div>
              <div className="chat-bubble bubble-3">
                <span className="user">Thomas:</span> Der Bitcoin-Chart hat gerade den Widerstand geknackt! Jetzt kaufen?
              </div>
            </div>
          </div>
        </section>

        <section id="mastermind" className="content-section">
          <div className="section-content">
            <h2>Mastermind Lerninhalte</h2>
            <p>Erweitere dein Wissen und schärfe deine Fähigkeiten mit unseren exklusiven Mastermind-Lerninhalten. Von detaillierten Aktienanalysen bis hin zu fortgeschrittenen Optionsstrategien - wir haben alles, was du für deinen Erfolg benötigst.</p>
            <ul className="feature-list">
              <li>Tiefgehende Aktienanalysen</li>
              <li>Fortgeschrittene Optionsstrategien</li>
              <li>Risikomanagement-Techniken</li>
              <li>Regelmäßige Webinare und Workshops</li>
            </ul>
          </div>
          <div className="section-image content-player-mockup">
            <div className="content-player">
              <div className="player-header">
                <h3>Aktuelle Lerninhalte</h3>
              </div>
              <div className="player-content">
                <div className="video-thumbnail">
                  <div className="play-button"></div>
                </div>
                <h4>Fortgeschrittene Optionsstrategien</h4>
                <p>Dauer: 45 Minuten</p>
              </div>
              <div className="player-playlist">
                <div className="playlist-item active">
                  <span className="playlist-item-number">1</span>
                  <span className="playlist-item-title">Einführung in Optionen</span>
                </div>
                <div className="playlist-item">
                  <span className="playlist-item-number">2</span>
                  <span className="playlist-item-title">Covered Calls Strategie</span>
                </div>
                <div className="playlist-item">
                  <span className="playlist-item-number">3</span>
                  <span className="playlist-item-title">Iron Condor Strategie</span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="insider-analysis" className="content-section">
          <div className="section-content">
            <h2>Insider-Analyse</h2>
            <p>Bleibe über die Aktivitäten von Unternehmensinsidern informiert. Unsere aufbereiteten Daten zeigen dir klar und deutlich, ob Insider gerade kaufen oder verkaufen.</p>
            <ul className="feature-list">
              <li>Tägliche Aktualisierung der Insider-Aktivitäten</li>
              <li>Übersichtliche Visualisierung der Transaktionen</li>
              <li>Historische Vergleichsdaten</li>
              <li>Detaillierte Analysen zu bedeutenden Bewegungen</li>
            </ul>
          </div>
          <div className="section-image indicator-mockup">
            <div className="pe-ratios-grid">
              <div className="pe-ratio-item">
                <div className="pe-ratio-header">
                  <span className="pe-ratio-name">Tesla (TSLA) Insider-Aktivität</span>
                  <span className="pe-ratio-value">Verkauf durch CEO</span>
                </div>
                <div className="insider-transaction">
                  <p><strong>Elon Musk</strong> hat <strong>500.000 Tesla Aktien</strong> verkauft</p>
                  <p>Gesamtwert: <strong>$126.715.000</strong></p>
                  <p>Datum: {new Date().toLocaleDateString('de-DE')}</p>
                </div>
                <div className="pe-ratio-scale-container">
                  <div className="pe-ratio-scale">
                    <div className="pe-ratio-indicator" style={{left: '75%'}}></div>
                  </div>
                  <div className="pe-ratio-labels">
                    <span>Kauf</span>
                    <span>Verkauf</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="mini-podcasts" className="content-section reverse">
          <div className="section-content">
            <h2>Exklusive Videos & Podcasts</h2>
            <p>Erhalte Zugang zu privaten Video- und Audio-Inhalten, die nur für Mitglieder verfügbar sind. Profitiere von detaillierten Marktanalysen und Trading-Entscheidungen in einem exklusiven Format.</p>
            <ul className="feature-list">
              <li>Private Video-Analysen zu aktuellen Trades</li>
              <li>Exklusive Audio-Updates zu Marktentwicklungen</li>
              <li>Detaillierte Strategieerklärungen</li>
              <li>Nicht öffentlich zugängliche Inhalte</li>
            </ul>
          </div>
          <div className="section-image content-player-mockup">
            <div className="content-player">
              <div className="player-header">
                <h3>Aktuelle Exklusivinhalte</h3>
              </div>
              <div className="player-content">
                <div className="video-thumbnail">
                  <div className="play-button"></div>
                </div>
                <h4>Marktupdate: Tech-Sektor Analyse</h4>
                <p>Dauer: 25 Minuten</p>
              </div>
              <div className="player-playlist">
                <div className="playlist-item active">
                  <span className="playlist-item-number">1</span>
                  <span className="playlist-item-title">Tech-Sektor Analyse (Video)</span>
                </div>
                <div className="playlist-item">
                  <span className="playlist-item-number">2</span>
                  <span className="playlist-item-title">Trading-Strategie Update (Audio)</span>
                </div>
                <div className="playlist-item">
                  <span className="playlist-item-number">3</span>
                  <span className="playlist-item-title">Depot-Review März 2024 (Video)</span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="broker-recommendation" className="content-section">
          <div className="section-content">
            <h2>Bester Allround-Broker</h2>
            <div className="broker-content xtb large">
              <img 
                src="/images/de-kv-hp-desktop-zi-wymw-2024-1140x320.png" 
                alt="XTB Broker" 
                className="broker-banner large"
              />
              <div className="broker-features-grid">
                <div className="feature-category">
                  <h3>Aktien und ETFs</h3>
                  <p>Über 3.000 Wertpapiere aus Europa und den USA</p>
                </div>
                <div className="feature-category">
                  <h3>Forex, Rohstoffe, Indizes</h3>
                  <p>Über 2.300 gehebelte CFD Instrumente</p>
                </div>
                <div className="feature-category">
                  <h3>Kryptowährungen</h3>
                  <p>Über 40 CFD auf BITCOIN BTC, ETH, etc.</p>
                </div>
                <div className="feature-category">
                  <h3>Sparpläne</h3>
                  <p>Entdecken Sie die smarte Art des Investierens auf Basis von ETFs - bester Sparplan Broker laut Handelsblatt</p>
                </div>
                <div className="feature-category">
                  <h3>Guthabenzinsen</h3>
                  <p>Verdienen Sie Zinsen, während Sie auf die perfekte Investmentmöglichkeit warten - 4,2% für die ersten 3 Monate</p>
                </div>
              </div>
              <a href="https://geolink.xtb.com/D0Nk2" 
                 target="_blank" 
                 rel="noopener noreferrer" 
                 className="broker-cta">
                Jetzt bei XTB registrieren
              </a>
            </div>
            <div className="broker-disclaimer">
              CFDs sind komplexe Instrumente und gehen wegen der Hebelwirkung mit dem hohen Risiko einher, schnell Geld zu verlieren. 74% der Kleinanlegerkonten verlieren Geld beim CFD-Handel mit diesem Anbieter. Sie sollten überlegen, ob Sie verstehen, wie CFDs funktionieren, und ob Sie es sich leisten können, das hohe Risiko einzugehen, Ihr Geld zu verlieren.
            </div>
          </div>
        </section>

        <section id="zertifikat" className="landing-zertifikat">
          <h2>100X Zertifikat: Deine Chance auf Tech- und Wachstumsaktien</h2>
          <div className="zertifikat-content">
            <div className="zertifikat-info">
              <p>Entdecke das 100X Zertifikat, deine Eintrittskarte in die Welt der Tech- und Wachstumsaktien. Dieses innovative Finanzprodukt bietet dir die Möglichkeit, in ein sorgfältig ausgewähltes Portfolio zukunftsweisender Unternehmen zu investieren.</p>
              <div className="wkn-container">
                <span>WKN: LS9TUS</span>
                <button onClick={copyWKN} className="copy-button">
                  <Copy size={16} />
                  Kopieren
                </button>
                {copySuccess && <span className="copy-success">{copySuccess}</span>}
              </div>
              <ul>
                <li>Fokus auf innovative Technologiewerte und Growth-Aktien</li>
                <li>Disruptive und digitale Geschäftsmodelle</li>
                <li>Einbeziehung des Kryptowährungs-Marktes</li>
                <li>Nutzung von ETFs zur breiteren Sektorabdeckung</li>
                <li>Einsatz von Hebelprodukten für kurzfristige Spekulationen</li>
              </ul>
              <p>Das 100X wikifolio Projekt konzentriert sich vorrangig auf innovative Technologiewerte (Growth-Aktien), disruptive und digitale Geschäftsmodelle sowie den Kryptowährungs-Markt. Neben Aktien werden auch ETFs zur breiteren Abdeckung bestimmter Sektoren sowie Hebelprodukte zur Spekulation auf kurzfristige Volatilität genutzt.</p>
              <p>Der Anlagehorizont ist überwiegend langfristig, wobei einzelne Positionen auch kurzfristig gehalten werden können. Die Entscheidungsfindung basiert auf technischer Analyse, wie Trendbestätigungsformationen und gleitenden Durchschnitten, sowie fundamentaler Analyse in Form qualitativer und quantitativer Unternehmensanalyse.</p>
            </div>
            <div className="zertifikat-chart">
              <img src="https://www.ariva.de/chart/images/chart.png?z=a171023929~A1~B100~b16~H0~L1~R100~w940x420~W0" alt="100X Zertifikat Chart" />
              <p>Kursverlauf des 100X Zertifikats vom {new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).toLocaleDateString('de-DE')} bis {new Date().toLocaleDateString('de-DE')}</p>
            </div>
          </div>
        </section>

      </main>

      <footer className="landing-footer">
        <p>&copy; 2024 100X Alpha. Alle Rechte vorbehalten.</p>
        <div className="footer-links">
          <a href="https://projekt100x.de/impressum/">Impressum</a>
          <a href="https://projekt100x.de/datenschutz/">Datenschutz</a>
          <a 
            href="#" 
            onClick={(e) => {
              e.preventDefault();
              setIsAgbModalOpen(true);
            }}
          >
            Allgemeine Geschäftsbedingungen
          </a>
        </div>
      </footer>

      <UpgradeModal
        isOpen={isUpgradeModalOpen}
        onClose={closeUpgradeModal}
        onYearlySubscription={handleYearlySubscription}
        onMonthlySubscription={handleMonthlySubscription}
      />

      <AgbModal 
        isOpen={isAgbModalOpen}
        onClose={() => setIsAgbModalOpen(false)}
      />
    </div>
  );
};

export default LandingPage;
