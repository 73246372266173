import React from 'react';

const ImageModal = ({ imageUrl, onClose }) => {
  return (
    <div className="image-modal-overlay" onClick={onClose}>
      <div className="image-modal-content" onClick={(e) => e.stopPropagation()}>
        <img src={imageUrl} alt="Enlarged" />
        <button className="close-button" onClick={onClose}>×</button>
      </div>
    </div>
  );
};

export default ImageModal;