import React, { useState, useRef, useEffect } from 'react';
import { BsFillPlayFill, BsPauseFill } from 'react-icons/bs';
import { formatDuration } from '../utils/timeUtils';
import { incrementAudioPlays, getAudioPlays } from '../supabaseClient';

const AudioPlayerComponent = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [playCount, setPlayCount] = useState(0);
  const hasPlayedRef = useRef(false);
  const audioRef = useRef(null);

  const audioUrl = "https://100xplatform.fra1.cdn.digitaloceanspaces.com/audio_commentary/auphonic-web-recording_11-09-2024--16-09.mp3";
  
  useEffect(() => {
    const audio = audioRef.current;
    audio.addEventListener('loadedmetadata', () => setDuration(audio.duration));
    audio.addEventListener('timeupdate', () => setCurrentTime(audio.currentTime));
    
    return () => {
      audio.removeEventListener('loadedmetadata', () => setDuration(audio.duration));
      audio.removeEventListener('timeupdate', () => setCurrentTime(audio.currentTime));
    };
  }, []);

  useEffect(() => {
    // Fetch initial play count
    const fetchPlayCount = async () => {
      const count = await getAudioPlays(audioUrl);
      setPlayCount(count);
    };
    fetchPlayCount();
  }, [audioUrl]);

  const togglePlayPause = async () => {
    if (audioRef.current.paused) {
      try {
        await audioRef.current.play();
        setIsPlaying(true);
        
        // Only increment play count once per session
        if (!hasPlayedRef.current) {
          hasPlayedRef.current = true;
          console.log('Incrementing play count...');
          const result = await incrementAudioPlays(audioUrl);
          console.log('Increment result:', result);
          if (result && typeof result.play_count === 'number') {
            setPlayCount(result.play_count);
          }
        }
      } catch (error) {
        console.error('Failed to handle play action:', error);
      }
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleTimeUpdate = (e) => {
    const time = e.target.value;
    audioRef.current.currentTime = time;
    setCurrentTime(time);
  };

  return (
    <div className="audio-player-card" role="region" aria-label="Audio Player">
      <div className="audio-player-header">
        <div>
          <h3>Trocknet der Swimming-Pool bald aus?</h3>
          <span className="play-count">
            {playCount}{playCount === 1 ? 'x angehört' : 'x angehört'}
          </span>
        </div>
        <span className="audio-duration" aria-label="Audio duration">
          {formatDuration(currentTime)} / {formatDuration(duration)}
        </span>
      </div>
      
      <div className="audio-player-controls">
        <button 
          className="play-pause-btn"
          onClick={togglePlayPause}
          aria-label={isPlaying ? 'Pause' : 'Play'}
        >
          {isPlaying ? <BsPauseFill size={24} /> : <BsFillPlayFill size={24} />}
        </button>

        <input
          type="range"
          className="time-slider"
          value={currentTime}
          min="0"
          max={duration}
          onChange={handleTimeUpdate}
          aria-label="Audio progress"
          style={{
            '--progress-width': `${(currentTime * 100) / duration}%`
          }}
        />

        <audio 
          ref={audioRef}
          src={audioUrl}
          preload="metadata"
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
        />
      </div>
    </div>
  );
};

export default AudioPlayerComponent; 