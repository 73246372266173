import React, { useEffect, useState } from 'react';
import { fetchLatestZinsInjektion, fetchHistoricalZinsInjektion } from '../services/marketDataService';
import DataCard from './DataCard';
import { ComposedChart, Area, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const ZinsInjektionComponent = () => {
  const [zinsInjektionData, setZinsInjektionData] = useState(null);
  const [historicalData, setHistoricalData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Hilfsfunktion für deutsche Zahlenformatierung am Anfang der Komponente hinzufügen
  const formatNumberDE = (number) => {
    return new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number);
  };

  useEffect(() => {
    const loadZinsInjektionData = async () => {
      try {
        setLoading(true);
        const latestData = await fetchLatestZinsInjektion();
        const historicalZinsInjektion = await fetchHistoricalZinsInjektion();
        setZinsInjektionData(latestData);
        
        // Sort historical data by date in ascending order
        const sortedData = historicalZinsInjektion
          .sort((a, b) => new Date(a.date) - new Date(b.date))
          .filter(d => new Date(d.date) >= new Date('2018-10-01'))
          .map(d => ({
            date: new Date(d.date).toLocaleDateString('de-DE', { year: 'numeric', month: 'short' }),
            annualizedInjection: d.injection / 1e9, // Convert to billions
            yoy: d.yoy * 100 // Convert decimal to percentage
          }));

        setHistoricalData(sortedData);
      } catch (err) {
        console.error('Error in ZinsInjektionComponent:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadZinsInjektionData();
  }, []);

  if (loading) {
    return <div className="loading">Zins-Injektion-Daten werden geladen...</div>;
  }

  if (error) {
    return <div className="error">Fehler beim Abrufen der Zins-Injektion-Daten: {error}</div>;
  }

  if (!zinsInjektionData) {
    return <div className="error">Keine Zins-Injektion-Daten verfügbar.</div>;
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const annualizedInjectionValue = payload[0] && payload[0].value;
      const yoyValue = payload[1] && payload[1].value;
      return (
        <div className="custom-tooltip">
          <p className="label">{`Datum: ${label}`}</p>
          {annualizedInjectionValue !== undefined && (
            <p className="info">
              <span className="injection-dot">●</span> Annualisierte Injektion: {formatNumberDE(annualizedInjectionValue)} Milliarden USD
            </p>
          )}
          {yoyValue !== undefined && (
            <p className="info">
              <span className="yoy-dot">●</span> Wachstum im Jahresvergleich: {formatNumberDE(yoyValue)}%
            </p>
          )}
        </div>
      );
    }
    return null;
  };

  const CustomizedAxisTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
          {payload.value}
        </text>
      </g>
    );
  };

  const chartConfig = {
    xAxisDataKey: 'date',
    yAxisDomain: [0, 'auto'],
    CustomizedAxisTick: CustomizedAxisTick,
    CustomTooltip: CustomTooltip
  };

  const latestAnnualizedInjection = historicalData[historicalData.length - 1]?.annualizedInjection || 0;
  const latestYoY = (zinsInjektionData.yoy * 100);
  const interpretationText = `Die aktuelle annualisierte Zins-Injektion (basierend auf den letzten 3 Monaten) beträgt <strong>${formatNumberDE(latestAnnualizedInjection)} Milliarden USD</strong> mit einem Wachstum im Jahresvergleich von <strong>${formatNumberDE(latestYoY)}%</strong>. Diese hohe Injektion spiegelt den aktuellen Höhepunkt des Leitzinszyklus wider. Mit einer erwarteten Senkung der Leitzinsen in der Zukunft ist davon auszugehen, dass die Injektion in die Wirtschaft allmählich abnehmen wird.`;

  const expandedExplanation = `
Zinszahlungen fließen von der US-Regierung in den Privatsektor, wenn die Regierung die Zinsen auf ihre ausstehenden Schulden (Staatsanleihen) bezahlt. Dies geschieht aus mehreren Gründen:

1. Schuldendienst: Die Regierung muss die vereinbarten Zinsen auf ihre Anleihen zahlen, um ihre Kreditwürdigkeit zu erhalten.
2. Anlegerentschädigung: Investoren erhalten eine Rendite für das Risiko, das sie eingehen, indem sie der Regierung Geld leihen.
3. Geldpolitisches Instrument: Staatsanleihen und ihre Zinszahlungen sind ein wichtiges Instrument der Zentralbank zur Steuerung der Geldmenge und Zinssätze.

Diese Zinszahlungen haben weitreichende Auswirkungen auf die Wirtschaft:

Die Zins-Injektion repräsentiert die monatlichen Zinszahlungen der US-Regierung, die in die Wirtschaft fließen. Aus der Perspektive unseres Markt-Modells sind diese Zahlungen eine Form der Geldschöpfung und wirken als fiskalischer Stimulus.

Wichtige Aspekte:

1. Zyklische Natur: Die Höhe der Zins-Injektion ist eng mit dem Leitzinszyklus verbunden. Aktuell befinden wir uns am Höhepunkt dieses Zyklus, was die hohen Injektionen erklärt.

2. Geldschöpfung: Jede Zinszahlung erhöht die Geldmenge in der Wirtschaft, was besonders in Hochzinsphasen bedeutsam ist.

3. Fiskalischer Stimulus: Diese Zahlungen können die Gesamtnachfrage ankurbeln und wirtschaftliche Aktivität fördern, insbesondere wenn die Zinssätze hoch sind.

4. Zukünftige Entwicklung: Mit erwarteten Leitzinssenkungen ist davon auszugehen, dass die Injektion in die Wirtschaft in Zukunft abnehmen wird.

5. Verteilungseffekte: Zinszahlungen fließen hauptsächlich an Anleihebesitzer, was Verteilungseffekte haben kann, besonders in Hochzinsphasen.

6. Inflationspotenzial: Große Zins-Injektionen könnten inflationär wirken, besonders wenn sie schnell wachsen.

7. Schuldendienstfähigkeit: Die hohen Zins-Injektionen könnten die langfristige Schuldendienstfähigkeit beeinflussen, insbesondere wenn die Zinssätze länger auf hohem Niveau bleiben.

8. Geldpolitische Implikationen: Die aktuell hohen Zins-Injektionen könnten die Effektivität der Geldpolitik beeinflussen und zukünftige geldpolitische Entscheidungen beeinflussen.

9. Wirtschaftszyklus-Indikator: Die aktuelle Höhe der Injektionen gibt Hinweise auf die späte Phase des Zinserhöhungszyklus und könnte auf eine bevorstehende Wende in der Geldpolitik hindeuten.

Beachten Sie: Diese Perspektive ist Teil der MMT und nicht allgemein akzeptierte ökonomische Theorie. Andere Sichtweisen auf Staatsschulden und Zinszahlungen existieren.
`;

  const CustomChart = () => (
    <ResponsiveContainer width="100%" height={300}>
      <ComposedChart 
        data={historicalData}
        margin={{ top: 5, right: 30, left: 35, bottom: 65 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" tick={<CustomizedAxisTick />} />
        <YAxis 
          yAxisId="left"
          orientation="left"
          domain={[0, 'auto']}
          tickFormatter={(value) => formatNumberDE(value)}
          stroke="var(--highlight)"
          label={{ 
            value: 'Milliarden USD', 
            angle: -90, 
            position: 'insideLeft',
            offset: -20, 
            style: { textAnchor: 'middle' }
          }}
        />
        <YAxis 
          yAxisId="right"
          orientation="right"
          domain={[-60, 60]}
          tickFormatter={(value) => `${formatNumberDE(value)}%`}
          stroke="#ff0000"
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend 
          verticalAlign="bottom" 
          height={36}
          wrapperStyle={{
            bottom: 0,
            paddingTop: '20px'
          }}
        />
        <Area 
          type="monotone" 
          dataKey="annualizedInjection" 
          stroke="var(--highlight)" 
          fill="var(--highlight)"
          fillOpacity={0.3}
          strokeWidth={2}
          dot={false}
          name="Annualisierte Injektion (Milliarden USD)"
          yAxisId="left"
        />
        <Line 
          type="monotone" 
          dataKey="yoy" 
          stroke="#ff0000" 
          strokeWidth={2}
          dot={false}
          name="Wachstum im Jahresvergleich (%)"
          yAxisId="right"
          filter="url(#glow)"
        />
        <defs>
          <filter id="glow">
            <feGaussianBlur stdDeviation="2.5" result="coloredBlur"/>
            <feMerge>
              <feMergeNode in="coloredBlur"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
        </defs>
      </ComposedChart>
    </ResponsiveContainer>
  );

  return (
    <DataCard 
      title="Annualisierte Zins-Injektion"
      value={`${formatNumberDE(latestAnnualizedInjection)} Milliarden USD`}
      timestamp={zinsInjektionData.date}
      category="Geldpolitik"
      explanation={<pre className="expanded-explanation">{expandedExplanation}</pre>}
      chartConfig={chartConfig}
      interpretationText={interpretationText}
    >
      <CustomChart />
    </DataCard>
  );
};

export default ZinsInjektionComponent;
