import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import DataCard from './DataCard';
import { fetchLatestPERatio, fetchHistoricalPERatios } from '../services/marketDataService';

const GlobalPEComponent = () => {
  const [peRatios, setPERatios] = useState(null);
  const [historicalPERatios, setHistoricalPERatios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    fetchPERatios();
  }, []);

  const fetchPERatios = async () => {
    try {
      setLoading(true);
      const latestData = await fetchLatestPERatio();
      const historicalData = await fetchHistoricalPERatios();

      console.log('Latest PE Ratios:', latestData);
      console.log('Historical PE Ratios:', historicalData);

      setPERatios(latestData);
      setHistoricalPERatios(historicalData);
    } catch (error) {
      console.error('Error fetching PE ratios:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const peRanges = {
    SPY: { min: 13, max: 30 },
    MCHI: { min: 8, max: 20 },
    VGK: { min: 8, max: 26 },
    EWJ: { min: 11, max: 24 },
    EEM: { min: 8, max: 17 }
  };

  const getUSAValuation = (pe) => {
    if (pe >= 30) return "extrem teuer";
    if (pe >= 24) return "teuer";
    if (pe >= 19) return "fair";
    if (pe >= 15) return "günstig";
    return "sehr günstig";
  };

  const renderPERatioScale = (code, value) => {
    const range = peRanges[code];
    const position = ((value - range.min) / (range.max - range.min)) * 100;
    const clampedPosition = Math.max(0, Math.min(100, position));

    return (
      <div className="pe-ratio-scale-container">
        <div className="pe-ratio-scale">
          <div 
            className="pe-ratio-indicator" 
            style={{ left: `${clampedPosition}%` }}
          ></div>
        </div>
        <div className="pe-ratio-labels">
          <span>{range.min}</span>
          <span>{range.max}</span>
        </div>
      </div>
    );
  };

  const renderPERatios = () => {
    if (!peRatios) return null;

    const ratios = [
      { name: 'EM', value: peRatios.EEM, code: 'EEM', flag: '🌍' },
      { name: 'China', value: peRatios.MCHI, code: 'MCHI', flag: '🇨🇳' },
      { name: 'Europa', value: peRatios.VGK, code: 'VGK', flag: '🇪🇺' },
      { name: 'Japan', value: peRatios.EWJ, code: 'EWJ', flag: '🇯🇵' },
      { name: 'S&P 500', value: peRatios.SPY, code: 'SPY', flag: '🇺🇸' },
    ];

    return (
      <div className={isExpanded ? "pe-ratios-list" : "pe-ratios-grid"}>
        {ratios.map((ratio) => (
          <div key={ratio.code} className="pe-ratio-item">
            <div className="pe-ratio-header">
              <span className="pe-ratio-name">{ratio.flag} {ratio.name}</span>
              <span className="pe-ratio-value">
                {ratio.value != null ? ratio.value.toFixed(2) : 'N/A'}
              </span>
            </div>
            {ratio.value != null && renderPERatioScale(ratio.code, ratio.value)}
          </div>
        ))}
      </div>
    );
  };

  const interpretationText = peRatios
    ? `Die aktuellen globalen KGV-Verhältnisse zeigen unterschiedliche Bewertungen in den Märkten. 
       ${peRatios.SPY != null ? `Der S&P 500 (USA) hat ein KGV von ${peRatios.SPY.toFixed(2)}, ` : ''}
       ${peRatios.MCHI != null ? `während das KGV des chinesischen Marktes bei ${peRatios.MCHI.toFixed(2)} liegt. ` : ''}
       ${peRatios.VGK != null ? `Europa steht bei ${peRatios.VGK.toFixed(2)}, ` : ''}
       ${peRatios.EWJ != null ? `Japan bei ${peRatios.EWJ.toFixed(2)}, ` : ''}
       ${peRatios.EEM != null ? `und die Schwellenländer bei ${peRatios.EEM.toFixed(2)}. ` : ''}
       Diese Unterschiede spiegeln unterschiedliche Wachstumserwartungen und Risikowahrnehmungen in den Regionen wider. Die Farbskalen bieten eine schnelle visuelle Referenz dafür, wie das aktuelle KGV jedes Marktes im Vergleich zu seiner historischen Spanne steht.`
    : 'Interpretation aufgrund fehlender Daten nicht verfügbar.';

  const chartConfig = {
    xAxisDataKey: 'date',
    dataKey: 'peRatio',
    xAxisFormatter: (timestamp) => {
      const date = new Date(timestamp);
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
    },
    tooltipFormatter: (value) => [`PE Ratio: ${value.toFixed(2)}`, 'S&P 500'],
    yAxisFormatter: (value) => value.toFixed(1),
    yAxisDomain: [10, 30]
  };

  if (loading) {
    return <div className="loading">Lade Globale KGV-Verhältnisse...</div>;
  }

  if (error) {
    return <div className="error">Fehler beim Laden der Globalen KGV-Verhältnisse: {error}</div>;
  }

  const usaPE = peRatios && peRatios.SPY != null ? peRatios.SPY.toFixed(2) : 'N/A';
  const usaValuation = peRatios && peRatios.SPY != null ? getUSAValuation(peRatios.SPY) : '';

  return (
    <DataCard
      title="Globale KGV-Verhältnisse"
      value={`USA: ${usaPE} (${usaValuation})`}
      timestamp={peRatios && peRatios.date ? new Date(peRatios.date).toLocaleDateString() : null}
      category="Marktbewertung"
      explanation={interpretationText}
      isExpanded={isExpanded}
      onToggleExpand={() => setIsExpanded(!isExpanded)}
      chartData={historicalPERatios}
      chartConfig={chartConfig}
      isRealtime={true}
    >
      {renderPERatios()}
    </DataCard>
  );
};

export default GlobalPEComponent;
