import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import '../styles/chat.css';

const UserSelectModal = ({ onSelect, onClose }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data, error } = await supabase
          .from('user_subscriptions')
          .select('user_id, nickname')
          .order('nickname');

        if (error) throw error;
        setUsers(data || []);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const filteredUsers = users.filter(user => 
    user.nickname?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="modal-overlay">
      <div className="user-select-modal">
        <div className="modal-header">
          <h3>Select User to Chat With</h3>
          <button className="close-button" onClick={onClose}>✕</button>
        </div>
        
        <input
          type="text"
          placeholder="Search users..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="user-search-input"
        />

        <div className="users-list">
          {loading ? (
            <div className="loading">Loading users...</div>
          ) : (
            filteredUsers.map(user => (
              <button
                key={user.user_id}
                className="user-select-button"
                onClick={() => onSelect(user)}
              >
                {user.nickname || 'Anonymous'}
              </button>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default UserSelectModal; 