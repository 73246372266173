import React, { useEffect, useState } from 'react';
import { fetchLatestAAII, fetchHistoricalAAII } from '../services/marketDataService';
import DataCard from './DataCard';
import { supabase } from '../supabaseClient';  // Add this line

const AAIIComponent = () => {
  const [aaiiData, setAAIIData] = useState(null);
  const [historicalData, setHistoricalData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadAAIIData = async () => {
      try {
        setLoading(true);
        const latestData = await fetchLatestAAII();
        const historicalAAII = await fetchHistoricalAAII();
        
        // Calculate 12-month rolling average
        const sortedData = historicalAAII.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        const rollingAverage = sortedData.map((_, index, array) => {
          // Get last 52 weeks (approximately 12 months) of data
          const last52Weeks = array.slice(Math.max(0, index - 51), index + 1);
          const average = last52Weeks.reduce((sum, entry) => sum + entry.spread, 0) / last52Weeks.length;
          return {
            date: new Date(array[index].created_at).toLocaleDateString(),
            spread: average
          };
        });

        // Calculate consecutive positive weeks
        let consecutivePositiveWeeks = 0;
        for (let i = sortedData.length - 1; i >= 0; i--) {
          if (sortedData[i].spread > 0) {
            consecutivePositiveWeeks++;
          } else {
            break;
          }
        }

        setAAIIData({
          ...latestData,
          spread: rollingAverage[rollingAverage.length - 1].spread,
          consecutivePositiveWeeks
        });
        setHistoricalData(rollingAverage);
      } catch (err) {
        console.error('Error in AAIIComponent:', err);
        const session = await supabase.auth.getSession();
        console.error('Current auth status:', session);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadAAIIData();
  }, []);

  if (loading) {
    return <div className="loading">AAII-Daten werden geladen...</div>;
  }

  if (error) {
    return <div className="error">Fehler beim Abrufen der AAII-Daten: {error}</div>;
  }

  if (!aaiiData) {
    return <div className="error">Keine AAII-Daten verfügbar.</div>;
  }

  const chartConfig = {
    xAxisDataKey: 'date',
    dataKey: 'spread',
    referenceLine: { y: 0, stroke: 'red', strokeDasharray: '3 3' }
  };

  const spread = aaiiData.spread * 100;
  const interpretationText = `Die 12-Monats-Durchschnitt der AAII-Stimmungsdifferenz beträgt <strong>${spread.toFixed(2)}%</strong>. ${
    spread > 10
      ? "Ein stark positiver Durchschnitt deutet auf übermäßigen Optimismus hin - ein bärisches Signal für konträre Investoren."
      : spread > 0
      ? "Ein leicht positiver Durchschnitt liegt im normalen Bereich."
      : spread > -20
      ? "Ein negativer Durchschnitt deutet auf Pessimismus hin - ein leicht bullisches Signal für konträre Investoren."
      : "Ein stark negativer Durchschnitt deutet auf extremen Pessimismus hin - ein stark bullisches Signal für konträre Investoren."
  } ${
    Math.abs(spread) > 20 || spread > 10
      ? "Dies gilt als extreme Messung und könnte auf eine potenzielle Marktumkehr hindeuten."
      : "Dies liegt im normalen Bereich."
  }`;

  const expandedExplanation = `
Die AAII-Stimmungsumfrage misst den Prozentsatz der Privatanleger, die für die nächsten sechs Monate bullisch, bärisch oder neutral gegenüber dem Aktienmarkt eingestellt sind.

Aus der Perspektive eines konträren Investors:

1. Extreme Werte:
   • Hohe positive Differenz (> 10%): Übermäßiger Optimismus, bärisches Signal
   • Moderate Differenz (0% bis 10%): Normaler Bereich, neutral
   • Negative Differenz (0% bis -20%): Pessimismus, leicht bullisches Signal
   • Stark negative Differenz (< -20%): Extremer Pessimismus, stark bullisches Signal

2. Konträre Indikatoren:
   • Wenn die Mehrheit bullisch ist, erwägen konträre Investoren bearish zu sein und umgekehrt
   • Basiert auf der Annahme, dass die Masse oft falsch liegt, besonders bei Extremen

3. Market Timing:
   • Extreme Werte können auf überkaufte oder überverkaufte Marktbedingungen hinweisen
   • Mögliche Einstiegs- oder Ausstiegspunkte für konträre Strategien

4. Sentiment als Frühindikator:
   • Stimmungsänderungen können Marktbewegungen vorausgehen
   • Ermöglicht es Investoren, sich auf potenzielle Trendwenden vorzubereiten

5. Kombination mit anderen Indikatoren:
   • AAII-Daten sollten nicht isoliert betrachtet werden
   • Kombination mit technischen und fundamentalen Analysen für robustere Entscheidungen

6. Langfristige Perspektive:
   • Der 12-Monats-Durchschnitt glättet kurzfristige Schwankungen
   • Extreme Werte im Durchschnitt sind besonders aussagekräftig

Wichtiger Hinweis:
Konträres Investieren birgt Risiken. Märkte können länger irrational bleiben als erwartet. Gründliche Analyse und striktes Risikomanagement sind unerlässlich.
`;

  const warningMessage = aaiiData.consecutivePositiveWeeks > 0 
    ? `Achtung: Mit ${aaiiData.consecutivePositiveWeeks} Wochen positiver Stimmung in Folge befinden wir uns in der längsten Periode optimistischer Stimmung seit Beginn der Aufzeichnungen. Der 12-Monats-Durchschnitt ist auf dem höchsten Stand seit der Dotcom-Blase.`
    : null;

  return (
    <DataCard 
      title="AAII-Stimmung"
      value={`${spread.toFixed(2)}%`}
      timestamp={aaiiData.created_at}
      chartData={historicalData}
      category="Marktstimmung"
      explanation={<pre className="expanded-explanation">{expandedExplanation}</pre>}
      chartConfig={chartConfig}
      interpretationText={interpretationText}
      warningMessage={warningMessage}
    />
  );
};

export default AAIIComponent;