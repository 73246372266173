import React, { useState, useEffect } from 'react';
import { 
  fetchLatestVIX, 
  fetchLatestAAII, 
  fetchLatestM2Data, 
  fetchLatestCreditCardDelinquency, 
  fetchLatestPersonalSavingRate, 
  fetchHistoricalLiquidityData,
  fetchLatestZinsInjektion
} from '../services/marketDataService';
import DataCard from './DataCard';
import { supabase } from '../supabaseClient';

const SIGNAL_LEVELS = {
  VERY_BEARISH: -2,
  BEARISH: -1,
  NEUTRAL: 0,
  BULLISH: 1,
  VERY_BULLISH: 2
};

const SIGNAL_WEIGHTS = {
  VIX: 1,
  AAII: 1,
  Unemployment: 1.5,
  FiscalFlows: 1.5,
  M2Growth: 1.5,
  CreditCardDelinquency: 1.5,
  PersonalSavingRate: 1.5,  // Add weight for Personal Saving Rate
  GlobalLiquidity: 1.5,  // Add weight for Global Liquidity Index
  RecessionIndicator: 2,  // Add weight for Recession Indicator (higher weight due to its importance)
  USPE: 1.5,  // Add weight for US PE Ratio
  ZinsInjektion: 1.5,  // Add weight for ZinsInjektion
};

const getVIXSignal = (vix) => {
  if (vix >= 40) return SIGNAL_LEVELS.VERY_BULLISH;
  if (vix >= 30) return SIGNAL_LEVELS.BULLISH;
  if (vix >= 20) return SIGNAL_LEVELS.NEUTRAL;
  if (vix >= 10) return SIGNAL_LEVELS.BEARISH;
  return SIGNAL_LEVELS.VERY_BEARISH;
};

const getAAIISignal = (aaiiSpread) => {
  if (aaiiSpread > 0.3) return SIGNAL_LEVELS.VERY_BEARISH;
  if (aaiiSpread > 0.2) return SIGNAL_LEVELS.BEARISH;
  if (aaiiSpread >= 0) return SIGNAL_LEVELS.NEUTRAL;
  if (aaiiSpread >= -0.2) return SIGNAL_LEVELS.BULLISH;
  return SIGNAL_LEVELS.VERY_BULLISH;
};

const getUnemploymentSignal = (state, sahmRuleTriggered) => {
  if (sahmRuleTriggered) return SIGNAL_LEVELS.VERY_BEARISH;
  if (state === 'rising') return SIGNAL_LEVELS.BEARISH;
  if (state === 'stable') return SIGNAL_LEVELS.BULLISH;
  if (state === 'falling') return SIGNAL_LEVELS.VERY_BULLISH;
  return SIGNAL_LEVELS.NEUTRAL;
};

const getFiscalFlowsSignal = (state) => {
  if (state === 'increasing') return SIGNAL_LEVELS.BULLISH;
  if (state === 'stable') return SIGNAL_LEVELS.NEUTRAL;
  if (state === 'decreasing') return SIGNAL_LEVELS.VERY_BEARISH;
  return SIGNAL_LEVELS.NEUTRAL;
};

const getM2GrowthSignal = (m2Growth) => {
  if (m2Growth < 0) return SIGNAL_LEVELS.VERY_BEARISH;
  if (m2Growth < 5) return SIGNAL_LEVELS.BEARISH;
  if (m2Growth < 6) return SIGNAL_LEVELS.BULLISH;
  return SIGNAL_LEVELS.VERY_BULLISH;
};

const getCreditCardDelinquencySignal = (rate) => {
  if (rate >= 4) return SIGNAL_LEVELS.VERY_BEARISH;
  if (rate >= 3) return SIGNAL_LEVELS.BEARISH;
  if (rate >= 2.5) return SIGNAL_LEVELS.NEUTRAL;
  if (rate >= 2) return SIGNAL_LEVELS.BULLISH;
  return SIGNAL_LEVELS.VERY_BULLISH;
};

const getPersonalSavingRateSignal = (rate) => {
  if (rate < 2) return SIGNAL_LEVELS.VERY_BEARISH;
  if (rate < 5) return SIGNAL_LEVELS.BEARISH;
  if (rate < 7) return SIGNAL_LEVELS.NEUTRAL;
  if (rate < 10) return SIGNAL_LEVELS.BULLISH;
  return SIGNAL_LEVELS.VERY_BULLISH;
};

const getGlobalLiquiditySignal = (currentLiquidity, oneMonthAvg, threeMonthAvg) => {
  const isDecreasing1Month = currentLiquidity < oneMonthAvg;
  const isDecreasing3Month = currentLiquidity < threeMonthAvg;

  if (isDecreasing1Month && isDecreasing3Month) return SIGNAL_LEVELS.VERY_BEARISH;
  if (isDecreasing1Month || isDecreasing3Month) return SIGNAL_LEVELS.BEARISH;
  if (!isDecreasing1Month && !isDecreasing3Month) return SIGNAL_LEVELS.VERY_BULLISH;
  return SIGNAL_LEVELS.BULLISH;
};

const getRecessionIndicatorSignal = (currentValue) => {
  if (currentValue > 50) return SIGNAL_LEVELS.VERY_BEARISH;
  if (currentValue >= 35) return SIGNAL_LEVELS.BEARISH;
  if (currentValue >= 20) return SIGNAL_LEVELS.NEUTRAL;
  if (currentValue >= 10) return SIGNAL_LEVELS.BULLISH;
  return SIGNAL_LEVELS.VERY_BULLISH;
};

const getUSPESignal = (pe) => {
  if (pe >= 30) return SIGNAL_LEVELS.VERY_BEARISH;
  if (pe >= 25) return SIGNAL_LEVELS.BEARISH;
  if (pe >= 20) return SIGNAL_LEVELS.NEUTRAL;
  if (pe >= 15) return SIGNAL_LEVELS.BULLISH;
  return SIGNAL_LEVELS.VERY_BULLISH;
};

const getZinsInjektionSignal = () => {
  // Manually set to bullish
  return SIGNAL_LEVELS.BULLISH;
};

const calculateContrarianSignal = (signals) => {
  let totalScore = 0;
  let totalWeight = 0;

  for (const [key, { score, direction }] of Object.entries(signals)) {
    const weight = score / (Object.values(signals).reduce((sum, s) => sum + s.score, 0));
    totalScore += direction * weight;
    totalWeight += weight;
  }

  return totalScore > 0 ? 'Bullish' : 'Bearish';
};

const getSignalInterpretation = (signalName, direction, score, value) => {
  const getStrength = (score) => {
    if (score === 2) return "very strong";
    if (score === 1) return "strong";
    return "moderate";
  };

  const getBearBull = (direction) => {
    if (direction > 0) return "bullish";
    if (direction < 0) return "bearish";
    return "neutral";
  };

  const interpretations = {
    VIX: (direction, score, value) => `VIX is at ${value}, showing a ${getStrength(score)} ${getBearBull(direction)} signal. ${direction > 0 ? "Higher volatility might indicate market uncertainty." : direction < 0 ? "Lower volatility could suggest market complacency." : "This indicates balanced market sentiment."}`,
    AAII: (direction, score, value) => {
      let interpretation = `AAII sentiment spread is at ${(value * 100).toFixed(1)}%, showing a ${getStrength(score)} ${getBearBull(direction)} signal. `;
      if (value > 0.3) {
        interpretation += "The bullish sentiment is significantly above the historical average, which from a contrarian perspective, suggests potential downside in the market.";
      } else if (value > 0.2) {
        interpretation += "The bullish sentiment is above the historical average, which from a contrarian perspective, suggests potential caution in the market.";
      } else if (value >= 0) {
        interpretation += "The bullish sentiment is near the historical average, suggesting a balanced market sentiment.";
      } else if (value >= -0.2) {
        interpretation += "The bullish sentiment is below the historical average, which from a contrarian perspective, suggests potential upside in the market.";
      } else {
        interpretation += "The bullish sentiment is significantly below the historical average, which from a contrarian perspective, suggests strong potential upside in the market.";
      }
      return interpretation;
    },
    Unemployment: (direction, score, value) => `Unemployment is at ${value}%, showing a ${getStrength(score)} ${getBearBull(direction)} signal. While the unemployment rate is low, the Sahm rule has been triggered, indicating a rapid increase in unemployment over the recent past. This could indicate the onset of a recession.`,
    FiscalFlows: (direction, score, value) => `Fiscal flows are ${value}, showing a ${getStrength(score)} ${getBearBull(direction)} signal. ${direction > 0 ? "Increased government spending or lower taxes may stimulate the economy." : direction < 0 ? "Decreased government spending or higher taxes might slow economic growth." : "Fiscal policy is relatively stable."}`,
    M2Growth: (direction, score, value) => `M2 money supply growth is ${getStrength(score)} ${getBearBull(direction)} at ${value}%. ${direction >= 0 ? "Expanding money supply can fuel economic growth and inflation." : "Contracting money supply might lead to economic slowdown and deflation."}`,
    CreditCardDelinquency: (direction, score, value) => `Credit card delinquency rate is at ${value}%, showing a ${getStrength(score)} ${getBearBull(direction)} signal. ${direction >= 0 ? "Lower delinquency rates suggest healthier consumer finances." : "Higher delinquency rates may indicate consumer financial stress."}`,
    PersonalSavingRate: (direction, score, value) => `The personal saving rate is at ${value}%, showing a ${getStrength(score)} ${getBearBull(direction)} signal. ${direction > 0 ? "Higher saving rates can indicate caution, potentially slowing economic growth." : direction < 0 ? "Lower saving rates might suggest increased consumer spending, boosting economic activity." : "This level suggests a balance between consumer spending and saving."}`,
    GlobalLiquidity: (direction, score, value) => `Global liquidity is ${value}, showing a ${getStrength(score)} ${getBearBull(direction)} signal. ${direction >= 0 ? "Increased liquidity can fuel asset price growth." : "Decreased liquidity might lead to tighter financial conditions."}`,
    RecessionIndicator: (direction, score, value) => `The recession indicator is at ${value}%, showing a ${getStrength(score)} ${getBearBull(direction)} signal. ${direction > 0 ? "Lower recession probability suggests economic stability or growth." : direction < 0 ? "Higher recession probability indicates increased economic risk." : "The current level suggests a balanced economic outlook."}`,
    USPE: (direction, score, value) => `US PE ratio is at ${value}, which is ${getStrength(score)} ${getBearBull(direction)}. ${direction >= 0 ? "Lower PE ratios might indicate undervalued stocks." : "Higher PE ratios could suggest overvalued stocks."}`,
    ZinsInjektion: (direction, score, value) => `ZinsInjektion is at ${value}, showing a ${getStrength(score)} ${getBearBull(direction)} signal. ${direction > 0 ? "ZinsInjektion is bullish." : direction < 0 ? "ZinsInjektion is bearish." : "ZinsInjektion is neutral."}`
  };

  return interpretations[signalName](direction, score, value);
};

const MarketScoreComponent = ({ fiscalFlowsState }) => {
  const [marketScore, setMarketScore] = useState(null);
  const [signals, setSignals] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const calculateMarketScore = (signals) => {
    let totalScore = 0;
    let totalWeight = 0;

    for (const [key, { score, direction }] of Object.entries(signals)) {
      const weight = SIGNAL_WEIGHTS[key] || 1; // Use predefined weights or default to 1
      totalScore += direction * weight;
      totalWeight += weight;
    }

    // Calculate the weighted average and convert to a 0-100 scale
    const weightedAverage = totalScore / totalWeight;
    const scaledScore = ((weightedAverage + 2) / 4) * 100; // Convert from -2 to 2 range to 0 to 100

    return Math.round(scaledScore);
  };

  useEffect(() => {
    const calculateMarketData = async () => {
      try {
        setLoading(true);

        // Fetch data
        const vixData = await fetchLatestVIX();
        const aaiiData = await fetchLatestAAII();
        const m2Data = await fetchLatestM2Data();
        const creditCardDelinquencyData = await fetchLatestCreditCardDelinquency();
        const personalSavingRateData = await fetchLatestPersonalSavingRate();
        const liquidityData = await fetchHistoricalLiquidityData();

        // Use hardcoded recession data
        const recessionData = [
          { date: 'Apr 24', value: 29 },
          { date: 'May 24', value: 27 },
          { date: 'Jun 24', value: 30 },
          { date: 'Jul 24', value: 31 },
          { date: 'Aug 24', value: 34 },
          { date: 'Sep 24', value: 35 },
          { date: 'Sep 24', value: 33 },
        ];
        const latestRecessionIndicator = recessionData[recessionData.length - 1];

        // Fetch ZinsInjektion data
        const zinsInjektionData = await fetchLatestZinsInjektion();

        // Calculate signals
        const vixSignal = getVIXSignal(vixData.VIX);
        const aaiiSignal = getAAIISignal(aaiiData.spread);
        const unemploymentSignal = getUnemploymentSignal(false, 5.5, 'rising'); // Hardcoded for now
        const fiscalFlowsSignal = getFiscalFlowsSignal(fiscalFlowsState);
        const m2GrowthSignal = getM2GrowthSignal(m2Data.yoy);
        const creditCardDelinquencySignal = getCreditCardDelinquencySignal(creditCardDelinquencyData.delinquency_rate);
        const personalSavingRateSignal = getPersonalSavingRateSignal(personalSavingRateData.saving_rate);
        const avgLiquidity = liquidityData.reduce((sum, d) => sum + d.liquidity, 0) / liquidityData.length;
        const globalLiquiditySignal = getGlobalLiquiditySignal(
          liquidityData[liquidityData.length - 1].liquidity,
          avgLiquidity
        );
        const recessionIndicatorSignal = getRecessionIndicatorSignal(latestRecessionIndicator.value);
        const zinsInjektionSignal = getZinsInjektionSignal();

        // Fetch US PE Ratio
        const { data: peData, error: peError } = await supabase
          .from('pe_ratios')
          .select('SPY')
          .order('date', { ascending: false })
          .limit(1)
          .single();

        if (peError) throw peError;

        const usPESignal = getUSPESignal(peData.SPY);

        const signals = {
          VIX: { score: Math.abs(vixSignal), direction: vixSignal },
          AAII: { score: Math.abs(aaiiSignal), direction: aaiiSignal },
          Unemployment: { score: Math.abs(unemploymentSignal), direction: unemploymentSignal },
          FiscalFlows: { score: Math.abs(fiscalFlowsSignal), direction: fiscalFlowsSignal },
          M2Growth: { score: Math.abs(m2GrowthSignal), direction: m2GrowthSignal },
          CreditCardDelinquency: { score: Math.abs(creditCardDelinquencySignal), direction: creditCardDelinquencySignal },
          PersonalSavingRate: { score: Math.abs(personalSavingRateSignal), direction: personalSavingRateSignal },
          GlobalLiquidity: { score: Math.abs(globalLiquiditySignal), direction: globalLiquiditySignal },
          RecessionIndicator: { score: Math.abs(recessionIndicatorSignal), direction: recessionIndicatorSignal },
          USPE: { score: Math.abs(usPESignal), direction: usPESignal },
          ZinsInjektion: { score: Math.abs(zinsInjektionSignal), direction: zinsInjektionSignal }
        };

        setSignals(signals);

        const score = calculateMarketScore(signals);
        setMarketScore(score);
      } catch (err) {
        console.error('Error in calculateMarketScore:', err);
        setError('An error occurred while calculating the market score. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    calculateMarketData();
  }, [fiscalFlowsState]);

  const getMarketCondition = (score) => {
    if (score >= 80) return "Sehr Positiv";
    if (score >= 60) return "Positiv";
    if (score >= 40) return "Neutral";
    if (score >= 20) return "Negativ";
    return "Sehr Negativ";
  };

  const getBearBull = (direction) => {
    if (direction > 0) return "Positiv";
    if (direction < 0) return "Negativ";
    return "Neutral";
  };

  const getSignalStrength = (score) => {
    if (score === 2) return "Sehr";
    if (score === 1) return "";
    return "Leicht";
  };

  const getPillClass = (direction, score) => {
    if (direction === 0) return 'neutral';
    const baseClass = direction > 0 ? 'bullish' : 'bearish';
    if (score === 2) return `very-${baseClass}`;
    if (score === 1) return baseClass;
    return `slightly-${baseClass}`;
  };

  const renderScoreScale = (score) => {
    const position = `${score}%`;
    return (
      <div className="market-score-scale-container">
        <div className="market-score-scale">
          <div 
            className="market-score-indicator" 
            style={{ left: position }}
          ></div>
        </div>
        <div className="market-score-labels">
          <span>0</span>
          <span>50</span>
          <span>100</span>
        </div>
      </div>
    );
  };

  if (loading) {
    return <div className="loading">Marktbewertung wird berechnet...</div>;
  }

  if (error) {
    return <div className="error">Fehler bei der Berechnung der Marktbewertung: {error}</div>;
  }

  const condition = getMarketCondition(marketScore);

  const signalTranslations = {
    VIX: "VIX",
    AAII: "AAII",
    Unemployment: "Arbeitslosigkeit",
    FiscalFlows: "Fiskalströme",
    M2Growth: "M2-Wachstum",
    CreditCardDelinquency: "Kreditkartenverzug",
    PersonalSavingRate: "Persönliche Sparquote",
    GlobalLiquidity: "Globaler Liquiditätsindex",
    RecessionIndicator: "Rezessionsindikator",
    USPE: "US KGV",
    ZinsInjektion: "Zins-Injektion",
  };

  return (
    <div>
      <DataCard
        title="Market Score"
        value={`${marketScore} - ${getMarketCondition(marketScore)}`}
        timestamp={Date.now()}
        category=""
        explanation={
          <div className="market-score-content">
            <div className="market-score-header">
              <h3>{getMarketCondition(marketScore)}</h3>
              <p>Diese Bewertung betont extreme Signale, um signifikante Marktbewegungen zu erfassen.</p>
            </div>
            {renderScoreScale(marketScore)}
            <div className="market-score-table">
              {Object.entries(signals).map(([key, value]) => (
                <div key={key} className="market-score-row">
                  <span className="signal-name">{signalTranslations[key]}:</span>
                  <span className={`pill ${getPillClass(value.direction, value.score)}`}>
                    {value.direction === 0 ? "Neutral" : `${getSignalStrength(value.score)} ${getBearBull(value.direction)}`}
                  </span>
                  <span className="signal-weight">
                    Extremität: {value.score !== undefined ? value.score.toFixed(2) : 'N/A'}
                  </span>
                </div>
              ))}
            </div>
          </div>
        }
        isRealtime={true}
      />
    </div>
  );
};

export default MarketScoreComponent;
