// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --positive-area: rgba(76, 175, 80, 0.3);  /* Semi-transparent Green */
    --negative-area: rgba(244, 67, 54, 0.3);  /* Semi-transparent Red */
  }
  
  .data-card-chart {
    background-color: var(--frosted-bg);
    position: relative;
  }
  
  /* Make sure areas are visible */
  .data-card-chart .recharts-area {
    opacity: 1 !important;
  }
  
  /* Style positive area */
  .recharts-area path[d*="M"][d*="L"] {
    fill: var(--positive-area) !important;
  }
  
  /* Style negative area */
  .recharts-area path[d*="M"][d*="L"]:nth-child(2) {
    fill: var(--negative-area) !important;
  }
  
  /* Make reference line more visible */
  .recharts-reference-line line {
    stroke: var(--text-color) !important;
    stroke-width: 1px !important;
    stroke-opacity: 0.5 !important;
  }
  
  /* Optional: Hover effects */
  .data-card-chart {
    transition: all 0.3s ease;
  }
  
  .data-card-chart:hover {
    filter: brightness(1.05);
  }
  
  /* Tooltip styling */
  .recharts-tooltip-wrapper {
    background-color: var(--background);
    border: 1px solid var(--border);
    border-radius: 4px;
    padding: 8px;
  }
  
  .recharts-tooltip-label {
    color: var(--text-color);
    margin-bottom: 4px;
  }
  
  .recharts-tooltip-item {
    color: var(--text-color);
  }`, "",{"version":3,"sources":["webpack://./src/styles/chart.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC,GAAG,2BAA2B;IACrE,uCAAuC,GAAG,yBAAyB;EACrE;;EAEA;IACE,mCAAmC;IACnC,kBAAkB;EACpB;;EAEA,gCAAgC;EAChC;IACE,qBAAqB;EACvB;;EAEA,wBAAwB;EACxB;IACE,qCAAqC;EACvC;;EAEA,wBAAwB;EACxB;IACE,qCAAqC;EACvC;;EAEA,qCAAqC;EACrC;IACE,oCAAoC;IACpC,4BAA4B;IAC5B,8BAA8B;EAChC;;EAEA,4BAA4B;EAC5B;IACE,yBAAyB;EAC3B;;EAEA;IACE,wBAAwB;EAC1B;;EAEA,oBAAoB;EACpB;IACE,mCAAmC;IACnC,+BAA+B;IAC/B,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,wBAAwB;IACxB,kBAAkB;EACpB;;EAEA;IACE,wBAAwB;EAC1B","sourcesContent":[":root {\n    --positive-area: rgba(76, 175, 80, 0.3);  /* Semi-transparent Green */\n    --negative-area: rgba(244, 67, 54, 0.3);  /* Semi-transparent Red */\n  }\n  \n  .data-card-chart {\n    background-color: var(--frosted-bg);\n    position: relative;\n  }\n  \n  /* Make sure areas are visible */\n  .data-card-chart .recharts-area {\n    opacity: 1 !important;\n  }\n  \n  /* Style positive area */\n  .recharts-area path[d*=\"M\"][d*=\"L\"] {\n    fill: var(--positive-area) !important;\n  }\n  \n  /* Style negative area */\n  .recharts-area path[d*=\"M\"][d*=\"L\"]:nth-child(2) {\n    fill: var(--negative-area) !important;\n  }\n  \n  /* Make reference line more visible */\n  .recharts-reference-line line {\n    stroke: var(--text-color) !important;\n    stroke-width: 1px !important;\n    stroke-opacity: 0.5 !important;\n  }\n  \n  /* Optional: Hover effects */\n  .data-card-chart {\n    transition: all 0.3s ease;\n  }\n  \n  .data-card-chart:hover {\n    filter: brightness(1.05);\n  }\n  \n  /* Tooltip styling */\n  .recharts-tooltip-wrapper {\n    background-color: var(--background);\n    border: 1px solid var(--border);\n    border-radius: 4px;\n    padding: 8px;\n  }\n  \n  .recharts-tooltip-label {\n    color: var(--text-color);\n    margin-bottom: 4px;\n  }\n  \n  .recharts-tooltip-item {\n    color: var(--text-color);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
