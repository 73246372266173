import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation, Navigate } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import Hotjar from '@hotjar/browser';
import Home from './pages/Home';
import AdminPage from './pages/AdminPage';
import AccountPage from './pages/AccountPage';
import Portfolio from './pages/Portfolio';
import EarningsCalendar from './components/EarningsCalendar';
import './App.css';
import Chat from './pages/Chat';
import Mastermind from './pages/Mastermind';
import FeedbackModal from './components/FeedbackModal';
import OTPLogin from './components/OTPLogin';
import { supabase } from './supabaseClient';
import { SubscriptionProvider } from './contexts/SubscriptionContext';
import GoogleAnalytics from './components/GoogleAnalytics';
import LandingPage from './pages/LandingPage';
import ChangelogModal from './components/ChangelogModal';
import Trades from './pages/Trades';
import Course from './pages/Course-Template';
import Tools from './pages/Tools';
import CoveredCalls from './pages/Covered-Calls';

function MobileMenu({ typedText, onFeedbackClick, isAdmin, onChangelogClick, isOpen, setIsOpen, session, onLoginClick }) {
  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <>
      <div className={`mobile-menu-container ${isOpen ? 'open' : ''}`} style={{ filter: 'none' }}>
        <div className="mobile-menu-bar">
          <div className="mobile-app-name-container">
            <Link to="/" className="logo-link">
              <div className="logo-version-container">
                <div className="app-name">
                  {typedText}
                  <span className="cursor">|</span>
                </div>
                <div className="version-number">v0.1.4</div>
              </div>
            </Link>
            <div className="alpha-pill">alpha</div>
          </div>
          <button className="menu-toggle" onClick={toggleMenu}>
            {isOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
        {isOpen && (
          <nav className="mobile-menu">
            <ul>
              <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
              <li><a href="https://blog.projekt100x.de" target="_blank" rel="noopener noreferrer" onClick={toggleMenu}>Blog</a></li>
              {session ? (
                <>
                  <li><Link to="/markt" onClick={toggleMenu}>Markt</Link></li>
                  <li><Link to="/trades" onClick={toggleMenu}>Trades</Link></li>
                  <li><Link to="/tools" onClick={toggleMenu}>Tools</Link></li>
                  <li><Link to="/portfolio" onClick={toggleMenu}>Portfolio</Link></li>
                  <li><Link to="/chat" onClick={toggleMenu}>Chat</Link></li>
                  <li><Link to="/mastermind" onClick={toggleMenu}>Mastermind</Link></li>
                  <li><Link to="/account" onClick={toggleMenu}>Account</Link></li>
                  {isAdmin && <li><Link to="/admin" onClick={toggleMenu}>Admin</Link></li>}
                  <li><button onClick={() => { onFeedbackClick(); toggleMenu(); }}>Feedback</button></li>
                  <li><button onClick={() => { onChangelogClick(); toggleMenu(); }}>Neue Funktionen</button></li>
                </>
              ) : (
                <li><button onClick={() => { onLoginClick(); toggleMenu(); }}>Login</button></li>
              )}
            </ul>
          </nav>
        )}
      </div>
    </>
  );
}

function AppContent() {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [typedText, setTypedText] = useState('');
  const fullText = '100X';
  const location = useLocation();
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [session, setSession] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [isChangelogModalOpen, setIsChangelogModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      if (session?.user?.email) {
        setUserEmail(session.user.email);
      }
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (session?.user?.email) {
        setUserEmail(session.user.email);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (typedText.length < fullText.length) {
      const timeout = setTimeout(() => {
        setTypedText(fullText.slice(0, typedText.length + 1));
      }, 200); // Adjust typing speed here
      return () => clearTimeout(timeout);
    }
  }, [typedText]);

  const isAdmin = session?.user?.email === '100x@maximilian.business';

  const handleLogin = (newSession) => {
    setSession(newSession);
    setIsLoginModalOpen(false);
  };

  return (
    <>
      <div className="app-container">
        <nav className="menu-bar desktop-menu">
          <div className="app-name-container">
            <Link to="/" className="logo-link">
              <div className="logo-version-container">
                <div className="app-name">
                  {typedText}
                  <span className="cursor">|</span>
                </div>
                <div className="version-number">v0.1.4</div>
              </div>
            </Link>
            <div className="alpha-pill">alpha</div>
          </div>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><a href="https://blog.projekt100x.de" target="_blank" rel="noopener noreferrer">Blog</a></li>
            {session ? (
              <>
                <li><Link to="/markt">Markt</Link></li>
                <li><Link to="/trades">Trades</Link></li>
                <li><Link to="/tools">Tools</Link></li>
                <li><Link to="/portfolio">Portfolio</Link></li>
                <li><Link to="/chat">Chat</Link></li>
                <li><Link to="/mastermind">Mastermind</Link></li>
                <li><Link to="/account">Account</Link></li>
                {isAdmin && <li><Link to="/admin">Admin</Link></li>}
                <li><button onClick={() => setIsFeedbackModalOpen(true)}>Feedback</button></li>
                <li><button onClick={() => setIsChangelogModalOpen(true)}>Neue Funktionen</button></li>
              </>
            ) : (
              <li><button onClick={() => setIsLoginModalOpen(true)}>Login</button></li>
            )}
          </ul>
        </nav>
        <div className="app-content">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/markt" element={
              session ? (
                <>
                  <Home />
                  <button 
                    className="calendar-toggle"
                    onClick={() => setIsCalendarOpen(!isCalendarOpen)}
                  >
                    {isCalendarOpen ? 'Kalender schließen' : 'Kalender öffnen'}
                  </button>
                  <EarningsCalendar isOpen={isCalendarOpen} setIsOpen={setIsCalendarOpen} />
                </>
              ) : (
                <Navigate to="/" replace />
              )
            } />
            <Route path="/trades" element={session ? <Trades /> : <Navigate to="/" replace />} />
            <Route path="/portfolio" element={session ? <Portfolio /> : <Navigate to="/" replace />} />
            <Route path="/mastermind" element={session ? <Mastermind /> : <Navigate to="/" replace />} />
            <Route path="/admin" element={session && isAdmin ? <AdminPage /> : <Navigate to="/" replace />} />
            <Route path="/account" element={session ? <AccountPage /> : <Navigate to="/" replace />} />
            <Route path="/chat" element={session ? <Chat /> : <Navigate to="/" replace />} />
            <Route path="/course/:courseId" element={<Course />} />
            <Route path="/tools" element={session ? <Tools /> : <Navigate to="/" replace />} />
            <Route path="/covered-calls" element={session ? <CoveredCalls /> : <Navigate to="/" replace />} />
          </Routes>
        </div>
      </div>
      <MobileMenu 
        typedText={typedText} 
        onFeedbackClick={() => setIsFeedbackModalOpen(true)} 
        isAdmin={isAdmin}
        onChangelogClick={() => setIsChangelogModalOpen(true)}
        isOpen={isMobileMenuOpen}
        setIsOpen={setIsMobileMenuOpen}
        session={session}
        onLoginClick={() => setIsLoginModalOpen(true)}
      />
      <FeedbackModal 
        isOpen={isFeedbackModalOpen} 
        onClose={() => setIsFeedbackModalOpen(false)} 
        userEmail={userEmail}
      />
      <ChangelogModal 
        isOpen={isChangelogModalOpen}
        onClose={() => setIsChangelogModalOpen(false)}
      />
      {isLoginModalOpen && (
        <OTPLogin onLogin={handleLogin} onClose={() => setIsLoginModalOpen(false)} />
      )}
    </>
  );
}

function App() {
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  const handleLogin = (newSession) => {
    setSession(newSession);
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
  };

  return (
    <SubscriptionProvider>
      <div className="app">
        <div className="matrix-bg"></div>
        <Router>
          <GoogleAnalytics />
          <AppContent />
        </Router>
      </div>
    </SubscriptionProvider>
  );
}

export default App;
