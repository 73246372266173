import React, { useState, useEffect, useRef } from 'react';
import { 
  supabase, 
  sendMessage, 
  deleteMessage, 
  updateMessage, 
  uploadImage, 
  likeMessage, 
  unlikeMessage, 
  approveMessage,
  sendPrivateMessage,
  fetchPrivateMessages,
  markMessagesAsRead,
  getUnreadCount,
  getAdminId,
  getUnreadMessageState,
  updateLastReadTime
} from '../supabaseClient';
import { useSubscription } from '../contexts/SubscriptionContext';
import ImageModal from '../components/ImageModal'; // You'll need to create this component
import { financialEmojis, emotionEmojis } from '../constants/customEmojis';
import UpgradeOverlay from '../components/UpgradeOverlay';
import ChatTabs from '../components/ChatTabs';
import AdminChatTabs from '../components/AdminChatTabs';
import UserSelectModal from '../components/UserSelectModal';
import '../styles/chat.css';
import { smoothScrollToBottom } from '../utils/scrollUtils';
const ADMIN_EMAIL = '100x@maximilian.business';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [privateMessages, setPrivateMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [user, setUser] = useState(null);
  const [editingMessage, setEditingMessage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [imageToUpload, setImageToUpload] = useState(null);
  const [enlargedImage, setEnlargedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const messagesEndRef = useRef(null);
  const [likedMessages, setLikedMessages] = useState({});
  const [activeUsers, setActiveUsers] = useState(0);
  const [shouldScroll, setShouldScroll] = useState(true);
  const [messageLikes, setMessageLikes] = useState({});
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [replyingTo, setReplyingTo] = useState(null);
  const [activeTab, setActiveTab] = useState('public');
  const [activeChats, setActiveChats] = useState([]);
  const [showUserSelect, setShowUserSelect] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState({
    public: false,
    dm: false
  });
  const [unreadAdminChats, setUnreadAdminChats] = useState(new Set());
  const [userNickname, setUserNickname] = useState(null);
  const [adminId, setAdminId] = useState(null);

  const { subscription, loading } = useSubscription();

  const hasActiveSubscription = subscription && subscription.subscription_status === 'active';
  const isAdmin = user && user.email === ADMIN_EMAIL;

  useEffect(() => {
    const checkUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user);
      
      if (user) {
        // Fetch user's nickname
        const { data, error } = await supabase
          .from('user_subscriptions')
          .select('nickname')
          .eq('user_id', user.id)
          .single();
          
        if (!error && data) {
          setUserNickname(data.nickname);
        }

        // Fetch admin ID
        try {
          const adminId = await getAdminId();
          setAdminId(adminId);
        } catch (error) {
          console.error('Error fetching admin ID:', error);
        }
      }
    };
    checkUser();

    // Subscribe to real-time updates for public chat
    const publicSubscription = supabase
      .channel('public_chat')
      .on('postgres_changes', { 
        event: 'INSERT', 
        schema: 'public', 
        table: 'public_chat' 
      }, async payload => {
        const { data, error } = await supabase
          .from('public_chat')
          .select(`
            *,
            user_subscription:user_subscriptions!user_subscription_id (
              nickname
            )
          `)
          .eq('id', payload.new.id)
          .single();

        if (!error && data) {
          setMessages(currentMessages => [...currentMessages, data]);
          // Set unread state for public messages if we're not on the public tab
          if (activeTab !== 'public' && data.name !== user?.email) {
            setUnreadMessages(prev => ({ ...prev, public: true }));
          }
        }
      })
      .subscribe();

    // Subscribe to real-time updates for private messages
    const privateSubscription = supabase
      .channel('private_messages')
      .on('postgres_changes', {
        event: 'INSERT',
        schema: 'public',
        table: 'private_messages'
      }, async payload => {
        const { data, error } = await supabase
          .from('private_messages')
          .select('*')
          .eq('id', payload.new.id)
          .single();

        if (!error && data) {
          // For admin: only show messages in the current active chat
          if (isAdmin) {
            if (
              (data.sender_id === user?.id && data.receiver_id === activeTab) ||
              (data.sender_id === activeTab && data.receiver_id === user?.id)
            ) {
              setPrivateMessages(currentMessages => [...currentMessages, data]);
            }
            // Set unread state for admin if message is from a different chat
            if (data.sender_id !== user?.id && activeTab !== data.sender_id) {
              setUnreadAdminChats(prev => new Set([...prev, data.sender_id]));
            }
          } else {
            // For normal users: show all messages with admin
            setPrivateMessages(currentMessages => [...currentMessages, data]);
            // Set unread state if we're not on the DM tab
            if (activeTab !== 'dm' && data.sender_id !== user?.id) {
              setUnreadMessages(prev => ({ ...prev, dm: true }));
            }
          }
        }
      })
      .subscribe();

    // Fetch initial messages
    fetchMessages();

    // Fetch active users
    const fetchActiveUsers = async () => {
      try {
        const response = await supabase.functions.invoke('calculate-active-users');
        if (response.error) throw response.error;
        setActiveUsers(response.data.activeUsers);
      } catch (error) {
        console.error('Error fetching active users:', error);
        setActiveUsers(69); // fallback number
      }
    };

    fetchActiveUsers();

    return () => {
      supabase.removeChannel(publicSubscription);
      supabase.removeChannel(privateSubscription);
    };
  }, []);

  // New useEffect for loading private messages when user or activeTab changes
  useEffect(() => {
    if (user && (activeTab === 'dm' || (isAdmin && activeTab !== 'public'))) {
      loadPrivateMessages();
    }
  }, [user, activeTab]);

  useEffect(() => {
    if (shouldScroll && messages.length > 0) {
      scrollToBottom();
    }
  }, [messages.length, shouldScroll]);

  // Update the useEffect for tab changes
  useEffect(() => {
    if (activeTab === 'dm' || (isAdmin && activeTab !== 'public')) {
      if (privateMessages.length > 0) {
        smoothScrollToBottom(messagesEndRef);
      }
    } else if (activeTab === 'public' && messages.length > 0) {
      smoothScrollToBottom(messagesEndRef);
    }
  }, [activeTab, privateMessages.length, messages.length]);

  useEffect(() => {
    if (messages.length > 0 && user) {
      const liked = {};
      messages.forEach(msg => {
        let likes = [];
        try {
          likes = JSON.parse(msg.like || '[]');
        } catch (e) {
          console.error('Error parsing likes:', e);
        }
        liked[msg.id] = Array.isArray(likes) && likes.includes(user.id);
      });
      setLikedMessages(liked);
    }
  }, [messages, user]);

  useEffect(() => {
    const calculateLikes = async () => {
      const likes = {};
      for (const msg of messages) {
        likes[msg.id] = await calculateDisplayLikes(msg);
      }
      setMessageLikes(likes);
    };
    calculateLikes();
  }, [messages]);

  useEffect(() => {
    if (activeTab === 'dm' && user) {
      const markAsRead = async () => {
        try {
          await markMessagesAsRead();
        } catch (error) {
          console.error('Error marking messages as read:', error);
        }
      };
      markAsRead();
    }
  }, [activeTab, user]);

  useEffect(() => {
    if (isAdmin && user) {
      const loadActiveChats = async () => {
        try {
          // Get unique users who have had conversations with admin
          const { data, error } = await supabase
            .from('private_messages')
            .select('sender_id, receiver_id')
            .or(`sender_id.eq.${user.id},receiver_id.eq.${user.id}`);

          if (error) throw error;

          // Get unique user IDs excluding admin
          const uniqueUserIds = [...new Set(
            data.flatMap(msg => [msg.sender_id, msg.receiver_id])
              .filter(id => id !== user.id)
          )];

          // Get user details
          const { data: users, error: usersError } = await supabase
            .from('user_subscriptions')
            .select('user_id, nickname')
            .in('user_id', uniqueUserIds);

          if (usersError) throw usersError;

          setActiveChats(users.map(u => ({
            userId: u.user_id,
            userName: u.nickname || 'Anonymous'
          })));
        } catch (error) {
          console.error('Error loading active chats:', error);
        }
      };

      loadActiveChats();
    }
  }, [isAdmin, user]);

  // Add new useEffect for initial unread state
  useEffect(() => {
    const loadUnreadState = async () => {
      if (!user) return;
      try {
        const unreadState = await getUnreadMessageState();
        if (isAdmin) {
          setUnreadAdminChats(unreadState.unreadChats);
        } else {
          setUnreadMessages(unreadState);
        }
      } catch (error) {
        console.error('Error loading unread state:', error);
      }
    };
    loadUnreadState();
  }, [user, isAdmin]);

  // Update the subscription handlers
  useEffect(() => {
    if (!user) return;

    const handlePublicMessage = async (payload) => {
      const { data, error } = await supabase
        .from('public_chat')
        .select(`
          *,
          user_subscription:user_subscriptions!user_subscription_id (
            nickname
          )
        `)
        .eq('id', payload.new.id)
        .single();

      if (!error && data) {
        setMessages(currentMessages => [...currentMessages, data]);
        // Only set unread if we're not on public tab and it's not our message
        if (activeTab !== 'public' && data.name !== user.email) {
          setUnreadMessages(prev => ({ ...prev, public: true }));
          // Get current unread state to ensure we don't create duplicate records
          const { data: unreadState } = await supabase
            .from('unread_public_messages')
            .select('last_read_at')
            .eq('user_id', user.id)
            .single();

          if (!unreadState) {
            // If no record exists, create one with a past timestamp to mark as unread
            await supabase
              .from('unread_public_messages')
              .insert([{ 
                user_id: user.id, 
                last_read_at: new Date(0).toISOString() 
              }]);
          }
        }
      }
    };

    const handlePrivateMessage = async (payload) => {
      const { data, error } = await supabase
        .from('private_messages')
        .select('*')
        .eq('id', payload.new.id)
        .single();

      if (!error && data) {
        if (isAdmin) {
          if (
            (data.sender_id === user.id && data.receiver_id === activeTab) ||
            (data.sender_id === activeTab && data.receiver_id === user.id)
          ) {
            setPrivateMessages(currentMessages => [...currentMessages, data]);
          }
          // Only mark as unread if admin is the receiver and not currently viewing this chat
          if (data.receiver_id === user.id && data.sender_id !== user.id && activeTab !== data.sender_id) {
            setUnreadAdminChats(prev => new Set([...prev, data.sender_id]));
            // Get current unread state to ensure we don't create duplicate records
            const { data: unreadState } = await supabase
              .from('unread_private_messages')
              .select('last_read_at')
              .eq('user_id', user.id)
              .eq('sender_id', data.sender_id)
              .single();

            if (!unreadState) {
              await supabase
                .from('unread_private_messages')
                .insert([{ 
                  user_id: user.id, 
                  sender_id: data.sender_id,
                  last_read_at: new Date(0).toISOString() 
                }]);
            }
          }
        } else {
          setPrivateMessages(currentMessages => [...currentMessages, data]);
          if (activeTab !== 'dm' && data.sender_id !== user.id) {
            setUnreadMessages(prev => ({ ...prev, dm: true }));
            // Get current unread state to ensure we don't create duplicate records
            const { data: unreadState } = await supabase
              .from('unread_private_messages')
              .select('last_read_at')
              .eq('user_id', user.id)
              .eq('sender_id', data.sender_id)
              .single();

            if (!unreadState) {
              await supabase
                .from('unread_private_messages')
                .insert([{ 
                  user_id: user.id, 
                  sender_id: data.sender_id,
                  last_read_at: new Date(0).toISOString() 
                }]);
            }
          }
        }
      }
    };

    const publicSubscription = supabase
      .channel('public_chat')
      .on('postgres_changes', { 
        event: 'INSERT', 
        schema: 'public', 
        table: 'public_chat' 
      }, handlePublicMessage)
      .subscribe();

    const privateSubscription = supabase
      .channel('private_messages')
      .on('postgres_changes', {
        event: 'INSERT',
        schema: 'public',
        table: 'private_messages'
      }, handlePrivateMessage)
      .subscribe();

    return () => {
      supabase.removeChannel(publicSubscription);
      supabase.removeChannel(privateSubscription);
    };
  }, [activeTab, user, isAdmin]);

  // Update the handleTabChange function
  const handleTabChange = async (newTab) => {
    setActiveTab(newTab);
    if (!user) return;

    try {
      if (newTab === 'public') {
        await updateLastReadTime('public');
        setUnreadMessages(prev => ({ ...prev, public: false }));
      } else if (newTab === 'dm') {
        await updateLastReadTime('private');
        setUnreadMessages(prev => ({ ...prev, dm: false }));
      } else if (isAdmin) {
        // For admin, update the specific chat's read time
        await updateLastReadTime('private', newTab);
        setUnreadAdminChats(prev => {
          const newSet = new Set(prev);
          newSet.delete(newTab);
          return newSet;
        });
      }
    } catch (error) {
      console.error('Error updating last read time:', error);
    }
  };

  const fetchMessages = async () => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    
    // Get current user
    const { data: { user } } = await supabase.auth.getUser();
    const isAdmin = user?.email === ADMIN_EMAIL;
    
    try {
      let query = supabase
        .from('public_chat')
        .select(`
          *,
          user_subscription:user_subscriptions(
            nickname,
            user_id
          )
        `);

      // Add date filter
      query = query.gte('date', sevenDaysAgo.toISOString());

      // Add visibility filter
      if (!isAdmin && user) {
        query = query.or(`visible.eq.true,name.eq."${user.email}"`);
      }

      // Add ordering
      query = query.order('date', { ascending: true });

      const { data, error } = await query;

      if (error) throw error;

      setMessages(data || []);
      smoothScrollToBottom(messagesEndRef);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const loadPrivateMessages = async () => {
    if (!user) return;

    try {
      let messages;
      if (isAdmin && activeTab !== 'public') {
        // For admin, fetch messages for the current active chat
        const { data, error } = await supabase
          .from('private_messages')
          .select('*')
          .or(
            `and(sender_id.eq.${user.id},receiver_id.eq.${activeTab}),` +
            `and(sender_id.eq.${activeTab},receiver_id.eq.${user.id})`
          )
          .order('date', { ascending: true });

        if (error) throw error;
        messages = data;
      } else {
        // For normal users, fetch messages with admin
        messages = await fetchPrivateMessages();
      }

      setPrivateMessages(messages || []);
    } catch (error) {
      console.error('Error fetching private messages:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editingMessage) {
      await handleUpdate();
      return;
    }

    if ((!newMessage.trim() && !imageToUpload) || !user) return;

    try {
      let messageContent = newMessage;
      let imageUrl = null;

      if (imageToUpload) {
        setIsUploading(true);
        imageUrl = await uploadImage(imageToUpload);
        messageContent += `\n[Image](${imageUrl})`;
        setIsUploading(false);
        setImageToUpload(null);
        setImagePreview(null);
      }

      if (activeTab === 'public') {
        const messageData = {
          name: user.email,
          message: messageContent,
          reply_to: replyingTo?.id || null
        };
        await sendMessage(messageData);
      } else {
        // Send private message
        if (isAdmin) {
          // For admin, activeTab contains the user ID we're chatting with
          await sendPrivateMessage(messageContent, imageUrl, activeTab);
        } else {
          await sendPrivateMessage(messageContent, imageUrl);
        }
      }

      setNewMessage('');
      setReplyingTo(null);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleEdit = (msg) => {
    setEditingMessage(msg);
    setNewMessage(msg.message);
  };

  const handleUpdate = async () => {
    if (!editingMessage || !newMessage.trim()) return;

    try {
      await updateMessage(editingMessage.id, newMessage);
      setMessages(messages.map(msg => 
        msg.id === editingMessage.id ? {...msg, message: newMessage} : msg
      ));
      setEditingMessage(null);
      setNewMessage('');
    } catch (error) {
      console.error('Error updating message:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const { error } = await deleteMessage(id);
      if (error) throw error;
      // Only update UI after successful deletion
      setMessages(messages.filter(msg => msg.id !== id));
    } catch (error) {
      console.error('Error deleting message:', error);
      alert('Failed to delete message. Please try again.');
    }
  };

  const formatName = (email, msg) => {
    if (email === ADMIN_EMAIL) return 'Max';
    
    // Use the nickname from the joined user_subscription data
    if (msg.user_subscription?.nickname) {
      return msg.user_subscription.nickname;
    }
    
    return email.split('@')[0];
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}. ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  };

  const scrollToBottom = () => {
    smoothScrollToBottom(messagesEndRef);
  };

  const handleImageUpload = async (e) => {
    if (!user) {
      console.error('User not authenticated');
      return;
    }
    const file = e.target.files[0];
    if (!file) return;

    setImageToUpload(file);
    
    // Create a preview of the image
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const renderMessageContent = (content, replyToMessage) => {
    const parts = content.split(/(\[Image\]\(.*?\))/);
    const imageUrls = [];
    const textParts = [];

    parts.forEach((part) => {
      const imageMatch = part.match(/\[Image\]\((.*?)\)/);
      if (imageMatch) {
        imageUrls.push(imageMatch[1]);
      } else if (part.trim()) {
        textParts.push(part.trim());
      }
    });

    // URL regex pattern
    const urlPattern = /(https?:\/\/[^\s]+)/g;

    return (
      <>
        {replyToMessage && (
          <div 
            className="reply-reference"
            onClick={() => scrollToMessage(replyToMessage.id)}
            style={{ cursor: 'pointer' }}
          >
            ↱ Antwort an {formatName(replyToMessage.name, replyToMessage)}: {replyToMessage.message.substring(0, 50)}...
          </div>
        )}
        {imageUrls.map((url, index) => (
          <img
            key={index}
            src={url}
            alt="Uploaded"
            className="message-image"
            onClick={() => setEnlargedImage(url)}
          />
        ))}
        {textParts.length > 0 && (
          <div className="message-text">
            {textParts.map((text, index) => (
              <p key={index} style={{ whiteSpace: 'pre-wrap' }}>
                {text.split('\n').map((line, i) => (
                  <React.Fragment key={i}>
                    {line.split(urlPattern).map((part, j) => {
                      if (part.match(urlPattern)) {
                        return (
                          <a 
                            key={j}
                            href={part}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {part}
                          </a>
                        );
                      }
                      return part;
                    })}
                    {i < text.split('\n').length - 1 && <br />}
                  </React.Fragment>
                ))}
              </p>
            ))}
          </div>
        )}
      </>
    );
  };

  const handleLike = async (messageId) => {
    if (!user) return;

    try {
      setShouldScroll(false);
      let updatedMessage;
      if (likedMessages[messageId]) {
        updatedMessage = await unlikeMessage(messageId, user.id);
      } else {
        updatedMessage = await likeMessage(messageId, user.id);
      }

      if (updatedMessage && updatedMessage[0] && updatedMessage[0].like !== undefined) {
        setLikedMessages(prev => ({
          ...prev,
          [messageId]: !prev[messageId]
        }));

        setMessages(prevMessages => 
          prevMessages.map(msg => 
            msg.id === messageId ? { ...msg, like: updatedMessage[0].like } : msg
          )
        );
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error in handleLike:', error);
      alert('Failed to like/unlike the message. Please try again.');
    }
  };

  const calculateDisplayLikes = async (msg) => {
    try {
      const response = await supabase.functions.invoke('calculate-engagement', {
        body: msg
      });
      
      if (response.error) throw response.error;
      return response.data.totalLikes;
    } catch (e) {
      console.error('Error calculating likes:', e);
      return JSON.parse(msg.like || '[]').length; // Fallback to actual likes only
    }
  };

  const handleApprove = async (messageId) => {
    try {
      await approveMessage(messageId);
      // Update the local messages state
      setMessages(messages.map(msg => 
        msg.id === messageId ? { ...msg, visible: true } : msg
      ));
    } catch (error) {
      console.error('Error approving message:', error);
      alert('Failed to approve message. Please try again.');
    }
  };

  const handleEmojiClick = (emoji) => {
    setNewMessage(prev => prev + emoji.native);
    setShowEmojiPicker(false);
  };

  const handleReply = (msg) => {
    setReplyingTo(msg);
    setNewMessage(`@${formatName(msg.name, msg)} `);
  };

  const cancelReply = () => {
    setReplyingTo(null);
    setNewMessage('');
  };

  const scrollToMessage = (messageId) => {
    const messageElement = document.getElementById(`message-${messageId}`);
    if (messageElement) {
      messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      // Optional: Kurzes Highlighting der Nachricht
      messageElement.style.backgroundColor = 'rgba(57, 255, 20, 0.1)';
      setTimeout(() => {
        messageElement.style.backgroundColor = '';
      }, 1000);
    }
  };

  const handleNewChat = async (selectedUser) => {
    setShowUserSelect(false);
    if (selectedUser) {
      setActiveChats(prev => {
        if (!prev.find(chat => chat.userId === selectedUser.user_id)) {
          return [...prev, {
            userId: selectedUser.user_id,
            userName: selectedUser.nickname || 'Anonymous'
          }];
        }
        return prev;
      });
      setActiveTab(selectedUser.user_id);
    }
  };

  const handleCloseChat = (userId) => {
    setActiveChats(prev => prev.filter(chat => chat.userId !== userId));
    if (activeTab === userId) {
      setActiveTab('public');
    }
  };

  if (!hasActiveSubscription) {
    return <UpgradeOverlay />;
  }

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Lade Mitgliedschafts-Status...</p>
      </div>
    );
  }

  return (
    <div className="chat-page">
      {hasActiveSubscription && (
        <div className="chat-container">
          {isAdmin ? (
            <AdminChatTabs
              activeTab={activeTab}
              onTabChange={handleTabChange}
              activeChats={activeChats}
              onNewChat={() => setShowUserSelect(true)}
              onCloseChat={handleCloseChat}
              unreadChats={unreadAdminChats}
            />
          ) : (
            <ChatTabs
              activeTab={activeTab}
              onTabChange={handleTabChange}
              unreadMessages={unreadMessages}
            />
          )}
          <div className="messages-container">
            {activeTab === 'public' ? (
              // Public chat messages
              messages.map((msg) => {
                const isOwnMessage = msg.name === user.email;
                const replyToMessage = messages.find(m => m.id === msg.reply_to);
                return (
                  <div 
                    key={msg.id} 
                    id={`message-${msg.id}`}
                    className={`message-wrapper ${isOwnMessage ? 'own-message' : 'other-message'}`}
                  >
                    <div className={`message ${msg.name === ADMIN_EMAIL ? 'admin-message' : ''}`}>
                      <div className="message-bubble">
                        <div className="message-header">
                          <span className="message-name">{formatName(msg.name, msg)}</span>
                          <span className="message-datetime">{formatDateTime(msg.date)}</span>
                        </div>
                        <div className="message-content">
                          {renderMessageContent(msg.message, replyToMessage)}
                        </div>
                      </div>
                      <div className="message-actions">
                        <button 
                          onClick={() => handleLike(msg.id)}
                          className={`like-button ${likedMessages[msg.id] ? 'liked' : ''}`}
                        >
                          {likedMessages[msg.id] ? '❤️' : '🤍'} {messageLikes[msg.id] || 0}
                        </button>
                        {msg.name !== user.email && (
                          <button onClick={() => handleReply(msg)} style={{ borderRadius: '15px' }}>↩️</button>
                        )}
                        {isAdmin && (
                          <>
                            <button onClick={() => handleEdit(msg)} style={{ borderRadius: '15px' }}>✏️</button>
                            <button onClick={() => handleDelete(msg.id)}>Delete</button>
                            {!msg.visible && (
                              <button 
                                onClick={() => handleApprove(msg.id)}
                                style={{ 
                                  backgroundColor: 'rgba(57, 255, 20, 0.2)',
                                  padding: '5px 10px',
                                  borderRadius: '15px'
                                }}
                              >
                                Approve
                              </button>
                            )}
                          </>
                        )}
                        {!isAdmin && isOwnMessage && (
                          <button onClick={() => handleEdit(msg)} style={{ borderRadius: '15px' }}>✏️</button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              // Private messages
              privateMessages.map((msg) => {
                const isOwnMessage = msg.sender_id === user.id;
                const isAdminMessage = msg.sender_id === adminId;
                return (
                  <div 
                    key={msg.id}
                    className={`message-wrapper ${isOwnMessage ? 'own-message' : 'other-message'}`}
                  >
                    <div className={`message ${isAdminMessage ? 'admin-message' : ''}`}>
                      <div className="message-bubble">
                        <div className="message-header">
                          <span className="message-name">
                            {isOwnMessage ? 'Du' : (isAdminMessage ? 'Max' : userNickname || 'Du')}
                          </span>
                          <span className="message-datetime">
                            {formatDateTime(msg.date)}
                          </span>
                        </div>
                        <div className="message-content">
                          {renderMessageContent(msg.message)}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
            <div ref={messagesEndRef} />
          </div>
          <div className="chat-input-area">
            {activeTab === 'public' && (
              <div className="active-users-indicator">
                <span className="active-users-dot"></span>
                <span className="active-users-text">{activeUsers} aktive Nutzer in den letzten 24 Stunden</span>
              </div>
            )}
            {replyingTo && (
              <div className="reply-indicator">
                <span>Antwort an {formatName(replyingTo.name, replyingTo)}</span>
                <button onClick={cancelReply}>✕</button>
              </div>
            )}
            <form className="message-form" onSubmit={handleSubmit}>
              <textarea
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder={editingMessage ? "Edit your message..." : "Nachricht eingeben..."}
                className="message-input"
                rows="3"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleSubmit(e);
                  }
                }}
              />
              
              <button 
                type="button" 
                className="emoji-button"
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              >
                😊
              </button>
              
              {showEmojiPicker && (
                <div className="emoji-picker">
                  <div className="emoji-section">
                    <h4>Emotionen</h4>
                    <div className="emoji-grid">
                      {Object.values(emotionEmojis.emojis).map(emoji => (
                        <button
                          key={emoji.id}
                          type="button"
                          onClick={() => handleEmojiClick(emoji)}
                          title={emoji.name}
                        >
                          {emoji.native}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="emoji-section">
                    <h4>Finanzen</h4>
                    <div className="emoji-grid">
                      {Object.values(financialEmojis.emojis).map(emoji => (
                        <button
                          key={emoji.id}
                          type="button"
                          onClick={() => handleEmojiClick(emoji)}
                          title={emoji.name}
                        >
                          {emoji.native}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              
              {imagePreview && (
                <div className="image-preview">
                  <img src={imagePreview} alt="Preview" />
                  <button type="button" onClick={() => {
                    setImageToUpload(null);
                    setImagePreview(null);
                  }}>Remove</button>
                </div>
              )}
              <label htmlFor="image-upload" className={`image-upload-label ${isUploading ? 'disabled' : ''}`}>
                📷
                <input
                  id="image-upload"
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  style={{ display: 'none' }}
                  disabled={isUploading}
                />
              </label>
              <button type="submit" className="send-button" disabled={isUploading}>
                <span className="send-icon">{editingMessage ? '✓' : '➤'}</span>
              </button>
              {editingMessage && (
                <button 
                  type="button" 
                  onClick={() => {
                    setEditingMessage(null);
                    setNewMessage(''); // Clear the message input
                  }} 
                  className="cancel-button"
                >
                  X
                </button>
              )}
            </form>
          </div>
          {enlargedImage && (
            <ImageModal imageUrl={enlargedImage} onClose={() => setEnlargedImage(null)} />
          )}
          {showUserSelect && (
            <UserSelectModal
              onSelect={handleNewChat}
              onClose={() => setShowUserSelect(false)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Chat;
