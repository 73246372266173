import React from 'react';
import '../styles/chat.css';

const ChatTabs = ({ activeTab, onTabChange, unreadMessages }) => {
  return (
    <div className="chat-tabs">
      <button 
        className={`tab-button ${activeTab === 'public' ? 'active' : ''} ${unreadMessages.public ? 'has-unread' : ''}`}
        onClick={() => onTabChange('public')}
      >
        Öffentlich
      </button>
      <button 
        className={`tab-button ${activeTab === 'dm' ? 'active' : ''} ${unreadMessages.dm ? 'has-unread' : ''}`}
        onClick={() => onTabChange('dm')}
      >
        Max
      </button>
    </div>
  );
};

export default ChatTabs; 