import React, { useState, useEffect } from 'react';
import { useSubscription } from '../contexts/SubscriptionContext';
import { supabase } from '../supabaseClient';
import Spinner from '../components/Spinner';
import UpgradeOverlay from '../components/UpgradeOverlay';
import '../styles/portfolio.css';

const Portfolio = () => {
  const [portfolio, setPortfolio] = useState([]);
  const [ytdPerformance, setYtdPerformance] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const { subscription } = useSubscription();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        
        // Fetch portfolio positions
        const { data: positions, error: positionsError } = await supabase
          .from('portfolio_positions')
          .select('*')
          .eq('is_active', true)
          .order('position_size', { ascending: false });

        if (positionsError) throw positionsError;

        // Calculate total portfolio value
        const totalPositionSize = positions.reduce((sum, position) => sum + position.position_size, 0);

        // Fetch YTD performance
        const currentYear = new Date().getFullYear();
        const { data: performance, error: performanceError } = await supabase
          .from('portfolio_performance')
          .select('*')
          .eq('year', currentYear)
          .single();

        if (performanceError && performanceError.code !== 'PGRST116') {
          throw performanceError;
        }

        // Transform positions data and calculate relative sizes
        const transformedPositions = positions.map(position => ({
          name: position.name,
          symbol: position.symbol,
          positionSize: (position.position_size / totalPositionSize) * 100, // Convert to relative percentage
          currentGain: position.current_gain
        }));

        setPortfolio(transformedPositions);
        setYtdPerformance(performance?.return_percentage || 0);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching portfolio data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleCloseDisclaimer = () => {
    setShowDisclaimer(false);
  };

  const hasActiveSubscription = subscription && subscription.subscription_status === 'active';

  return (
    <div className="portfolio-page">
      <h1>Aktienportfolio</h1>
      <p>Aktualisiert am: {new Date().toLocaleDateString('de-DE')}</p>
      
      <div className="historical-performance data-card">
        <h2>Historische Performance</h2>
        <div className="performance-grid">
          <div className="performance-year">
            <span className="year">2023:</span>
            <span className="return positive">+95,37%</span>
          </div>
          <div className="performance-year">
            <span className="year">2024:</span>
            <span className="return positive">+66,96%</span>
          </div>
          <div className="performance-year">
            <span className="year">{new Date().getFullYear()}:</span>
            {ytdPerformance === null || ytdPerformance === 0 ? (
              <span className="return">wird aktualisiert...</span>
            ) : (
              <span className={`return ${ytdPerformance >= 0 ? 'positive' : 'negative'}`}>
                {ytdPerformance >= 0 ? '+' : ''}{ytdPerformance?.toFixed(2)}%
              </span>
            )}
            <span className="date-note">(Seit Jahresbeginn)</span>
          </div>
        </div>
        
        <div className="performance-chart">
          <img 
            src="/images/yearly performance 2023.png" 
            alt="Jährliche Performance Übersicht" 
            className="performance-image"
          />
        </div>
      </div>

      <br />
      {!hasActiveSubscription && <UpgradeOverlay />}
      <div className={`components-grid ${!hasActiveSubscription ? 'blurred' : ''}`}>
        {showDisclaimer && (
          <div className="page-disclaimer-box">
            <button className="page-close-disclaimer" onClick={handleCloseDisclaimer}>×</button>
            <h3>Rechtlicher Hinweis und Haftungsausschluss</h3>
            <div className="page-disclaimer-content">
              <p>
                Die auf dieser Webseite bereitgestellten Informationen, einschließlich aber nicht beschränkt auf Portfoliodaten, Analysen und Kommentare, dienen ausschließlich zu Informations- und Bildungszwecken. Sie stellen unter keinen Umständen eine Anlageberatung, Empfehlung oder Aufforderung zum Kauf oder Verkauf von Wertpapieren oder anderen Finanzinstrumenten dar.
              </p>
              <ul>
                <li>Jegliche Investitionsentscheidungen, die Sie auf Grundlage der hier präsentierten Informationen treffen, erfolgen ausschließlich auf Ihr eigenes Risiko.</li>
                <li>Wir übernehmen keinerlei Haftung für etwaige Verluste oder Schäden, die direkt oder indirekt aus der Nutzung dieser Informationen resultieren können.</li>
                <li>Vergangene Wertentwicklungen, Simulationen oder Prognosen sind kein verlässlicher Indikator für zukünftige Ergebnisse.</li>
                <li>Es besteht die Möglichkeit, dass Sie nicht den ursprünglich investierten Betrag zurückerhalten.</li>
              </ul>
              <p>
                Bevor Sie eine Anlageentscheidung treffen, sollten Sie:
              </p>
              <ol>
                <li>Ihre individuellen finanziellen Umstände prüfen</li>
                <li>Ihre Anlageziele und Risikotoleranz sorgfältig abwägen</li>
                <li>Unabhängigen, professionellen Finanzrat einholen</li>
                <li>Alle relevanten Risiken, Gebühren und Bedingungen vollständig verstehen</li>
              </ol>
              <p>
                Durch die Nutzung dieser Webseite erklären Sie sich mit den hierin enthaltenen Bedingungen einverstanden und bestätigen, dass Sie die damit verbundenen Risiken verstanden haben. Bei Nichteinverständnis bitten wir Sie, diese Webseite nicht zu nutzen.
              </p>
            </div>
          </div>
        )}
        
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="portfolio-list data-card">
            {portfolio.map((stock, index) => (
              <div key={index} className="portfolio-item">
                <div className="portfolio-item-main">
                  <div className="portfolio-item-name">
                    <h2>{stock.name}</h2>
                  </div>
                  <div className={`portfolio-item-gain ${stock.currentGain >= 0 ? 'positive' : 'negative'}`}>
                    {stock.currentGain >= 0 ? '+' : ''}{stock.currentGain.toFixed(2)}%
                  </div>
                </div>
                <div className="portfolio-item-details">
                  <span>Positionsgröße: {stock.positionSize.toFixed(2)}%</span>
                </div>
                <div className="portfolio-item-info">
                  <p>{stock.info}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Portfolio;
