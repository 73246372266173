import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { Tooltip } from 'react-tooltip';
import '../styles/earningscalender.css';


const EarningsCalendar = ({ isOpen, setIsOpen }) => {
  const wirtschaftsdaten = [
    { ereignis: 'ISM Produktions-PMI JAN', datum: new Date(2025, 1, 3, 17, 0), konsens: '49,8', vorherig: '49,2', aktuell: '50,9', erklaerung: 'Misst die Aktivität im verarbeitenden Gewerbe. Wichtiger Frühindikator für Wirtschaftswachstum und kann Industrieaktien beeinflussen.' },
    { ereignis: 'JOLTs Stellenangebote DEZ', datum: new Date(2025, 1, 4, 17, 0), konsens: '8,0M', vorherig: '8,156M', aktuell: '7,6M', erklaerung: 'Zeigt die Anzahl der offenen Stellen. Wichtiger Indikator für den Arbeitsmarkt.' },
    { ereignis: 'ISM Dienstleistungs-PMI JAN', datum: new Date(2025, 1, 5, 17, 0), konsens: '54,3', vorherig: '54,0', aktuell: '52,8', erklaerung: 'Misst die Aktivität im Dienstleistungssektor. Wichtiger Indikator für den größten Sektor der US-Wirtschaft.' },
    { ereignis: 'Beschäftigung außerhalb der Landwirtschaft JAN', datum: new Date(2025, 1, 7, 15, 30), konsens: '170K', vorherig: '307K', aktuell: '143K', erklaerung: 'Zeigt die Anzahl neuer Arbeitsplätze. Einer der wichtigsten Arbeitsmarktindikatoren.' },
    { ereignis: 'Arbeitslosenquote JAN', datum: new Date(2025, 1, 7, 15, 30), konsens: '4,1%', vorherig: '4,1%', aktuell: '4,0%', erklaerung: 'Misst den Anteil der Arbeitslosen an der Erwerbsbevölkerung.' },
    { ereignis: 'Michigan Verbrauchervertrauen Vorl. FEB', datum: new Date(2025, 1, 7, 17, 0), konsens: '71,1', vorherig: '71,1', aktuell: '-', erklaerung: 'Misst die Verbraucherstimmung. Wichtiger Indikator für zukünftiges Konsumverhalten.' },
    { ereignis: 'Fed-Vorsitzender Powell Aussage', datum: new Date(2025, 1, 11, 17, 0), konsens: '-', vorherig: '-', aktuell: '-', erklaerung: 'Wichtige Aussagen des Fed-Vorsitzenden zur Geldpolitik.' },
    { ereignis: 'Kerninflationsrate MoM JAN', datum: new Date(2025, 1, 12, 15, 30), konsens: '0,3%', vorherig: '0,2%', aktuell: '-', erklaerung: 'Misst Preisänderungen ohne volatile Lebensmittel- und Energiepreise.' },
    { ereignis: 'Kerninflationsrate YoY JAN', datum: new Date(2025, 1, 12, 15, 30), konsens: '3,1%', vorherig: '3,2%', aktuell: '-', erklaerung: 'Jährliche Veränderung der Kerninflation. Zeigt langfristige Inflationstrends.' },
    { ereignis: 'Inflationsrate MoM JAN', datum: new Date(2025, 1, 12, 15, 30), konsens: '0,3%', vorherig: '0,4%', aktuell: '-', erklaerung: 'Monatliche Veränderung der Verbraucherpreise.' },
    { ereignis: 'Inflationsrate YoY JAN', datum: new Date(2025, 1, 12, 15, 30), konsens: '2,9%', vorherig: '2,9%', aktuell: '-', erklaerung: 'Jährliche Inflationsrate. Zentral für geldpolitische Entscheidungen.' },
    { ereignis: 'Fed-Vorsitzender Powell Aussage', datum: new Date(2025, 1, 12, 17, 0), konsens: '-', vorherig: '-', aktuell: '-', erklaerung: 'Fortsetzung der Aussagen zur Geldpolitik.' },
    { ereignis: 'Erzeugerpreisindex MoM JAN', datum: new Date(2025, 1, 13, 15, 30), konsens: '0,3%', vorherig: '0,2%', aktuell: '-', erklaerung: 'Misst Preisänderungen auf Produzentenebene. Frühindikator für Verbraucherpreisinflation.' },
    { ereignis: 'Einzelhandelsumsätze MoM JAN', datum: new Date(2025, 1, 14, 15, 30), konsens: '0,3%', vorherig: '0,4%', aktuell: '-', erklaerung: 'Zeigt Veränderungen im Konsumverhalten. Wichtig für BIP-Prognosen.' },
    { ereignis: 'Baugenehmigungen Vorl. JAN', datum: new Date(2025, 1, 19, 15, 30), konsens: '-', vorherig: '1,482M', aktuell: '-', erklaerung: 'Indikator für zukünftige Bauaktivitäten. Wichtig für Immobilien- und Baustoffsektor.' },
    { ereignis: 'Baubeginne JAN', datum: new Date(2025, 1, 19, 15, 30), konsens: '-', vorherig: '1,499M', aktuell: '-', erklaerung: 'Zeigt die Anzahl der begonnenen Neubauten.' },
    { ereignis: 'FOMC Protokoll', datum: new Date(2025, 1, 19, 21, 0), konsens: '-', vorherig: '-', aktuell: '-', erklaerung: 'Detaillierte Aufzeichnung der letzten Fed-Sitzung.' },
    { ereignis: 'Verkäufe bestehender Häuser JAN', datum: new Date(2025, 1, 21, 17, 0), konsens: '-', vorherig: '-', aktuell: '-', erklaerung: 'Wichtiger Indikator für den Immobilienmarkt.' },
    { ereignis: 'Auftragseingang langlebiger Güter MoM JAN', datum: new Date(2025, 1, 27, 15, 30), konsens: '-', vorherig: '-2,2%', aktuell: '-', erklaerung: 'Misst Bestellungen für langlebige Produkte. Wichtiger Indikator für Industrieproduktion.' },
    { ereignis: 'BIP-Wachstumsrate QoQ 2.Schätzung Q4', datum: new Date(2025, 1, 27, 15, 30), konsens: '2,3%', vorherig: '3,1%', aktuell: '-', erklaerung: 'Zweite Schätzung des Wirtschaftswachstums im vierten Quartal.' },
    { ereignis: 'Kern-PCE-Preisindex MoM JAN', datum: new Date(2025, 1, 28, 15, 30), konsens: '-', vorherig: '0,2%', aktuell: '-', erklaerung: 'Bevorzugtes Inflationsmaß der Fed.' },
    { ereignis: 'Persönliche Einkommen MoM JAN', datum: new Date(2025, 1, 28, 15, 30), konsens: '0,3%', vorherig: '0,4%', aktuell: '-', erklaerung: 'Misst die Veränderung der Haushaltseinkommen.' },
    { ereignis: 'Persönliche Ausgaben MoM JAN', datum: new Date(2025, 1, 28, 15, 30), konsens: '-', vorherig: '0,7%', aktuell: '-', erklaerung: 'Zeigt Veränderungen im Konsumverhalten der Haushalte.' }
  ];

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    } else {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
    }
    return () => {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const getColorClass = (aktuell, konsens, ereignis) => {
    if (aktuell === '-' || konsens === '-') return '';
    const aktuelValue = parseFloat(aktuell.replace(',', '.').replace('%', ''));
    const konsensValue = parseFloat(konsens.replace(',', '.').replace('%', ''));
    
    // Check if the event is related to inflation or prices
    const isInflationOrPriceRelated = ereignis.toLowerCase().includes('inflation') || 
                                    ereignis.toLowerCase().includes('preis') ||
                                    ereignis.toLowerCase().includes('pce');
    
    if (isInflationOrPriceRelated) {
      // For inflation and price metrics, lower than expected is good (green), higher is bad (red)
      return aktuelValue < konsensValue ? 'green' : aktuelValue > konsensValue ? 'red' : '';
    } else {
      // For other metrics, higher than expected is good (green), lower is bad (red)
      return aktuelValue > konsensValue ? 'green' : aktuelValue < konsensValue ? 'red' : '';
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content calendar-modal">
        <h2>Wirtschaftsdaten-Veröffentlichungen</h2>
        
        <div className="calendar-table-container">
          <table className="calendar-table">
            <thead>
              <tr>
                <th>Datum</th>
                <th>Ereignis</th>
                <th>Konsens</th>
                <th>Vorherig</th>
                <th>Aktuell</th>
              </tr>
            </thead>
            <tbody>
              {wirtschaftsdaten.map((data, index) => (
                <tr key={`economic-${index}`}>
                  <td>{format(data.datum, "dd.MM.yyyy HH:mm", { locale: de })}</td>
                  <td>
                    <span data-tooltip-id={`tooltip-${index}`}>
                      {data.ereignis}
                    </span>
                    <Tooltip id={`tooltip-${index}`} className="custom-tooltip">
                      <span>{data.erklaerung}</span>
                    </Tooltip>
                  </td>
                  <td>{data.konsens}</td>
                  <td>{data.vorherig}</td>
                  <td className={getColorClass(data.aktuell, data.konsens, data.ereignis)}>
                    {data.aktuell}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <button className="calendar-close-modal" onClick={() => setIsOpen(false)}>X</button>
      </div>
    </div>
  );
};

export default EarningsCalendar;
